import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from '~/components/Wrapper';

import ContentWrapper from '~/components/ContentWrapper';
import { Sidebar } from '~/components/Sidebar';
import { NavbarCustom } from '~/components/NavbarCustom';

export default function AuthLayout({ children }) {
  return (
    <Wrapper>
      <Sidebar />
      <NavbarCustom />
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
