import React, { useEffect, useState } from 'react'
import Header from '~/components/Header'
import { FaCaretDown, FaCaretUp, FaPlus } from 'react-icons/fa'
import history from '~/services/history'
import { Card } from '~/components/Card'
import CardHeader from '~/components/Card/Header'
import CardBody from '~/components/Card/Body'
import Table from '~/components/Table'
import { ContainerDate, ContainerHeader, ContainerMeeting } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getMeetingsRequest } from '~/store/modules/meeting/actions'
import { format, formatDistanceToNow, formatISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import Loading from '~/components/Loading'

export default function DashboardMeeting() {

  const [passado, setPassado] = useState(false)
  const { profile } = useSelector(state => state.auth)
  const { data: meetingData, loading: loadingMeeting } = useSelector(state => state.meeting)

  const dispatch = useDispatch()
  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2
  const internalMeeting = profile.permissions?.map(e => e.id).includes(1)
  const externalMeeting = profile.permissions?.map(e => e.id).includes(2)


  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'start',
      text: 'Data',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <ContainerDate>
            <div className="row">
              <strong>{format(new Date(row.start), 'dd MMM', { locale: ptBR })}</strong>
            </div>
            <div className="row">
              <small>{format(new Date(row.start), 'HH:mm', { locale: ptBR })}</small>
            </div>
          </ContainerDate>
        )
      },
      headerFormatter: (column, e, { sortElement }) => {
        return (
          <div>
            {column.text}
            {sortElement}
          </div>
        )
      }
    },
    {
      dataField: 'title.value',
      text: 'Tema',
      events: {
        onClick: (e, cell, i, row) => {
          handleRedirectToMeeting(row.id)
        }
      },
      formatter: (cell, row) => {
        return (
          <div className="td-meeting">
            <div className="row">
              <i style={{ backgroundColor: row.color }}></i>&nbsp;
              <small className="text-muted text-wrap">
                {row.convener.user.company.fantasyName} ({row.status.label})
              </small>
            </div>
            <div className="row">
              <strong>{row.title.value}</strong>
            </div>
            <div className="row">
              <small className="text-muted">
                {formatDistanceToNow(
                  new Date(row.start),
                  {
                    locale: ptBR,
                    addSuffix: true
                  }
                )}
              </small>
            </div>
          </div>
        )
      }
    },
  ]

  function handleChangeToPast() {
    setPassado(true)
  }

  function handleChangeToFuture() {
    setPassado(false)
  }
  function handleRedirectToMeeting(id) {
    history.push('/reuniao', {
      id: id ?? null,
      cadastrar: true
    })
  }

  useEffect(() => {

    const now = formatISO(new Date())
    dispatch(getMeetingsRequest({
      id: profile.id,
      adminLocal,
      adminTotal,
      companyId: profile.companyId,
      queryMeeting: passado
        ? `&&where.OR[0].start.lte=${now}&&where.OR[1].status.id=2&&orderBy.start=desc&&take=15`
        : `&&where.start.gte=${now}&&where.status.id=1&&take=15`
    })
    )
  }, [adminLocal, adminTotal, passado, dispatch, profile.companyId, profile.id])

  return (
    <Card>
      <CardHeader>
        <ContainerHeader>
          <Header
            title="Próximas reuniões"
            ativo={!passado}
            onClick={handleChangeToFuture}>
          </Header>
          <Header
            title="Reuniões anteriores"
            ativo={passado}
            onClick={handleChangeToPast}>
          </Header>
          {(internalMeeting || externalMeeting || adminTotal || adminLocal) && (
            <button
              onClick={() => handleRedirectToMeeting()}
              type="button"
              className="btn btn-sm float-right">
              <FaPlus color="#1b9f36" />
            </button>)}
        </ContainerHeader>
      </CardHeader>
      <CardBody>
        <ContainerMeeting>
          {loadingMeeting && <Loading card={true} />}
          {!loadingMeeting && (
            <Table
              keyField="id"
              data={meetingData}
              columns={columns}
              striped={false}
              paginationOptions={
                {
                  sizePerPageList: [{
                    text: '3', value: 3
                  }, {
                    text: '6', value: 6
                  }],
                  hidePageListOnlyOnePage: true,
                }
              }
            />)}
        </ContainerMeeting>
      </CardBody>
    </Card>
  )
}
