import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

// components
import { Card, CardBody } from '~/components/Card'
import Header from '~/components/Header'
import Description from '~/components/Description'
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Table from '~/components/Table'
import TooltipCustom from '~/components/TooltipCustom'
import Loading from '~/components/Loading'
import SwitchButton from '~/components/Form/SwitchButton'
import Button from '~/components/Form/Button'
import Col from '~/components/Bootstrap/Col'
import { FormFile } from './styles'
import { toast } from 'react-toastify'

import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'

// icons
import { FaCaretDown, FaCaretUp, FaUpload, FaUsers } from 'react-icons/fa'

// actions (reducers)
import {
  createProfileRequest,
  getUsersRequest,
} from '~/store/modules/user/actions'
import { signInRequest, updateRequest } from '~/store/modules/auth/actions'

// services
// import { cpfValidate } from '~/utils/validation'
import history from '~/services/history'
import api from '~/services/api'
import { Modal } from 'react-bootstrap'
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth'
import { confirmAlert } from 'react-confirm-alert'
import Select from '~/components/Form/Select'

export default function User({ location }) {
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.auth)

  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  const data = location.state
  const formData = new FormData()
  const {
    data: usersData,
    loading: loadingUser,
    created,
  } = useSelector((state) => state.user)

  const { control, handleSubmit, setValue, register, getValues, reset, watch } =
    useForm()

  const [loading, setLoading] = useState(false)

  const [createdBool, setCreatedBool] = useState(true)

  const [profilePic, setProfilePic] = useState(null)

  const [profilePicCropped, setProfilePicCropped] = useState(null)

  const [modalCrop, setModalCrop] = useState(false)

  const [loginBool, setLoginBool] = useState(false)

  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState(null)

  const [email, setEmail] = useState(null)

  const auth = getAuth()

  const imgRef = useRef(null)

  const file = watch('file')

  const previewCanvasRef = useRef(null)

  const [companiesOptions, setCompaniesOptions] = useState([])

  async function onSubmit(datas) {
    if (!loginBool && !data?.id && !datas.email)
      toast.error('O email é obrigatório.')
    else if (!loginBool && !data?.id && !datas.password)
      toast.error('A senha é obrigatória.')
    // if (!cpfValidate(datas.cpf)) {
    //   toast.error('O CPF está inválido.')
    // }
    else if (
      loginBool &&
      !adminTotal &&
      !(await signInSucces(datas.email, datas.password))
    )
      toast.error('A senha informada está incorreta.')
    else if (loginBool && datas.passwordNew1 !== datas.passwordNew2)
      toast.error('As senhas inseridas não estão iguais.')
    else if (!datas.name) toast.error('O nome do usuário é obrigatório.')
    else if (!data?.id && datas.password !== datas.password2)
      toast.error('As senhas inseridas não estão iguais.')
    else {
      if (profilePicCropped) formData.append('file', profilePicCropped)
      let companyIdTemp = datas.companyId
      if (!data?.id) companyIdTemp = profile.companyId

      dispatch(
        createProfileRequest({
          name: datas.name,
          email: datas.email,
          password: loginBool
            ? datas.passwordNew1
            : !data.id
            ? datas.password
            : undefined,
          id: datas.id,
          companyId: !data?.id ? companyIdTemp : companyIdTemp.value,
          companies: datas.companies?.map(({ value }) => value),
          permissions: datas.meetingCB ? [1, 2] : [],
          file: formData,
        })
      )
    }
  }

  async function signInSucces(email, password) {
    // let b = true
    const b = await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })
    // console.log(b)
    return b
  }

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'name',
      text: 'Nome',
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <>
              <FaCaretUp />
              <FaCaretDown />
            </>
          )
        else if (order === 'asc')
          return (
            <>
              <FaCaretUp color="#4b8a52" />
              <FaCaretDown />
            </>
          )
        else if (order === 'desc')
          return (
            <>
              <FaCaretUp />
              <FaCaretDown color="#4b8a52" />
            </>
          )
        return null
      },
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'role.label',
      text: 'Tipo',
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <>
              <FaCaretUp />
              <FaCaretDown />
            </>
          )
        else if (order === 'asc')
          return (
            <>
              <FaCaretUp color="#4b8a52" />
              <FaCaretDown />
            </>
          )
        else if (order === 'desc')
          return (
            <>
              <FaCaretUp />
              <FaCaretDown color="#4b8a52" />
            </>
          )
        return null
      },
    },
    {
      dataField: 'company.fantasyName',
      text: 'Empresa',
      hidden: !!adminLocal,
    },
  ]

  function handleChangeToForm(item) {
    if (adminLocal || adminTotal) {
      history.push('/usuarios', {
        id: item ? item.id : null,
        cadastrar: true,
      })
    }
  }

  function handleChangeToList() {
    history.push('/usuarios', {
      cadastrar: false,
    })

    if (adminTotal) dispatch(getUsersRequest())
    else if (adminLocal)
      dispatch(
        getUsersRequest({
          companyId: profile.companyId,
        })
      )
    setProfilePicCropped(null)
    formData.delete('file')
    reset()
  }

  useEffect(() => {
    if (adminTotal) dispatch(getUsersRequest())
    else if (adminLocal)
      dispatch(
        getUsersRequest({
          companyId: profile.companyId,
        })
      )
  }, [dispatch, adminTotal, adminLocal, profile.companyId])

  useEffect(() => {
    const getCompanies = () => {
      setLoading(true)
      api
        .get('company')
        .then(({ data: dataCompany }) => {
          setLoading(false)
          setCompaniesOptions(
            dataCompany.map(({ id, fantasyName }) => ({
              value: id,
              label: fantasyName,
            }))
          )
        })
        .catch(() => {
          setLoading(false)
        })
    }
    getCompanies()
  }, [adminLocal])

  useEffect(() => {
    function loadUsers() {
      setLoading(true)
      api
        .get(
          `user/${data.id}?include.role=true&&include.permissions=true&&include.company=true`
        )
        .then(({ data: dataUser }) => {
          setValue('id', dataUser.id)
          setValue('name', dataUser.name)
          setValue('companyId', {
            value: dataUser.company.id,
            label: dataUser.company.fantasyName,
          })
          setValue('email', dataUser.email)
          setEmail(dataUser.email)
          const profileCompanies = JSON.parse(dataUser?.companies ?? '[]')
          let query = ''
          profileCompanies.forEach((elem, i) => {
            query += `${i > 0 ? '&&' : ''}where.OR[${i}].id=${elem}`
          })
          if (query)
            api
              .get(`company?${query}`)
              .then(({ data: dataCompany }) => {
                setLoading(false)
                setValue(
                  'companies',
                  dataCompany?.map(({ id, fantasyName }) => ({
                    value: id,
                    label: fantasyName,
                  }))
                )
              })
              .catch(() => {
                setLoading(false)
              })
          setValue('logoLink', data.logoLink)
          setValue('meetingCB', data.permissions.map((e) => e.id).includes(2))
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
    if (data?.id) loadUsers()
  }, [data, setValue])

  useEffect(() => {
    if (created && createdBool) {
      setCreatedBool(false)
      if (!data?.id)
        reset({
          internalCb: false,
          externalCb: false,
        })
      else if (email === profile.email) {
        if (loginBool)
          dispatch(signInRequest(profile.email, getValues('passwordNew1')))
        else dispatch(updateRequest(profile.email))
      }
    }
  }, [
    created,
    dispatch,
    profile.email,
    reset,
    data?.id,
    createdBool,
    email,
    getValues,
    loginBool,
  ])

  useEffect(() => {
    if (data?.id) setLoginBool(false)
  }, [data?.id])

  function getCroppedImg() {
    return new Promise((resolve, reject) => {
      previewCanvasRef.current.toBlob(
        (blob) => {
          blob.name = 'file'
          resolve(blob)
        },
        'image/jpeg',
        1
      )
    })
  }

  async function handleUploadFile() {
    if (
      previewCanvasRef.current.width !== 0 ||
      previewCanvasRef.current.height !== 0
    ) {
      const croppedImage = await getCroppedImg()
      setProfilePicCropped(croppedImage)
    } else setProfilePicCropped(file[0])
    setModalCrop(false)
    toast.success('Foto carregada com sucesso.')
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (file?.length > 0) {
      setModalCrop(true)
      const reader = new FileReader()
      reader.addEventListener('load', () => setProfilePic(reader.result))
      reader.readAsDataURL(file[0])
    }
  }, [file])

  function handleActivate(item) {
    confirmAlert({
      message: `Deseja realmente ${
        item.active ? 'inativar' : 'ativar'
      } esse usuário?`,
      buttons: [
        {
          label: 'Sim',
          className: 'form-control',
          onClick: () => {
            setLoading(true)
            api
              .put(`user/${item.id}`, { active: !item.active })
              .then(() => {
                setLoading(false)
                toast.success(
                  `Usuário ${
                    item.active ? 'inativado' : 'ativado'
                  } com sucesso.`
                )
                if (adminTotal) dispatch(getUsersRequest())
                else if (adminLocal)
                  dispatch(
                    getUsersRequest({
                      companyId: profile.companyId,
                    })
                  )
              })
              .catch(() => {
                setLoading(false)
                toast.error(
                  `Erro ao ${item.active ? 'inativar' : 'ativar'} usuário.`
                )
              })
          },
        },
        {
          label: 'Não',
          className: 'form-control',
          onClick: () => {},
        },
      ],
    })
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
  }, [completedCrop])

  return (
    <>
      {(loadingUser || loading) && <Loading />}
      <div className="row mb-2 mt-4 d-flex align-items-center ml-2">
        {(adminLocal || adminTotal) && (
          <Header
            title="Listar usuários"
            ativo={!data?.cadastrar}
            onClick={handleChangeToList}
          ></Header>
        )}
        <Header
          title={data?.id ? 'Atualizar usuário' : 'Cadastrar usuário'}
          ativo={!!data?.cadastrar}
          onClick={() => handleChangeToForm()}
        ></Header>
        {(adminLocal || adminTotal) && (
          <TooltipCustom text="Nessa página você tem acesso a visualização, cadastro e edição de usuário" />
        )}
      </div>
      {!!data?.cadastrar && (
        <div className="content mt-4">
          <Card>
            <CardBody>
              <Description
                icon={<FaUsers color="#495057" size={30} className="mr-2" />}
                title="Dados do usuário"
              />
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <input hidden {...register('id')} />
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        xl="12"
                        lg="12"
                        label="Nome"
                        id={field.name}
                        type="text"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Row>
                {!!data?.id && (
                  <Button
                    type="button"
                    className="btn-outline-info btn mb-2"
                    onClick={() => setLoginBool(!loginBool)}
                    title="Alterar senha"
                  />
                )}
                {data?.id && (
                  <Row>
                    <Controller
                      name="companyId"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          label="Empresa principal"
                          isClearable={false}
                          value={field.value}
                          handleChangeValue={field.onChange}
                          name={field.name}
                          xl="12"
                          lg="12"
                          options={companiesOptions}
                          style={{
                            multiValue: (styles) => ({
                              ...styles,
                              background: 'transparent',
                            }),
                            multiValueRemove: (styles) => ({
                              ...styles,
                              ':hover': {
                                background: 'none',
                                color: '#DE350B',
                              },
                            }),
                          }}
                          formatOptionLabel={({ label }) => (
                            <div>
                              <span>{label}</span>
                            </div>
                          )}
                        />
                      )}
                    />
                  </Row>
                )}
                {adminTotal && (
                  <Row>
                    <Controller
                      name="companies"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          label="Empresas"
                          isClearable={false}
                          value={field.value}
                          handleChangeValue={field.onChange}
                          name={field.name}
                          xl="12"
                          lg="12"
                          multiOptions
                          options={companiesOptions}
                          style={{
                            multiValue: (styles) => ({
                              ...styles,
                              background: 'transparent',
                            }),
                            multiValueRemove: (styles) => ({
                              ...styles,
                              ':hover': {
                                background: 'none',
                                color: '#DE350B',
                              },
                            }),
                          }}
                          formatOptionLabel={({ label }) => (
                            <div>
                              <span>{label}</span>
                            </div>
                          )}
                        />
                      )}
                    />
                  </Row>
                )}
                {!loginBool && !data?.id && (
                  <>
                    <Row>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="E-mail"
                            id={field.name}
                            type="email"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="Senha"
                            id={field.name}
                            placeholder="********"
                            type="password"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>
                    <Row>
                      <Controller
                        name="password2"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="Repetir senha"
                            id={field.name}
                            placeholder="********"
                            type="password"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>
                  </>
                )}
                {loginBool && (
                  <>
                    {!adminTotal && (
                      <Row>
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Input
                              label="Senha atual"
                              id={field.name}
                              placeholder="********"
                              type="password"
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </Row>
                    )}
                    <Row>
                      <Controller
                        name="passwordNew1"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="Nova senha"
                            id={field.name}
                            placeholder="********"
                            type="password"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <Controller
                        name="passwordNew2"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="Repetir nova senha"
                            id={field.name}
                            placeholder="********"
                            type="password"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>
                  </>
                )}
                {(adminTotal || adminLocal) && (
                  <Row>
                    <Controller
                      name="meetingCB"
                      control={control}
                      defaultValue={true}
                      render={({ field }) => (
                        <SwitchButton
                          label="Criar reuniões"
                          id={field.name}
                          name={field.name}
                          onChange={(name, val) => {
                            field.onChange(val)
                          }}
                          md="6"
                          sm="6"
                          col="12"
                          size="md"
                          lg="2"
                          xl="2"
                          checked={field.value}
                        />
                      )}
                    />
                  </Row>
                )}
                <Row>
                  <Col
                    className="d-flex align-items-end mb-2"
                    col="12"
                    sm="6"
                    md="6"
                    xl="2"
                    lg="2"
                  >
                    <FormFile>
                      <label
                        title="O arquivo deve ser em .png ou .jpg ou .jpeg."
                        htmlFor="file"
                        className="btn btn-default form-control"
                      >
                        <FaUpload /> Enviar foto
                      </label>
                      <input
                        id="file"
                        type="file"
                        accept=".png, .jpg, .jpeg, .gif"
                        {...register('file')}
                      />
                    </FormFile>
                  </Col>
                </Row>
                <Modal
                  size="xl"
                  show={modalCrop}
                  backdrop="static"
                  onHide={() => {
                    setModalCrop(false)
                    setValue('file', null)
                  }}
                >
                  <Modal.Header>
                    <Modal.Title className="w-100">Ajustar tamanho</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col>
                        <ReactCrop
                          onImageLoaded={onLoad}
                          src={profilePic}
                          onChange={(newCrop) => setCrop(newCrop)}
                          crop={crop}
                          onComplete={(c) => setCompletedCrop(c)}
                        />
                      </Col>
                      <Col>
                        <div>
                          <canvas
                            ref={previewCanvasRef}
                            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                            style={{
                              width: Math.round(completedCrop?.width ?? 0),
                              height: Math.round(completedCrop?.height ?? 0),
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      className="btn-default form-control"
                      onClick={handleUploadFile}
                      title="Finalizar"
                    />
                  </Modal.Body>
                </Modal>
                <Button
                  type="submit"
                  className="btn-default form-control"
                  title={data && data.id ? 'Atualizar' : 'Cadastrar'}
                />
              </form>
            </CardBody>
          </Card>
        </div>
      )}
      {(adminLocal || adminTotal) && !data?.cadastrar && (
        <div className="content mt-4">
          <Table
            keyField="id"
            data={usersData}
            columns={columns}
            extrasColumns={[
              {
                text: 'Editar',
                className: 'btn btn-sm btn-default',
                onClick: handleChangeToForm,
                buttonText: 'Editar',
              },
              {
                text: 'Inativar',
                keyConditionButtonText: 'active',
                className: 'btn btn-sm btn-default',
                onClick: handleActivate,
                buttonText: 'Inativar',
              },
            ]}
          />
        </div>
      )}
    </>
  )
}

User.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      cadastrar: PropTypes.bool,
    }),
  }),
}

User.defaultProps = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: '',
      cadastrar: false,
    }),
  }),
}
