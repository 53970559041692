import styled from "styled-components"

export const ContainerHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
`
export const ContainerHeaderOptions = styled.div`
  span {
    background-color: #5e5e5e;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    font-size: 14px;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: 0.8em;
    top: 0em;
  }
`
export const ContainerAction = styled.div`
  .td-action {
    .row{
      align-items: center;
    }
    i {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2px;
    }
    strong {
      color: #23527c;
      font-size: 14px;
      padding: 0px;
      margin: 0px;
    }
  }
`
