import * as types from '../types'

export function getCompanyRequest() {
  return {
    type: types.GET_COMPANY_REQUEST,
    payload: {},
  }
}

export function getCompanySuccess(data) {
  return {
    type: types.GET_COMPANY_SUCCESS,
    payload: {
      data,
    },
  }
}
export function saveCompanyRequest(data) {
  return {
    type: types.SAVE_COMPANY_REQUEST,
    payload: { data },
  }
}

export function saveCompanySuccess(data) {
  return {
    type: types.SAVE_COMPANY_SUCCESS,
    payload: { data },
  }
}

export function getCompanyFailure() {
  return {
    type: types.REQUEST_FAILURE,
    payload: {},
  }
}
