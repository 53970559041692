import styled from "styled-components"
import { Card, CardBody } from "~/components/Card"

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const ContainerCard = styled(Card)`
  & > .card-body.row {
    display: block;
    overflow: auto;
    white-space: nowrap;
    /* transform: rotateX(180deg); */
    & > .col-xl-4 {
      display: inline-block;
      /* transform: rotateX(180deg);  */

    }
    /* &::-webkit-scrollbar
    {
      width: 0px;
    } */
  }
`
export const ContainerCardHeader = styled.div`
  display: flex;
  margin: 40px 30px 10px 30px;
  align-items: flex-start;

  label {
    font-family: Roboto Condensed, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #35393a;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    letter-spacing: 0px;
  }
  svg{
    cursor: pointer;
    margin-left: 1em;
  }
`


export const ContainerCardBody = styled.div`
  display: flex;
  justify-content: space-evenly;
`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const ColumnCard = styled(Card)`
  padding: 0;
  border: none;
  box-shadow: none;
`

export const ColumnCardBody = styled(CardBody)`
  padding: 5px;
  margin: 0 30px 0 30px;
`

export const ColumnHeader = styled.div`
  padding: 4px;
`
export const ColumnHeaderTitle = styled.div`
  /* text-align: center; */
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    /* padding-left: .25rem; */
    margin-bottom: 20px;
    color: #686B6D;
    /* display: inline-block; */
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  span {
    background-color: #5e5e5e;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: 0.8em;
    top: 0em;
  }
`


export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
`


export const ColumnWrapperContent = styled.div`
  border-radius: 5px;
  /* border-left: 1px solid ${props => props.snapshot.isDraggingOver
    ? "#1b9f36"
    : "#f2f2f2"};
  border-right: 1px solid ${props => props.snapshot.isDraggingOver
    ? "#1b9f36"
    : "#f2f2f2"}; */
  background-color: ${props => props.snapshot.isDraggingOver
    ? "rgba(8, 8, 8, 0.028)"
    : "white"};
  padding: 4px;
  height: 420px;
  overflow: auto;
  /* overflow-y: hidden; */
  &:hover{
    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar
    {
      width: 3px;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
  }
  &::-webkit-scrollbar
  {
    width: 0px;
  }
  &:not(:hover) {
    overflow: hidden;
  }
`

export const ContentCard = styled.div.attrs(props => ({
  style: {
    border: props.snapshot.isDragging
      ? '1px solid'
      : 'none',
    borderColor: props.snapshot.isDragging
      ? "rgba(27, 157, 53, 0.38)"
      : ''
  }
}))`
  display: flex;
  padding: 10px;
  margin: 0 0 16px 0;
  min-height: 50px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 28%);
  flex-direction: column;
  transition: all ease .2s;
  &:hover {
    box-shadow: 0 4px 16px rgb(0 0 0 / 40%);
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #686b6d;
    font-size: 14px;
    font-weight: 500;
    color: #686B6D;
    word-break: break-word;
  }
  div {
    img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      object-fit: contain;
      margin-left: .4em;
      margin-bottom: .2em;
      float: right;
    }
    span>span {
      color: white;
    }
    div {
      display: flex;
      flex-direction: column;
    }
    svg {
      cursor: pointer;
    }
  }
  small {
    margin-top: .2em;
  }
`

