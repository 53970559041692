import React from 'react'
import PropTypes from 'prop-types'

export default function Col({
  // reponsividade breakpoints (Extra small <576px)
  col,
  // reponsividade breakpoints (Small >=576px)
  sm,
  // reponsividade breakpoints (Medium >=768px)
  md,
  // reponsividade breakpoints (Large >=992px)
  lg,
  // reponsividade breakpoints (Extra Large >=1200px)
  xl,
  children,
  className
}) {
  return <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl} ${className}`}>{children}</div>
}


Col.propTypes = {
  col: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
  className: PropTypes.string,
}

Col.defaultProps = {
  col: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
  className: '',
}
