import * as types from '../types'

export function createMeetingRequest(data) {
  return {
    type: types.CREATE_MEETING_REQUEST,
    payload: { data },
  }
}
export function createMeetingSuccess(data) {
  return {
    type: types.CREATE_MEETING_SUCCESS,
    payload: { data },
  }
}

export function getMeetingsRequest(data) {
  return {
    type: types.GET_MEETINGS_REQUEST,
    payload: { data },
  }
}

export function getMeetingsSuccess(data) {
  return {
    type: types.GET_MEETINGS_SUCCESS,
    payload: { data },
  }
}

export function meetingFailure() {
  return {
    type: types.MEETING_FAILURE,
  }
}
