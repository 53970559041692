import * as types from '../types'

export function createMessageRequest(data) {
  return {
    type: types.CREATE_MESSAGE_REQUEST,
    payload: { data },
  }
}
export function createMessageSuccess() {
  return {
    type: types.CREATE_MESSAGE_SUCCESS,
    payload: {},
  }
}

export function getMessagesRequest(data) {
  return {
    type: types.GET_MESSAGES_REQUEST,
    payload: { data },
  }
}

export function getMessagesSuccess(data) {
  return {
    type: types.GET_MESSAGES_SUCCESS,
    payload: { data },
  }
}

export function messageFailure() {
  return {
    type: types.MESSAGE_FAILURE,
  }
}
