import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, Controller } from "react-hook-form"
import { toast } from 'react-toastify'

// components
import { Card, CardBody } from '~/components/Card'
import Header from '~/components/Header'
import Description from '~/components/Description'
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Button from '~/components/Form/Button'
import Table from '~/components/Table'
import TooltipCustom from '~/components/TooltipCustom'
import Loading from '~/components/Loading'
import CreatableSelect from '~/components/Form/CreatableSelect'
import ColorPicker from '~/components/Form/ColorPicker'
import Select from '~/components/Form/Select'
import { CardFile, ContainerAcao, ContainerAvatar, ContainerParticipantes, ContainerPauta, ContainerReuniao, ContainerRSVP } from './styles'
import Topic from './Topic'
import Action from './Action'

// icons
import { FaCaretDown, FaCaretUp, FaPaperclip, FaPlus, FaSortDown, FaSortUp, FaTrash } from 'react-icons/fa'
import { MdMessage } from 'react-icons/md'

// actions (reducers)
import { getWorkgroupsRequest } from '~/store/modules/workgroup/actions'
import { createMeetingRequest, getMeetingsRequest } from '~/store/modules/meeting/actions'

// services
import history from '~/services/history'
import api from '~/services/api'
import { durationMask } from '~/utils/validation'
import { add, format, formatDistance, formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Collapse, Modal } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import Avatar from 'react-avatar'
import Col from '~/components/Bootstrap/Col'
import { useMediaQuery } from 'react-responsive'
import { Comment, CommentContent, CommentHeader } from './Action/styles'
import { Markup } from 'interweave'

export default function Meeting({ location }) {
  const dispatch = useDispatch()

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' })

  const { control, handleSubmit, setValue, register, reset, getValues, watch } = useForm()

  const { profile } = useSelector(state => state.auth)
  const { data: meetingData, loading: loadingMeeting, meeting } = useSelector(state => state.meeting)
  const { data: workgroupOptions, loading: loadingWorkgroup } = useSelector(state => state.workgroup)

  const data = location.state

  const [meetingFinalized, setMeetingFinalized] = useState(false)

  const [usuarioOptions, setUsuarioOptions] = useState([])
  const [participantes, setParticipantes] = useState([])
  const [participantesAssinaturas, setParticipantesAssinaturas] = useState([])

  const [creator, setCreator] = useState(profile)

  const [pautas, setPautas] = useState([])
  const [acoes, setAcoes] = useState([])
  const [arquivos, setArquivos] = useState([])

  const [comentarioArquivoCollapse, setComentarioArquivoCollapse] = useState([])

  const [locationsOptions, setLocationsOptions] = useState([])
  const [dateMeeting, setDateMeeting] = useState(new Date())

  const [topicId, setTopicId] = useState(null)
  const [actionId, setActionId] = useState(null)
  const [createdTime, setCreatedTime] = useState(new Date())
  const [allottedTime, setAllottedTime] = useState(0)
  const [selectedColor, setSelectedColor] = useState('')

  const [pautaModal, setPautaModal] = useState(false)
  const [reuniaoAtiva, setReuniaoAtiva] = useState(true)
  const [acaoModal, setAcaoModal] = useState(false)
  const [arquivosModal, setArquivosModal] = useState(false)
  const [RSVPModal, setRSVPModal] = useState(false)
  const [resumoReuniao, setResumoReuniao] = useState(false)

  const [loading, setLoading] = useState(false)
  const [loadingAction, setLoadingAction] = useState(false)
  const [loadingTopic, setLoadingTopic] = useState(false)
  const [loadingMeetings, setLoadingMeetings] = useState(false)
  const [loadingWorkgroups, setLoadingWorkgroups] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)
  const [loadingLocation, setLoadingLocation] = useState(false)

  const [isCreator, setIsCreator] = useState(false)

  const workgroup = watch('workgroup')
  const users = watch('users', [])

  const internalMeeting = profile.permissions?.map(e => e.id).includes(1)
  const externalMeeting = profile.permissions?.map(e => e.id).includes(2)
  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  const options = {
    locale: ptBR
  }

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'title.value',
      text: 'Assunto',
    },
    {
      dataField: 'start',
      text: 'Data',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      formatter: (cell, row, rowIndex) => (
        <div>
          {format(new Date(row.start), "dd/MM/yyyy HH:mm")}
        </div>
      )
    },
  ]

  const rowStyles = (row) => {
    const style = {}
    style.borderLeft = `${row.color} 15px solid`
    return style
  }

  const defaultColors = [
    "#64c4fe",
    "#fbd327",
    "#f59384",
    "#deadee",
    "#56cbb4",
  ]

  const onSubmit = data => {
    if (!data.title) {
      toast.error('O título da reunião é obrigatório.')
    } else if (!data.start) {
      toast.error('A data é obrigatória.')
    } else if (!data.location) {
      toast.error('O local da reunião é obrigatório.')
    } else if (!data.duration) {
      toast.error('A duração é obrigatória.')
    } else if (!selectedColor) {
      toast.error('Selecione uma cor.')
    } else if (!data.users || !data.users.length) {
      toast.error('Selecione pelo menos um participante.')
    } else {
      dispatch(
        createMeetingRequest({
          id: meeting?.id ? meeting.id : data.id,
          start: new Date(data.start).toISOString(),
          duration: parseInt(data.duration),
          participants: [...users.map(e => e.value), profile.id],
          color: selectedColor,
          title: data.title,
          meetingLocalId: data.location?.value,
          convenerUserId: profile.id,
          videoConferenceLink: data.videoConferenceLink
        })
      )
      setIsCreator(true)
      setDateMeeting(new Date(data.start))
      setReuniaoAtiva(false)
      setResumoReuniao(true)
      setParticipantes(data.users.filter(({ value }) => value !== profile.id))
      setCreatedTime(new Date())
      setAllottedTime(parseInt(data.duration))
    }
  }

  function handleCreateLocation(e) {
    setLoading(true)
    api
      .post('meeting-local', {
        value: e,
        creatorUserId: profile.id,
      })
      .then((response) => {
        setLocationsOptions(e =>
          [...e,
          {
            value: response.data.id,
            label: response.data.value,
          }
          ])
        setValue('location', {
          value: response.data.id,
          label: response.data.value,
        })
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        toast.error('Ocorreu um erro ao tentar adicionar essa opção.')
      })
  }

  function handleChangeToForm(item) {
    if (!data?.id)
      if (adminLocal || adminTotal || internalMeeting || externalMeeting) {
        history.push('/reuniao', {
          id: item ? item.id : null,
          cadastrar: true
        })
      }
  }

  function handleChangeToList() {
    history.push("/reuniao", {
      id: null,
      cadastrar: false
    })
    setReuniaoAtiva(true)
    setPautaModal(false)
    setSelectedColor('')
    setResumoReuniao(false)
    setAcoes([])
    setPautas([])
    setMeetingFinalized(false)
    setIsCreator(false)
    dispatch(
      getMeetingsRequest({
        id: profile.id,
        adminLocal,
        adminTotal,
        companyId: profile.companyId,
        queryMeeting: '&&orderBy.start=asc'
      })
    )
    reset()
  }

  function handleColorSelect(e) {
    setSelectedColor(e.hex)
  }

  function handleEditarPauta(e) {
    setPautaModal(true)
    setTopicId(e.id)
  }

  function handleEditarAcao(e) {
    setAcaoModal(true)
    setActionId(e.id)
  }

  function handleConvocarParticipantes() {
    setLoading(true)
    api.put(`meeting/invite-participants/${data?.id ? data.id : meeting?.id}`)
      .then((e) => {
        toast.success('Participantes convocados com sucesso.')
        setLoading(false)
      }).catch((e) => {
        toast.error('Falha ao convocar participantes.')
        setLoading(false)
      })
  }

  function handleEditarReuniao() {
    setReuniaoAtiva(true)
  }

  function handleRSVP() {
    setRSVPModal(true)
  }

  function handleArquivos() {
    setArquivosModal(true)
  }


  function handleComentarioArquivoCollapse(id) {
    setComentarioArquivoCollapse(
      comentarioArquivoCollapse?.map((e) => {
        if (e.id === id) e.value = !e.value
        return e
      })
    )
    // console.log(comentarioArquivoCollapse)
  }


  function handleDeletarReuniao(item) {
    confirmAlert({
      title: 'Exclusão',
      message:
        'Deseja realmente excluir permanentemente essa reunião? Essa ação não poderá ser desfeita.',
      buttons: [
        {
          label: 'Sim. Eu desejo.',
          onClick: () => {
            if (item.id) {
              setLoading(true)
              api.delete(`meeting/${item.id}`).then(() => {
                toast.success(`Reunião ${item.title.value} deletada com sucesso.`)
                setLoading(false)
                handleChangeToList()
              }).catch(() => {
                toast.error('Erro ao deletar a reunião.')
                setLoading(false)
              })
            }
            else {
              setLoading(true)
              api.delete(`meeting/${data?.id ? data.id : meeting?.id}`).then(() => {
                toast.success(`Reunião ${getValues('title')?.label} deletada com sucesso.`)
                setResumoReuniao(false)
                setLoading(false)
                handleChangeToList()
              }).catch(() => {
                setLoading(false)
                toast.error('Erro ao deletar a reunião.')
              })
            }
          },
        },
        {
          label: 'Cancelar essa ação.',
          onClick: () => { },
        },
      ],
    })
  }

  function handleDeletarPauta() {
    confirmAlert({
      title: 'Exclusão',
      message:
        'Deseja realmente excluir permanentemente essa pauta? Essa ação não poderá ser desfeita.',
      buttons: [
        {
          label: 'Sim. Eu desejo.',
          onClick: () => {
            setPautaModal(false)
            setLoadingTopic(true)
            api.delete(`topic/${topicId}`).then(() => {
              setLoadingTopic(false)
              toast.success(`Pauta deletada com sucesso.`)
              getTopics()
              getActions()
            }).catch(() => {
              setLoadingTopic(false)
              toast.error('Erro ao deletar a pauta.')
            })
          }
        },
        {
          label: 'Cancelar essa ação.',
          onClick: () => { },
        },
      ],
    })
  }
  function handleDeletarAcao() {
    confirmAlert({
      title: 'Exclusão',
      message:
        'Deseja realmente excluir permanentemente essa ação? Essa ação não poderá ser desfeita.',
      buttons: [
        {
          label: 'Sim. Eu desejo.',
          onClick: () => {
            setAcaoModal(false)
            setLoadingAction(true)
            api.delete(`action/${actionId}`).then(() => {
              toast.success(`Ação deletada com sucesso.`)
              setLoadingAction(false)
              getActions()
            }).catch(() => {
              setLoadingAction(false)
              toast.error('Erro ao deletar a ação.')
            })
          }
        },
        {
          label: 'Cancelar essa ação.',
          onClick: () => { },
        },
      ],
    })
  }

  function handleIrParaAta() {
    history.push('/reuniao/ata', {
      id: data?.id ? data.id : meeting?.id,
    })
  }

  const getActions = useCallback(() => {
    if (data?.id || meeting?.id) {
      setLoadingAction(true)
      api.get(`action?include.attachments=true&&include.comments=true&&include.responsibles.include.user=true&&include.topic=true&&include.deadline.include.dateMarking=true&&include.extendedDeadline.include.dateMarking=true&&where.topic.meetingId=${data?.id ? data.id : meeting?.id}&&orderBy.createdAt=asc`)
        .then(({ data: action }) => {
          const parseAction = action.map(e => {
            e.deadline = e.deadline ? e.deadline.dateMarking.date : null
            e.extendedDeadline = e.extendedDeadline ? e.extendedDeadline.dateMarking.date : null
            e.hasAttachment = e.attachments.length > 0
            e.hasComment = e.comments.length > 0
            return e
          })
          setAcoes(parseAction)
          setLoadingAction(false)
        })
        .catch(() => {
          setLoadingAction(false)
        })
    }
  }, [data?.id, meeting?.id])

  const getTopics = useCallback(() => {
    if (data?.id || meeting?.id) {
      setLoadingTopic(true)
      api.get(`topic?include.monitors.include.user=true&&include.meeting=true&&where.meetingId=${data?.id ? data.id : meeting.id}&&orderBy.createdAt=asc`)
        .then(({ data: topic }) => {
          setPautas(topic)
          setAllottedTime(
            topic.map(({ meeting }) => meeting)[0].duration
            - topic.map(({ allottedTime }) => allottedTime).reduce((a, b) => a + b, 0)
          )
          setLoadingTopic(false)
          // console.log(topic.map(({ meeting }) => meeting)[0])
        })
        .catch(() => {
          setLoadingTopic(false)
        })
    }
  }, [data?.id, meeting?.id])


  const getParticipantes = () => {
    if (data?.id || meeting?.id)
      api
        .get(
          `invitation?where.meeting.id=${data.id ? data.id : meeting.id}&&include.participant.include.user.include.company=true`)
        .then(({ data: loadParticipant }) => {
          const parseParticipantes =
            loadParticipant.map(({ participant }) => participant)
              .map(({ user }) => user)
              .map(
                ({ id, name, email, logoLink, company }) => {
                  let mConfirmed = false
                  let mAnswered = false
                  loadParticipant.forEach(({ participant, confirmed, answered }) => {
                    if (participant.userId === id) {
                      mConfirmed = confirmed
                      mAnswered = answered
                    }
                  })
                  return {
                    value: id,
                    label: name + ' | ' + email,
                    logoLink: logoLink,
                    email,
                    name,
                    company,
                    confirmed: mConfirmed,
                    answered: mAnswered
                  }
                })
          // console.log(parseParticipantes.filter(({ value }) => value !== creator?.id).map(({ value }) => value).sort())
          if (
            JSON.stringify(parseParticipantes.filter(({ value }) => value !== creator?.id).map(({ value }) => value).sort())
            === JSON.stringify(participantes.map(({ value }) => value).sort()))
            setParticipantes(parseParticipantes.filter(({ value }) => value !== creator?.id))
        }).catch(() => {
          toast.error('Erro ao atualizar RSVP.')
        })
  }

  const getParticipantesAssinaturas = useCallback(() => {
    if (meetingFinalized)
      if (data?.id || meeting?.id)
        api
          .get(
            `signature?where.meeting.id=${data.id ? data.id : meeting.id}&&include.participant.include.user.include.company=true`)
          .then(({ data: loadParticipant }) => {
            const parseParticipantes =
              loadParticipant
                .map(({ participant }) => participant)
                .map(({ user }) => user)
                .map(
                  ({ id, name, email, logoLink, company }) => {
                    return {
                      value: id,
                      label: name + ' | ' + email,
                      logoLink: logoLink,
                      email,
                      name,
                      company
                    }
                  }
                )
            // if (
            //   JSON.stringify(parseParticipantes.map(({ value }) => value).sort())
            //   === JSON.stringify(participantes.map(({ value }) => value).sort()))
            setParticipantesAssinaturas(parseParticipantes)
          }).catch(() => { })
  }, [data?.id, meeting?.id, meetingFinalized])

  const handleCloseTopicModal = useCallback(() => {
    getTopics()
    setPautaModal(false)
  }, [getTopics])

  const handleCloseActionModal = useCallback(() => {
    getActions()
    setAcaoModal(false)
  }, [getActions])


  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }


  useEffect(() => {
    dispatch(
      getWorkgroupsRequest({
        companyId: profile.companyId,
        adminLocal,
        adminTotal,
        id: profile.id,
      })
    )
    dispatch(
      getMeetingsRequest({
        id: profile.id,
        adminLocal,
        adminTotal,
        companyId: profile.companyId,
        queryMeeting: '&&orderBy.start=asc'
      }))
  }, [adminLocal, adminTotal, dispatch, profile.companyId, profile.id])

  useEffect(() => {
    function loadMeeting() {
      setLoadingMeetings(true)
      api
        .get(
          `meeting/${data.id}?include.convener.include.user=true&&include.topics.include.actions=true`
          + `&&include.title=true&&include.participants.include.user.include.company=true` +
          `&&include.local=true&&include.invitations.include.participant.include.user=true`
          + `&&include.attachments.include.user=true&&include.attachments.include.comment.include.user=true`)
        .then(({ data: loadMeeting }) => {
          setValue('id', loadMeeting.id)
          setValue('title', loadMeeting.title.value)
          setValue('location',
            loadMeeting.local
              ? { value: loadMeeting.local.id, label: loadMeeting.local.value }
              : null
          )
          setSelectedColor(loadMeeting.color)
          setValue('start', format(new Date(loadMeeting.start), "yyyy-MM-dd'T'HH:mm", { locale: ptBR }))
          setDateMeeting(new Date(loadMeeting.start))
          setValue('duration', loadMeeting.duration)
          setAllottedTime(parseInt(loadMeeting.duration))
          setArquivos(loadMeeting.attachments)
          loadMeeting?.attachments.forEach(a => {
            setComentarioArquivoCollapse(e => ([...e, { id: a.id, value: false }]))
          })
          const parseParticipantes =
            loadMeeting.participants
              .map(({ user }) => user)
              .map(
                ({ id, name, email, logoLink, company }) => {
                  let mConfirmed = false
                  let mAnswered = false
                  loadMeeting.invitations.forEach(({ participant, confirmed, answered }) => {
                    if (participant.userId === id) {
                      mConfirmed = confirmed
                      mAnswered = answered
                    }
                  })
                  return {
                    value: id,
                    label: name + ' | ' + email,
                    logoLink: logoLink,
                    email,
                    name,
                    company,
                    confirmed: mConfirmed,
                    answered: mAnswered
                  }
                })
          setValue('users', parseParticipantes.filter(({ value }) => value !== creator?.id))
          setValue('logoLink', loadMeeting.logoLink)
          setValue('videoConferenceLink', loadMeeting.videoConferenceLink)
          setResumoReuniao(true)
          setCreator(loadMeeting.convener.user)
          setParticipantes(parseParticipantes.filter(({ value }) => value !== creator?.id))
          setCreatedTime(loadMeeting.createdAt)
          setMeetingFinalized(loadMeeting.statusId === 2)
          setLoadingMeetings(false)
        }).catch(() => {
          setLoadingMeetings(false)
        })
    }
    if (data?.id) {
      loadMeeting()
    }
  }, [creator?.id, data, setValue])

  useEffect(() => {
    function getUsers() {
      let query
      if (internalMeeting && !externalMeeting) {
        query = `user?include.company=true&&where.companyId=${profile?.companyId}`
      } else if (adminTotal || adminLocal || externalMeeting) {
        query = 'user?include.company=true'
      }
      if (query) {
        setLoadingUsers(true)
        api.get(query).then((response) => {
          const parseResp = response.data.map(({ id, name, email, logoLink, company }) => {
            return {
              value: id,
              label: name + ' | ' + email,
              email,
              name,
              logoLink,
              company
            }
          })
          setUsuarioOptions(parseResp.filter(({ value }) => value !== profile.id))
          setLoadingUsers(false)
        }).catch(() => {
          toast.error('Erro ao carregar usuários.')
          setLoadingUsers(false)
        })
      }
    }
    function getParticipantesWorkgroup() {
      setLoadingWorkgroups(true)
      api.get(`work-group/${workgroup.value}?include.users.include.company=true`)
        .then(({ data: workgroupData }) => {
          const parseWorkgroup = workgroupData.users.map(
            ({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                email,
                name,
                logoLink,
                company
              }
            }
          )
          setValue('users', parseWorkgroup.filter(({ value }) => value !== profile?.id))
          setLoadingWorkgroups(false)
        }).catch(() => {
          toast.error('Erro ao carregar Workgroups.')
          setLoadingWorkgroups(false)
        })
    }
    getUsers()
    if (workgroup) getParticipantesWorkgroup()
  }, [adminLocal, adminTotal, externalMeeting, internalMeeting, profile, setValue, workgroup])

  useEffect(() => {
    function getLocations() {
      setLoadingLocation(true)
      api.get(`meeting-local/?where.creator.user.companyId=${profile.companyId}`)
        .then(({ data: locationData }) => {
          const parseLocation = locationData.map(
            ({ id, value }) => {
              return {
                value: id,
                label: value,
              }
            }
          )
          setLocationsOptions(parseLocation)
          setLoadingLocation(false)
        }).catch(() => {
          toast.error('Erro ao carregar locais das reuniões.')
          setLoadingLocation(false)
        })
    }
    getLocations()
  }, [profile.companyId])

  useEffect(() => {
    setIsCreator(creator?.id === profile?.id)
  }, [creator?.id, profile?.id])

  useEffect(() => {
    getParticipantesAssinaturas()
  }, [getParticipantesAssinaturas])

  useEffect(() => {
    getActions()
    getTopics()
  }, [getActions, getTopics])

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useInterval(getParticipantesAssinaturas, 8100)
  useInterval(getParticipantes, 6800)

  return (
    <>
      {(
        loading || loadingMeeting || loadingWorkgroup ||
        loadingAction || loadingTopic || loadingWorkgroups ||
        loadingMeetings || loadingUsers || loadingLocation
      )
        && <Loading />}
      {(adminLocal || adminTotal || internalMeeting || externalMeeting) && (
        <div className="row mb-4 mt-4 d-flex align-items-center ml-2">
          <Header
            title="Listar reuniões"
            ativo={!data?.cadastrar}
            onClick={handleChangeToList}>
          </Header>
          <Header
            title={
              data?.id
                ? 'Atualizar reunião'
                : 'Cadastrar reunião'}
            ativo={!!data?.cadastrar}
            onClick={handleChangeToForm}>
          </Header>
          {(adminLocal || adminTotal || internalMeeting || externalMeeting) && (
            <TooltipCustom text="Nessa página você tem acesso a visualização, cadastro e edição de reunião" />
          )}
        </div>
      )}
      {!!data?.cadastrar && (
        <Row className='mt-2'>
          <div className="col-lg-8 col-xl-9">
            <Card border={selectedColor ? `1px solid ${selectedColor}` : ''}>
              <CardBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {resumoReuniao && (
                    <ContainerReuniao>
                      <h4>{getValues('title')?.label}</h4>
                      <small className="text-wrap">
                        {'Criada em ' + format(Date.parse(createdTime), "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                          options
                        )}
                      </small>
                      <dl className="row">
                        <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3">Assunto:</dt>
                        <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap">
                          {getValues('title')}
                        </dd>
                        <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3">Local:</dt>
                        <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap">
                          {getValues('location')?.label}
                        </dd>
                        <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3">Data:</dt>
                        <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap">
                          {`
                            ${format(dateMeeting,
                            "EEEE, dd 'de' MMMM 'de' yyyy HH:mm",
                            options
                          )} -
                          ${format(
                            add(dateMeeting,
                              {
                                minutes: parseInt(getValues('duration'))
                              }
                            ), 'HH:mm', options)}
                          (${formatDistance(dateMeeting, new Date(), { ...options, addSuffix: true })})
                          `}
                        </dd>
                        {getValues('videoConferenceLink') && (
                          <>
                            <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3">Link da reunião:</dt>
                            <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap">
                              <a href={getValues('videoConferenceLink')} target="_blank" rel="noreferrer">
                                {getValues('videoConferenceLink')}
                              </a>
                            </dd>
                          </>)}
                        <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3">Duração:</dt>
                        <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap">
                          {`${getValues('duration')} minutos`}
                        </dd>
                        <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3">Proprietário:</dt>
                        <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap">
                          {creator &&
                            <Avatar
                              color="#999"
                              title={creator.name}
                              name={creator.name}
                              src={creator.logoLink}
                              style={{
                                marginRight: '0.4em'
                              }}
                              maxInitials={2}
                              size='32px'
                              round />}

                        </dd>
                        <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3 mt-2">Participantes:</dt>
                        <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap mt-2">
                          <ContainerParticipantes>
                            {participantes && (
                              participantes.map(({ logoLink, name, value, confirmed, answered }) => {
                                return (
                                  <ContainerAvatar key={value} confirmed={confirmed} answered={answered}>
                                    <Avatar
                                      color="#999"
                                      title={name}
                                      name={name}
                                      src={logoLink}
                                      style={{
                                        marginRight: '0.4em'
                                      }}
                                      maxInitials={2}
                                      size='32px'
                                      round />
                                  </ContainerAvatar>
                                )
                              })
                            )}
                          </ContainerParticipantes>
                        </dd>
                        {meetingFinalized && (
                          <>
                            <dt className="col-sm-12 col-12 col-xl-3 col-lg-3 col-md-3 mt-2">Assinaturas:</dt>
                            <dd className="col-sm-12 col-12 col-md-9 col-lg-9 col-xl-9 text-wrap mt-2">
                              <ContainerParticipantes>
                                {participantesAssinaturas && (
                                  participantesAssinaturas.map(({ logoLink, name, value, confirmed, answered }) => {
                                    return (
                                      <ContainerAvatar key={value} confirmed={confirmed} answered={answered}>
                                        <Avatar
                                          color="#999"
                                          title={name}
                                          name={name}
                                          src={logoLink}
                                          style={{
                                            marginRight: '0.4em'
                                          }}
                                          maxInitials={2}
                                          size='32px'
                                          round />
                                      </ContainerAvatar>
                                    )
                                  })
                                )}
                              </ContainerParticipantes>
                            </dd>
                          </>)}
                      </dl>
                    </ContainerReuniao>
                  )}
                  {reuniaoAtiva && (isCreator || adminTotal || !data?.id) && !meetingFinalized && (
                    <>
                      <Description
                        title="Dados da reunião"
                      />
                      <Row>
                        <input hidden {...register("id")} />
                        <Controller
                          name="title"
                          control={control}
                          defaultValue=""
                          render={({ field }) =>
                            <Input
                              xl="5"
                              lg="5"
                              label="Assunto"
                              id={field.name}
                              type="text"
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                            />}
                        />
                        <Controller
                          name="start"
                          control={control}
                          defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm", options)}
                          render={({ field }) =>
                            <Input
                              xl="5"
                              lg="5"
                              label="Data"
                              id={field.name}
                              type="datetime-local"
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                            />}
                        />
                        <Controller
                          name="duration"
                          control={control}
                          defaultValue=""
                          render={({ field }) =>
                            <Input
                              xl="2"
                              lg="2"
                              label="Duração"
                              id={field.name}
                              maxLength={3}
                              type="text"
                              name={field.name}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(durationMask(e.currentTarget.value))
                              }}
                            />}
                        />
                      </Row>
                      <Row>
                        <ColorPicker
                          label="Cor"
                          xl="5"
                          lg="5"
                          onChange={handleColorSelect}
                          options={defaultColors} />
                        <Controller
                          name="location"
                          control={control}
                          defaultValue={null}
                          render={({ field }) =>
                            <CreatableSelect
                              label="Local"
                              xl="7"
                              lg="7"
                              isClearable={false}
                              value={field.value}
                              handleCreate={handleCreateLocation}
                              handleChangeValue={field.onChange}
                              name={field.name}
                              options={locationsOptions}
                            />}
                        />
                      </Row>
                      <Row>
                        <Controller
                          name="videoConferenceLink"
                          control={control}
                          defaultValue=""
                          render={({ field }) =>
                            <Input
                              xl="12"
                              lg="12"
                              label="Link da reunião"
                              id={field.name}
                              type="text"
                              name={field.name}
                              value={field.value}
                              onChange={field.onChange}
                            />}
                        />
                      </Row>
                      <hr />
                      <Description
                        title="Participantes"
                      />
                      <Row>
                        <Controller
                          name="workgroup"
                          control={control}
                          defaultValue={null}
                          render={({ field }) =>
                            <Select
                              label='Workgroup'
                              isClearable={false}
                              xl='12'
                              lg='12'
                              value={field.value}
                              handleChangeValue={field.onChange}
                              name={field.name}
                              options={workgroupOptions?.map(({ id, name }) => {
                                return {
                                  value: id,
                                  label: name
                                }
                              })}
                            />}
                        />
                      </Row>
                      <Row>
                        <Controller
                          name="users"
                          control={control}
                          defaultValue={[]}
                          render={({ field }) =>
                            <Select
                              label='Escolher usuários'
                              xl="12"
                              lg='12'
                              multiOptions
                              isClearable
                              value={field.value}
                              handleChangeValue={field.onChange}
                              name={field.name}
                              options={usuarioOptions}
                              style={{
                                multiValue: styles => ({
                                  ...styles,
                                  background: "transparent"
                                }),
                                multiValueRemove: styles => ({
                                  ...styles,
                                  ":hover": {
                                    background: 'none',
                                    color: '#DE350B'
                                  }
                                })
                              }}
                              formatOptionLabel={(user) => (
                                <div>
                                  <Avatar
                                    color="#999"
                                    title={
                                      user.name
                                      + ' | '
                                      + user.email
                                    }
                                    key={user.id}
                                    name={user.name}
                                    src={user.logoLink}
                                    maxInitials={2}
                                    style={{
                                      marginRight: '0.4em'
                                    }}
                                    size='32px'
                                    round />
                                  <span title={user.name + ' | ' + user.company.fantasyName}>
                                    {user.name.split(' ')[0] + ' | ' + user.company.fantasyName.split(' ')[0]}
                                  </span>
                                </div>
                              )}
                            />}
                        />
                      </Row>
                    </>
                  )}
                  {reuniaoAtiva && (isCreator || adminTotal || (!data?.id && !meeting?.id)) && !meetingFinalized && (
                    <Button
                      type="submit"
                      className="btn-default form-control mt-4"
                      title='Finalizar'
                    />)}
                </form>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-4 col-xl-3">
            <Card>
              <CardBody>
                <div>
                  <h6>Opcões</h6>
                  {arquivos?.length > 0 &&
                    <Button
                      type="button"
                      name="btnArquivos"
                      onClick={handleArquivos}
                      className="btn-default mt-2 form-control"
                      title="Arquivos"
                    />}
                  <Button
                    type="button"
                    name="btnRSVP"
                    onClick={handleRSVP}
                    className="btn-default mt-2 form-control"
                    title="RSVP"
                    disabled={!resumoReuniao || !pautas.length}
                  />
                  <Button
                    type="button"
                    name="btnEditarReuniao"
                    onClick={handleEditarReuniao}
                    className="btn-default mt-2 form-control"
                    title="Editar reunião"
                    disabled={(!data?.id && !meeting?.id) || reuniaoAtiva || (meetingFinalized && (!isCreator && !adminTotal))}
                  />
                  <Button
                    type="button"
                    name="btnConvocarParticipantes"
                    onClick={handleConvocarParticipantes}
                    className="btn-default mt-2 form-control"
                    title="Convocar participantes"
                    disabled={!resumoReuniao || meetingFinalized || (!isCreator && !adminTotal) || !pautas.length}
                  />
                  <Button
                    type="button"
                    name="btnGerarAta"
                    onClick={handleIrParaAta}
                    className="btn-default mt-2 form-control"
                    title="Gerar ata"
                    disabled={!resumoReuniao || !acoes.length}
                  />
                  <Button
                    type="button"
                    name="btnDeletarReuniao"
                    onClick={handleDeletarReuniao}
                    className="btn-danger mt-2 form-control"
                    title="Deletar"
                    disabled={!resumoReuniao || (!isCreator && !adminTotal)}
                  />
                </div>
              </CardBody>
            </Card>
          </div>
          {resumoReuniao && (
            <>
              <div className="col-lg-8 col-xl-9">
                <Card>
                  <CardBody>
                    <ContainerPauta>
                      <Row>
                        <Col xl='11' lg='11' col='8' sm='9' md='9'>
                          <label>Pautas: </label>
                        </Col>
                        {(isCreator || adminTotal) && (
                          <Col xl='1' lg='1' col='4' sm='3' md='3'>
                            <FaPlus
                              color="#1b9f36"
                              className="btn form-control"
                              onClick={() => {
                                setPautaModal(true)
                                setTopicId(null)
                              }} />
                          </Col>)}
                      </Row>
                      {pautas && (
                        pautas.map(e => {
                          return (
                            <Row key={e.id}>
                              <div className='col-md-12'>
                                <Card>
                                  <CardBody className="d-flex align-items-center">
                                    {e.monitors.map(({ user }) => {
                                      return (
                                        user.logoLink
                                          ? <Avatar
                                            color="#999"
                                            title={user.name}
                                            key={user.id}
                                            name={user.name}
                                            src={user.logoLink}
                                            maxInitials={2}
                                            style={{
                                              marginRight: '0.4em'
                                            }}
                                            size='32px'
                                            round />
                                          : <Avatar
                                            color="#999"
                                            title={user.name}
                                            key={user.id}
                                            name={user.name}
                                            maxInitials={2}
                                            style={{
                                              marginRight: '0.4em'
                                            }}
                                            size='32px'
                                            round />
                                      )
                                    })}
                                    <div className="d-flex flex-column">
                                      <span
                                        className="text-wrap"
                                        onClick={() => handleEditarPauta(e)}>
                                        {e.agendaTopic}
                                      </span>
                                      <small className="text-wrap">{e.allottedTime} minutos alocado</small>
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            </Row>)
                        }))}
                    </ContainerPauta>
                  </CardBody>
                </Card>
              </div>
              <div className="col-lg-8 col-xl-9">
                <Card>
                  <CardBody>
                    <ContainerAcao>
                      <Row>
                        <Col xl='11' lg='11' col='8' sm='9' md='9'>
                          <label>Ações: </label>
                        </Col>
                        {(isCreator || adminTotal) && (
                          <Col xl='1' lg='1' col='4' sm='3' md='3'>
                            <FaPlus
                              color="#1b9f36"
                              className="btn form-control"
                              onClick={() => {
                                setAcaoModal(true)
                                setActionId(null)
                              }} />
                          </Col>)}
                      </Row>
                      {acoes && (
                        acoes.map(e => {
                          return (
                            <Row key={e.id}>
                              <div className='col-md-12'>
                                <Card>
                                  <CardBody className="d-flex align-items-center row">
                                    <Col
                                      xl={e.responsibles.length > 1 ? '2' : '1'}
                                      lg='6'
                                      md='6'
                                      sm='6'
                                      col='6'
                                      className='d-flex flex-wrap'>
                                      {e.responsibles.map(({ user }) => {
                                        return (
                                          user.logoLink
                                            ? <Avatar
                                              color="#999"
                                              key={user.id}
                                              title={user.name}
                                              name={user.name}
                                              src={user.logoLink}
                                              style={{
                                                marginRight: '0.4em',
                                                marginBottom: '0.2em'
                                              }}
                                              maxInitials={2}
                                              size='32px'
                                              round />
                                            : <Avatar
                                              color="#999"
                                              key={user.id}
                                              title={user.name}
                                              name={user.name}
                                              style={{
                                                marginRight: '0.4em',
                                                marginBottom: '0.2em'
                                              }}
                                              maxInitials={2}
                                              size='32px'
                                              round />
                                        )
                                      })}
                                    </Col>
                                    {isTabletOrMobile && (
                                      <Col
                                        xl='2'
                                        lg='6'
                                        md='6'
                                        sm='6'
                                        col='6'
                                        className='d-flex justify-content-end'>
                                        <MdMessage
                                          onClick={() => handleEditarAcao(e)}
                                          color={e.hasComment ? "#fbd327" : ''}
                                          style={{ marginRight: '0.4em' }}
                                          size="20px" />
                                        <FaPaperclip
                                          onClick={() => handleEditarAcao(e)}
                                          color={e.hasAttachment ? "#56cbb4" : ''}
                                          size="20px" />
                                      </Col>)}
                                    <Col
                                      xl={e.responsibles.length > 1 ? '8' : '9'}
                                      lg='12'
                                      className='d-flex flex-column'>
                                      <span
                                        className="text-wrap"
                                        title={e.topic.agendaTopic}
                                        onClick={() => handleEditarAcao(e)}>
                                        {e.description}
                                      </span>
                                      <small className="text-wrap">
                                        {e.extendedDeadline &&
                                          `Prazo até
                                          ${format(Date.parse(e.deadline),
                                            "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                                            options)}`
                                        }
                                        {e.deadline && !e.extendedDeadline &&
                                          `Prazo até
                                          ${format(Date.parse(e.deadline),
                                            "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                                            options)}`
                                        }
                                        {!e.deadline && !e.extendedDeadline &&
                                          'À combinar'
                                        }
                                        {` | Pauta: ${e.topic.agendaTopic}`}
                                      </small>
                                    </Col>
                                    {!isTabletOrMobile && (
                                      <Col
                                        xl='2'
                                        lg='6'
                                        md='6'
                                        sm='6'
                                        col='6'
                                        className='d-flex justify-content-end'>
                                        <MdMessage
                                          onClick={() => handleEditarAcao(e)}
                                          color={e.hasComment ? "#fbd327" : ''}
                                          style={{ marginRight: '0.4em' }}
                                          size="20px" />
                                        <FaPaperclip
                                          onClick={() => handleEditarAcao(e)}
                                          color={e.hasAttachment ? "#56cbb4" : ''}
                                          size="20px" />
                                      </Col>)}
                                  </CardBody>
                                </Card>
                              </div>
                            </Row>)
                        }))}
                    </ContainerAcao>
                    <Modal
                      size="lg"
                      show={RSVPModal}
                      onHide={() => setRSVPModal(false)}>
                      <Modal.Header closeButton>
                        <Modal.Title className="w-100">
                          Participantes
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <ContainerRSVP>
                          {participantes.map(e => {
                            return (
                              <Row key={e.value}>
                                <div className='col-md-12'>
                                  <Card
                                    style={{
                                      border: e.answered ? (e.confirmed ? '1px solid #1b9f36' : '1px solid #c82333') : '',
                                      borderRadius: '13px'
                                    }}>
                                    <CardBody
                                      className="d-flex align-items-center row">
                                      <Col xl='1' lg='1' col='2' md='1' sm='1'>
                                        <Avatar
                                          color="#999"
                                          key={e.value}
                                          title={e.name}
                                          name={e.name}
                                          src={e.logoLink}
                                          style={{
                                            marginRight: '0.4em',
                                          }}
                                          avatar
                                          maxInitials={2}
                                          size='32px'
                                          round />
                                      </Col>
                                      <Col xl='8' lg='8' col='10' md='6' sm='6'>
                                        <span
                                          className="text-wrap">
                                          {e.name}
                                        </span>
                                      </Col>
                                      <Col xl='3' lg='3' col='12' md='5' sm='5' className="d-flex justify-content-end">
                                        <small className="text-wrap">
                                          {
                                            e.answered
                                              ? (
                                                e.confirmed
                                                  ? 'Confirmado'
                                                  : 'Não confirmado'
                                              )

                                              : 'Sem resposta'
                                          }
                                        </small>
                                      </Col>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Row>)
                          })}
                        </ContainerRSVP>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      size="lg"
                      show={arquivosModal}
                      onHide={() => setArquivosModal(false)}>
                      <Modal.Header closeButton>
                        <Modal.Title className="w-100">
                          Arquivos
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {arquivos?.map((e) => {
                          return (
                            <CardFile>
                              <Row key={e.id}>
                                <Col xl='1' lg='1' sm='1' md='1' className="d-flex justify-content-center mt-4">
                                  <Avatar
                                    color="#999"
                                    title={e.user.name}
                                    key={e.user.id}
                                    name={e.user.name}
                                    maxInitials={2}
                                    src={e.user.logoLink}
                                    style={{
                                      marginRight: '0.4em'
                                    }}
                                    size='32px'
                                    round />
                                </Col>
                                <Col className="d-flex flex-column" xl='11' lg='11'>
                                  <>
                                    <span className="text-wrap">
                                      <a href={e.url} target="_blank" rel="noreferrer">
                                        {e.name}
                                      </a>
                                    </span>
                                    <small className="text-muted">
                                      {formatBytes(e.size * 1.024)}
                                    </small>
                                    <small className="text-muted text-wrap">
                                      {format(Date.parse(e.createdAt),
                                        "'Enviado em ' EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                                        { locale: ptBR })}
                                      {` por ${e.user.name}`}
                                    </small>
                                    {e.comment && (
                                      <small
                                        tabIndex={0}
                                        onKeyPress={() => { }}
                                        style={{ marginLeft: "0.2em", paddingLeft: '0' }}
                                        onClick={() => handleComentarioArquivoCollapse(e.id)}
                                        className="btn text-wrap d-flex align-items-flex-end">
                                        <span>
                                          Comentários
                                        </span>
                                        {comentarioArquivoCollapse.filter(({ id }) => e.id === id)[0]?.value && (
                                          <span className="d-flex align-items-start">
                                            <FaSortDown />
                                          </span>)
                                        }
                                        {!comentarioArquivoCollapse.filter(({ id }) => e.id === id)[0]?.value && (
                                          <span className="d-flex align-items-end">
                                            <FaSortUp />
                                          </span>)
                                        }
                                      </small>)
                                    }
                                  </>

                                  {e.comment && (
                                    <Collapse in={comentarioArquivoCollapse.filter(({ id }) => e.id === id)[0]?.value}>
                                      <Row>
                                        <Col xl="12" lg="12">
                                          <Comment key={e.id} className='row'>
                                            <Col lg='12' xl='12'>
                                              <CommentHeader className="float-left">
                                                <Avatar
                                                  color="#999"
                                                  name={e.user.name}
                                                  size='34px'
                                                  src={e.user.logoLink}
                                                  style={{
                                                    marginRight: '.4em'
                                                  }}
                                                  maxInitials={2}
                                                  title={e.user.name}
                                                  round />
                                              </CommentHeader>
                                              <Row>
                                                <Col xl='8' lg='8' md='8' sm='8' col='8'>
                                                  <strong className="text-wrap">
                                                    {e.user.name}
                                                  </strong>
                                                </Col>
                                                <Col xl='4' lg='4' md='4' sm='4' col='4'>
                                                  <small className="text-muted float-right">
                                                    {formatDistanceToNow(
                                                      new Date(e.createdAt),
                                                      {
                                                        locale: ptBR
                                                      }
                                                    )}
                                                  </small>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col xl='12' lg='12'>
                                                  <small className="text-muted text-wrap">
                                                    {format(
                                                      new Date(e.createdAt),
                                                      "dd 'de' MMMM 'de' yyyy HH:mm",
                                                      {
                                                        locale: ptBR
                                                      }
                                                    )}
                                                    &nbsp;
                                                  </small>
                                                </Col>
                                              </Row>
                                              <CommentContent className="row">
                                                <Col xl='12' lg='12'>
                                                  <Markup content={e.comment?.value} />
                                                </Col>
                                              </CommentContent>
                                            </Col>
                                          </Comment>
                                        </Col>
                                      </Row>
                                    </Collapse>)
                                  }
                                </Col>
                              </Row>
                            </CardFile>
                          )
                        })}
                      </Modal.Body>
                    </Modal>
                    <Modal
                      size="xl"
                      show={pautaModal}
                      onHide={handleCloseTopicModal}>
                      <Modal.Header closeButton>
                        <Modal.Title className="w-100">
                          Pauta
                          {topicId && (
                            <FaTrash onClick={handleDeletarPauta} size='.7em' />)}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Topic
                          meetingId={data?.id ? parseInt(data.id) : parseInt(meeting?.id)}
                          topicId={parseInt(topicId)}
                          allottedTime={allottedTime} />
                      </Modal.Body>
                    </Modal>
                    <Modal
                      size="xl"
                      show={acaoModal}
                      onHide={handleCloseActionModal}>
                      <Modal.Header closeButton>
                        <Modal.Title className="w-100">
                          Ação
                          {actionId && (
                            <FaTrash onClick={handleDeletarAcao} size='.7em' />)}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Action
                          // deleting={deletingAction}
                          meetingId={data?.id ? parseInt(data.id) : parseInt(meeting?.id)}
                          actionId={parseInt(actionId)} />
                      </Modal.Body>
                    </Modal>
                  </CardBody>
                </Card>
              </div>
            </>
          )}
        </Row>
      )}
      {!data?.cadastrar && (
        <div className="content mt-2">
          <Table
            keyField="id"
            data={meetingData}
            columns={columns}
            rowStyles={rowStyles}
            extrasColumns={[
              {
                text: '',
                className: 'btn btn-sm btn-default',
                onClick: handleChangeToForm,
                buttonText: 'Editar',
              },
            ]}
          />
        </div>
      )}
    </>
  )
}

Meeting.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      cadastrar: PropTypes.bool,
    }),
  }),
}

Meeting.defaultProps = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: '',
      cadastrar: false,
    }),
  }),
}
