import styled from "styled-components"

export const TableAta = styled.table`
  width: 100%;
  td {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(128, 128, 128);
    vertical-align: center;
    &.header {
      &.inner {
        background-color: #E1EEDA;
      }
      background-color: #C5DFB4;
    }
  }
  table {
    border-collapse: collapse;
    overflow-x: auto;
  }
`

export const ContainerAta = styled.div`
  width: 100%;
  .row {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1em;
  }
  svg{
    cursor: pointer;
    margin-left: 1em;
  }
`
