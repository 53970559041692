import { all } from 'redux-saga/effects'

import auth from './auth/sagas'

// reuniões
import meeting from './meeting/sagas'
import topic from './topic/sagas'
import action from './action/sagas'

// configurações
import user from './user/sagas'
import company from './company/sagas'
import workgroup from './workgroup/sagas'
import message from './message/sagas'

export default function* rootSaga() {
  return yield all([
    auth,
    // configurações
    user,
    company,
    workgroup,
    meeting,
    topic,
    action,
    message,
  ])
}
