import * as types from '../types'

export function createActionRequest(data) {
  return {
    type: types.CREATE_ACTION_REQUEST,
    payload: { data },
  }
}
export function createActionSuccess() {
  return {
    type: types.CREATE_ACTION_SUCCESS,
    payload: {},
  }
}

export function getActionsRequest(data) {
  return {
    type: types.GET_ACTIONS_REQUEST,
    payload: { data },
  }
}

export function getActionsSuccess(data) {
  return {
    type: types.GET_ACTIONS_SUCCESS,
    payload: { data },
  }
}

export function actionFailure() {
  return {
    type: types.ACTION_FAILURE,
  }
}
