import produce from 'immer'
import * as types from '../types'
const INITIAL_STATE = {
  data: [],
  loading: false,
  success: false
}

export default function action(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.CREATE_ACTION_REQUEST: {
        draft.loading = true
        draft.success = false
        break
      }
      case types.CREATE_ACTION_SUCCESS: {
        draft.loading = false
        draft.success = true
        break
      }
      case types.GET_ACTIONS_REQUEST: {
        draft.success = false
        draft.loading = true
        break
      }
      case types.GET_ACTIONS_SUCCESS: {
        draft.data = action.payload.data
        draft.success = false
        draft.loading = false
        break
      }
      case types.ACTION_FAILURE: {
        draft.success = false
        draft.loading = false
        break
      }
      default:
    }
  })
}
