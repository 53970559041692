import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './styled'
export default function Header({
  title,
  ativo,
  style,
  ...rest
}) {
  return (
    <Button
      {...rest}
      ativo={ativo}
      type="button"
      style={style}>
      {title}
    </Button>
  )
}

Header.propTypes = {
  ativo: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
}

Header.defaultProps = {
  ativo: true,
  className: "btn",
}
