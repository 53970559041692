import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import * as types from '../types'
import {
  getCompanySuccess,
  saveCompanySuccess,
  getCompanyFailure,
} from './actions'

function sendFile(file) {
  return api.post('upload', file,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${file._boundary}`,
      },
    }
  )
}

export function* getCompany() {
  try {
    const response = yield call(api.get, `company?include.users=true`)
    const parseResponse = response.data.map((e) => {
      e.userAdmin = e.users.filter(({ roleId }) => roleId === 2 || roleId === 1).map(({ name }) => name)[0]
      return e
    })
    yield put(getCompanySuccess(parseResponse))
  } catch (error) {
    yield put(getCompanyFailure())
    toast.error("Erro interno.")
  }
}
export function* saveCompany({ payload }) {

  try {
    let response
    const { id, file } = payload.data
    let link
    if (file.has('file'))
      link = yield call(sendFile, file)
    if (!id) {
      if (link)
        response = yield call(
          api.post,
          'company',
          { ...payload.data, logoLink: link.data.url }
        )
      else
        response = yield call(
          api.post,
          'company',
          payload.data
        )
      toast.success('Empresa cadastrada com sucesso!')
    } else {

      if (link)
        response = yield call(api.put, `company/${id}`, { ...payload.data, logoLink: link.data.url })
      else
        response = yield call(api.put, `company/${id}`, payload.data)

      toast.success('Empresa atualizada com sucesso!')
    }
    yield put(saveCompanySuccess(response.data))
  } catch (error) {
    yield put(getCompanyFailure())
    if (error.response)
      if (Array.isArray(error.response.data.message)) {
        error.response.data?.message?.forEach(e => {
          toast.error(e)
        })
      } else {
        toast.error(error.response.data?.message)
      }
    else
      toast.error("Erro interno.")
  }
}

export default all([
  takeLatest(types.GET_COMPANY_REQUEST, getCompany),
  takeLatest(types.SAVE_COMPANY_REQUEST, saveCompany),
])
