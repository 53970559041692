import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import * as types from '../types'

import {
  actionFailure,
  getActionsSuccess,
  createActionSuccess
} from './actions'

export function* createAction({ payload }) {
  try {
    const { id } = payload.data
    if (!id) {
      yield call(api.post, 'action', payload.data)
      toast.success('Ação cadastrada com sucesso!')
    } else {
      yield call(api.put, `action/${id}`, payload.data)
      toast.success('Ação atualizada com sucesso!')
    }
    yield put(createActionSuccess())
  } catch (error) {
    yield put(actionFailure())
    // if (error.response.data?.message?.length > 0) {
    //   error.response.data.message?.forEach(e => {
    //     toast.error(e)
    //   })
    // } else {
    //   toast.error(error.response.data?.message)
    // }
  }
}
export function* getAction({ payload }) {
  try {

    const { meetingId } = payload.data

    const response = yield call(api.get,
      `action?where.topic.meetingId=${meetingId}&&include.deadline.include.dateMarking=true&&include.extendedDeadline.include.dateMarking=true`
    )
    yield put(getActionsSuccess(response.data))

  } catch (e) {
    yield put(actionFailure())
    toast.error("Erro interno.")
  }
}
export default all([
  takeLatest(types.CREATE_ACTION_REQUEST, createAction),
  takeLatest(types.GET_ACTIONS_REQUEST, getAction),
])
