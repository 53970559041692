import produce from 'immer'
import * as types from '../types'
const INITIAL_STATE = {
  data: [],
  success: false,
  loading: false
}

export default function topic(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.CREATE_TOPIC_REQUEST: {
        draft.success = false
        draft.loading = true
        break
      }
      case types.CREATE_TOPIC_SUCCESS: {
        draft.success = true
        draft.loading = false
        break
      }
      case types.GET_TOPICS_REQUEST: {
        draft.success = false
        draft.loading = true
        break
      }
      case types.GET_TOPICS_SUCCESS: {
        draft.data = action.payload.data
        draft.success = false
        draft.loading = false
        break
      }
      case types.TOPIC_FAILURE: {
        draft.success = false
        draft.loading = false
        break
      }
      default:
    }
  })
}
