import React from 'react'
import PropTypes from 'prop-types'
import { CirclePicker } from 'react-color'
export default function ColorPicker({
  // reponsividade breakpoints (Extra small <576px)
  col,
  // reponsividade breakpoints (Small >=576px)
  sm,
  // reponsividade breakpoints (Medium >=768px)
  md,
  // reponsividade breakpoints (Large >=992px)
  lg,
  // reponsividade breakpoints (Extra Large >=1200px)
  xl,
  label,
  options,
  onChange
}) {
  return (
    <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
      {label && <label>{label}</label>}
      <CirclePicker colors={options} width="auto" circleSize={30} onChange={onChange} />
    </div>
  )
}

ColorPicker.propTypes = {
  col: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

ColorPicker.defaultProps = {
  col: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
}
