import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 50px;
`

export const TopSide = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 20px;
  img {
    width: 230px;
    @media screen and (max-width: 768px) {
      width: 180px;
    }
    display: inline-block;
  }
`

export const BottomSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    width: 100%;
    text-align: left;
    font-size: 35px;
    color: #1d1d1d;
  }
`

export const LoginCard = styled.div`
  @media screen and (min-width: 768px) {
    width: 570px;
    padding: 90px;
  }
  width: 100%;
  padding: 20px 20px;
  background-color: rgb(255, 255, 255);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-width: 0 !important;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  display: inline-block;
`
