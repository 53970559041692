import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, Controller } from "react-hook-form"

import { toast } from 'react-toastify'

// components
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Button from '~/components/Form/Button'
import Loading from '~/components/Loading'
import Select from '~/components/Form/Select'

// icons
// import { FaUsers } from 'react-icons/fa'


// services
import api from '~/services/api'
import { durationMask } from '~/utils/validation'
import { createTopicRequest } from '~/store/modules/topic/actions'
import Avatar from 'react-avatar'


export default function Topic({ meetingId, topicId, allottedTime }) {
  const dispatch = useDispatch()
  const { profile } = useSelector(state => state.auth)
  const { loading: loadingTopic } = useSelector(state => state.topic)


  const { control, handleSubmit, setValue, register, watch, reset } = useForm()

  const [prioridadeOptions, setPrioridadeOptions] = useState([])
  const [usuarioOptions, setUsuarioOptions] = useState([])

  const [loading, setLoading] = useState(false)
  const [loadingPriority, setLoadingPriority] = useState(false)
  const [loadingMeeting, setLoadingMeeting] = useState(false)


  const [creator, setCreator] = useState(null)

  const [oldTopicTime, setOldTopicTime] = useState(0)

  const adminTotal = profile.roleId === 1

  const topicTime = watch('topicAllottedTime')

  const onSubmit = data => {
    if (!data.topicTitle) {
      toast.error('Adicione um título à pauta.')
    } else if (!data.topicAllottedTime) {
      toast.error('Digite um tempo para alocação da pauta.')
    } else if (!topicAllottedTime()) {
      toast.error(`Tempo indisponível, só restam ${allottedTime + oldTopicTime} minutos`)
    } else if (!data.topicResponsible) {
      toast.error('Adicione um responsável pelo acompanhamento.')
    } else if (!data.topicPriority?.value) {
      toast.error('Adicione uma prioridade.')
    } else {
      dispatch(
        createTopicRequest({
          id: data.id,
          allottedTime: parseInt(data.topicAllottedTime),
          agendaTopic: data.topicTitle ? data.topicTitle : null,
          meetingId: meetingId,
          monitorUserIds: data.topicResponsible.map(e => e.value),
          priorityId: data.topicPriority.value,
          conclusion: data.topicConclusion ? data.topicConclusion : null,
          creatorUserId: profile.id,
        })
      )
      if (!topicId) reset({
        topicResponsible: null,
        topicPriority: null
      })
    }
  }

  const topicAllottedTime = useCallback(() => {
    if ((parseInt(allottedTime) + oldTopicTime) - parseInt(topicTime) < 0) return false
    return true
  }, [allottedTime, topicTime, oldTopicTime])

  useEffect(() => {
    function loadTopic() {
      setLoading(true)
      api
        .get(`topic/${topicId}?include.monitors.include.user.include.company=true&&include.priority=true&&include.meeting.include.convener=true`)
        .then(({ data: loadTopic }) => {
          setValue('id', loadTopic.id)
          setCreator(loadTopic.meeting.convener.userId)
          setValue('topicTitle', loadTopic.agendaTopic)
          setValue('topicAllottedTime', loadTopic.allottedTime)
          setValue('topicPriority', { value: loadTopic.priority.id, label: loadTopic.priority.label })
          setValue('topicConclusion', loadTopic.conclusion ? loadTopic.conclusion : '')
          setValue('topicResponsible', loadTopic.monitors
            .map(({ user }) => user)
            .map(({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                email,
                name,
                logoLink,
                company
              }
            }))
          setOldTopicTime(loadTopic.allottedTime)
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
    }
    if (topicId) loadTopic()
  }, [setValue, topicId])

  useEffect(() => {
    function getPriority() {
      setLoadingPriority(true)
      api.get('priority').then((e) => {
        const parseResp = e.data.map(({ id, label }) => {
          return {
            value: id,
            label
          }
        })
        setPrioridadeOptions(parseResp)
        setLoadingPriority(false)
      }).catch((error) => {
        setLoadingPriority(false)
      })
    }
    function getMeeting() {
      setLoadingMeeting(true)
      api
        .get(`meeting/${meetingId}?include.participants.include.user.include.company=true`)
        .then(({ data: loadMeeting }) => {
          const parseParticipantes =
            loadMeeting.participants
              .map(({ user }) => user)
              .map(({ id, name, email, logoLink, company }) => {
                return {
                  value: id,
                  label: name + ' | ' + email,
                  email,
                  name,
                  logoLink,
                  company
                }
              })
          setUsuarioOptions(parseParticipantes)
          setLoadingMeeting(false)
        }).catch(() => {
          setLoadingMeeting(false)
        })
    }
    getPriority()
    getMeeting()

  }, [meetingId])


  return (
    <>
      {(loading || loadingTopic || loadingPriority || loadingMeeting) && <Loading card={true} />}
      {!(loading || loadingTopic || loadingPriority || loadingMeeting) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <input hidden {...register("id")} />
          <Row>
            <Controller
              name="topicTitle"
              control={control}
              defaultValue=""
              render={({ field }) =>
                <Input
                  label="Título da pauta"
                  xl="9"
                  id={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  name={field.name}
                  disabled={!!topicId && (creator !== profile.id) && !adminTotal}
                  type="text"
                />}
            />
            <Controller
              name="topicAllottedTime"
              control={control}
              defaultValue=""
              render={({ field }) =>
                <Input
                  xl="3"
                  label="Tempo alocado (minutos)"
                  id={field.name}
                  type="text"
                  maxLength={3}
                  name={field.name}
                  value={field.value}
                  disabled={!!topicId && (creator !== profile.id) && !adminTotal}
                  onChange={(e) => {
                    field.onChange(durationMask(e.currentTarget.value))
                  }}
                />}
            />
          </Row>
          <Row>
            <Controller
              name="topicResponsible"
              control={control}
              defaultValue={null}
              render={({ field }) =>
                <Select
                  label='Responsáveis pelo acompanhamento'
                  isClearable={false}
                  multiOptions
                  value={field.value}
                  handleChangeValue={field.onChange}
                  name={field.name}
                  disabled={!!topicId && (creator !== profile.id) && !adminTotal}
                  options={usuarioOptions}
                  style={{
                    multiValue: styles => ({
                      ...styles,
                      background: "transparent"
                    }),
                    multiValueRemove: styles => ({
                      ...styles,
                      ":hover": {
                        background: 'none',
                        color: '#DE350B'
                      }
                    })
                  }}
                  formatOptionLabel={(user) => (
                    <div>
                      <Avatar
                        color="#999"
                        title={
                          user.name
                          + ' | '
                          + user.email
                        }
                        key={user.id}
                        name={user.name}
                        src={user.logoLink}
                        maxInitials={2}
                        style={{
                          marginRight: '0.4em'
                        }}
                        size='32px'
                        round />
                      <span title={user.email + ' | ' + user.company.fantasyName}>
                        {user.name.split(' ')[0]}
                      </span>
                    </div>
                  )}
                />}
            />
            <Controller
              name="topicPriority"
              control={control}
              defaultValue={null}
              render={({ field }) =>
                <Select
                  label='Prioridade'
                  isClearable={false}
                  value={field.value}
                  handleChangeValue={field.onChange}
                  name={field.name}
                  disabled={!!topicId && (creator !== profile.id) && !adminTotal}
                  options={prioridadeOptions}
                />}
            />
          </Row>
          {!!topicId && (
            <Row>
              <Controller
                name="topicConclusion"
                control={control}
                defaultValue=""
                render={({ field }) =>
                  <Input
                    label="Conclusões"
                    xl='12'
                    lg='12'
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    disabled={!!topicId && (creator !== profile.id) && !adminTotal}
                    type="text"
                  />}
              />
            </Row>)}
          <Button
            type="submit"
            className="btn-default form-control mt-4"
            title={!topicId ? 'Cadastrar pauta' : 'Atualizar pauta'}
          />
        </form>)}
    </>
  )
}

Topic.propTypes = {
  meetingId: PropTypes.number,
  topicId: PropTypes.number,
  allotted: PropTypes.number,
}

