import { takeLatest, call, put, all } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import * as types from '../types'
import api from '~/services/api'

import history from '~/services/history'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import { signInSuccess, signFailure, companySelected } from './actions'

export function* update({ payload }) {
  try {
    const { email } = payload
    const auth = getAuth()
    const { data: user } = yield call(api.get,
      `user/get-by-email/${email}?include.role=true&&include.company=true&&include.permissions=true`)
    // yield call(auth.onAuthStateChanged, user => {
    //   user && user.getIdToken().then((e) => {
    //     console.log('saedfdfgffdggf')
    //     token = `Bearer ${e}`
    //   }).catch((err) => {
    //     signFailure()
    //   })
    // })
    // console.log(user)
    api.axiosApi.defaults.headers.Authorization = `Bearer ${auth.currentUser.accessToken}`
    yield put(signInSuccess(auth.currentUser.accessToken, user))
    history.go(0)
  } catch (err) {
    toast.error('Erro, verifique seus dados.')
    yield put(signFailure())
  }
}

export function* signIn({ payload }) {
  try {
    const auth = getAuth()
    const { email, password } = payload

    yield call(signInWithEmailAndPassword, auth, email, password)
    const { data: user } = yield call(api.get,
      `user/get-by-email/${email}?include.role=true&&include.company=true&&include.permissions=true`)
    api.axiosApi.defaults.headers.Authorization = `Bearer ${auth.currentUser.accessToken}`
    // yield call(api.put, `user/${user.id}`, { loggedFirstTime: true })
    yield put(signInSuccess(auth.currentUser.accessToken, user))
    history.push('/home')
  } catch (err) {
    if (err.message === 'Firebase: Error (auth/user-disabled).')
      toast.error('Conta inativada, entre em contato com o suporte.', {
        autoClose: 10000,
      })
    else toast.error('Falha na autenticação, verifique seus dados.')
    yield put(signFailure())
  }
}

export function signOut() {
  const auth = getAuth()
  auth.signOut()
  history.push('/')
}

export function* selectCompany({ payload }) {
  const { userId } = payload
  const auth = getAuth()
  const { data: user } = yield call(
    api.get,
    `user?where.id=${userId.userId}&&include.role=true&&include.company=true`
  )
  api.axiosApi.defaults.headers.Authorization = `Bearer ${auth.currentUser.accessToken}`
  yield put(signInSuccess(auth.currentUser.accessToken, user[0]))
  yield put(companySelected())
  history.push('/home')
}



export default all([
  takeLatest(types.SIGN_IN_REQUEST, signIn),
  takeLatest(types.COMPANY_SELECT, selectCompany),
  takeLatest(types.UPDATE_REQUEST, update),
  takeLatest(types.SIGN_OUT, signOut),
])
