import React from 'react'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import PropTypes from 'prop-types'

export default function SwitchButton({
  checked,
  onChange,
  name,
  label,
  onlabel = 'Sim',
  offlabel = 'Não',
  onstyle = 'success',
  offstyle = 'danger',
  // reponsividade breakpoints (Extra small <576px)
  col,
  // reponsividade breakpoints (Small >=576px)
  sm,
  // reponsividade breakpoints (Medium >=768px)
  md,
  // reponsividade breakpoints (Large >=992px)
  lg,
  // reponsividade breakpoints (Extra Large >=1200px)
  xl,
  id,
  size,
  disabled,
}) {
  return (
    <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
      <div className="form-group">
        {label && <label htmlFor={id}>{label}</label>}
        <BootstrapSwitchButton
          checked={checked}
          onlabel={onlabel}
          onstyle={onstyle}
          offlabel={offlabel}
          offstyle={offstyle}
          // eslint-disable-next-line
          style="w-100"
          size={size}
          onChange={val => {
            onChange(name, val)
          }}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

SwitchButton.propTypes = {
  col: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

SwitchButton.defaultProps = {
  col: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
  disabled: false,
  size: 'sm'
}
