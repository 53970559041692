// mask


export const timeMask = value => {
  return value
    .replace(/\D/g, '')
    .replace(/[^\dh:]/, "")
    .replace(/^[^0-2]/, "")
    .replace(/^([2-9])[4-9]/, "$1")
    .replace(/^\d[:h]/, "")
    .replace(/(\d{2})(\d)/, '$1:$2')
    .replace(/^(2[0-3])[^:h]/, "$1")
}

export const durationMask = value => {
  return value
    .replace(/\D/g, '')
}

// validation

export const timeValidation = value => {
  if (value.match(/\b(?:[01][0-9]|2[0-3]):[0-5][0-9]\b/gm)) return true
  else return false
}


export const cpfValidate = cpf => {
  if (!cpf) return false
  cpf = cpf.replace(/[^\d]+/g, '')
  let Soma
  let Resto
  Soma = 0
  if (cpf === "00000000000") return false

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(cpf.substring(9, 10))) return false

  Soma = 0
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  Resto = (Soma * 10) % 11

  if ((Resto === 10) || (Resto === 11)) Resto = 0
  if (Resto !== parseInt(cpf.substring(10, 11))) return false
  return true
}

export const cnpjValidate = cnpj => {
  if (!cnpj) return false
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj === '') return false

  if (cnpj.length !== 14)
    return false

  if (cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999")
    return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
      pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(0)))
    return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
      pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(1)))
    return false

  return true
}
