import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import * as types from '../types'

import {
  workgroupFailure,
  createWorkgroupSuccess,
  getWorkgroupsSuccess
} from './actions'

export function* createWorkgroup({ payload }) {
  try {
    const { id } = payload.data
    if (!id) {
      yield call(api.post, 'work-group', payload.data)
      toast.success('Workgroup cadastrado com sucesso!')
    } else {
      yield call(api.put, `work-group/${id}`, payload.data)
      toast.success('Workgroup atualizado com sucesso!')
    }
    yield put(createWorkgroupSuccess())
  } catch (error) {
    yield put(workgroupFailure())
    // if (error.response.data?.message?.length > 0) {
    //   error.response.data.message.forEach(e => {
    //     toast.error(e)
    //   })
    // } else {
    toast.error("Erro interno.")
    // }
  }
}
export function* getWorkgroups({ payload }) {
  try {
    const {
      id,
      adminLocal,
      adminTotal,
      companyId
    } = payload.data
    let query

    if (adminLocal) {
      query = `work-group?where.OR[0].users.some.id=${id}&&where.OR[1].companyId=${companyId}`
    } else if (adminTotal) {
      query = 'work-group'
    } else {
      query = `work-group?where.users.some.id=${id}`
    }

    const response = yield call(api.get, query)
    yield put(getWorkgroupsSuccess(response.data))
  } catch (e) {
    yield put(workgroupFailure())
    toast.error("Erro interno.")
  }
}
export default all([
  takeLatest(types.CREATE_WORKGROUP_REQUEST, createWorkgroup),
  takeLatest(types.GET_WORKGROUPS_REQUEST, getWorkgroups),
])
