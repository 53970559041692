import styled from "styled-components"
import { Card } from "~/components/Card"

export const ContainerComment = styled.div`
  /* display: flex; */
  width: 100%;
`

export const CardComment = styled(Card)`
  display: flex;
  padding: 10px;
  min-height: 100px;
`


export const CardFile = styled(Card)`
  display: flex;
  padding: 10px;
  min-height: 100px;

  white-space: nowrap;
  display: flex;
  flex-direction: column;
  svg {
    cursor: pointer;
  }
  span a, small {
    margin-left: .4em;
  }
  a {
    color: rgba(0,0,0, .75);
  }
`

export const CommentHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: .5em;
  img {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-top: .4em;
    margin-right: .4em;
  }
`

export const Comment = styled.div`
    small {
      font-size: 10px;
    }
    .row{
      /* align-items: flex-end; */
      strong {
        font-weight: 700;
        font-size: 12px;
      }
      .col {
        display: flex;
        align-items: flex-end;
      }
    }
`
export const CommentContent = styled.div`
  margin-left: calc(34px + .4em);
  margin-top: .8em;
  p {
    font-size: 90%;
  }
`

export const FormFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  label {
    svg{
      margin-left: .4em;
      color: #1b9f36;
    }
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`
