import React from 'react'
import PropTypes from 'prop-types'
import makeAnimated from 'react-select/animated'
import RSelect from 'react-select'
export default function Select({
  defaultValue = {},
  name,
  options,
  // reponsividade breakpoints (Extra small <576px)
  col,
  // reponsividade breakpoints (Small >=576px)
  sm,
  // reponsividade breakpoints (Medium >=768px)
  md,
  // reponsividade breakpoints (Large >=992px)
  lg,
  // reponsividade breakpoints (Extra Large >=1200px)
  xl,
  label,
  value,
  handleChangeValue,
  multiOptions,
  disabled,
  style,
  isClearable,
  formatOptionLabel,
  formatGroupLabel,
}) {
  const animatedComponents = makeAnimated()


  if (multiOptions) {
    return (
      <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
        <div className="form-group">
          {label && <label htmlFor={name}>{label}</label>}
          <RSelect
            isClearable={isClearable}
            defaultValue={defaultValue}
            isMulti
            components={animatedComponents}
            value={value}
            onChange={handleChangeValue}
            name={name}
            id={name}
            options={options}
            styles={style}
            className="basic-multi-select"
            classNamePrefix="select"
            loadingMessage={() => 'Carregando ...'}
            noOptionsMessage={() => 'Opção não encontrada'}
            placeholder="Selecione"
            isDisabled={disabled}
            formatOptionLabel={formatOptionLabel}
            formatGroupLabel={formatGroupLabel}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
      <div className="form-group">
        {label && <label htmlFor={name} style={style}>{label}</label>}
        <RSelect
          isClearable={isClearable}
          defaultValue={defaultValue}
          components={animatedComponents}
          value={value}
          onChange={handleChangeValue}
          name={name}
          options={options}
          isDisabled={disabled}
          className="basic-multi-select"
          classNamePrefix="select"
          theme="primary75"
          styles={style}
          loadingMessage={() => 'Carregando ...'}
          noOptionsMessage={() => 'Opção não encontrada'}
          placeholder="Selecione"
          formatOptionLabel={formatOptionLabel}
          formatGroupLabel={formatGroupLabel}
        />
      </div>
    </div>
  )
}

Select.propTypes = {
  /** um nome identificador para o componente */
  name: PropTypes.string.isRequired,
  /** Opções de seleção para o componente */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  col: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
  /** Nome da label */
  label: PropTypes.string,
  /** valor atual do select */
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.any]),
  /** Função responsável por alterar o estado do select */
  handleChangeValue: PropTypes.func.isRequired,
  /** Habilita a função de receber multiplas opções */
  multiOptions: PropTypes.bool,
  defaultValue: PropTypes.object,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  formatGroupLabel: PropTypes.func,
}

Select.defaultProps = {
  col: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
  label: '',
  multiOptions: false,
  disabled: false,
  isClearable: false,
  style: {
    control: styles => ({
      ...styles,
      minHeight: '34px',
      height: "calc(2.25rem + 2px)"
    }),
    clearIndicator: styles => ({
      ...styles,
      padding: '2px 8px',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      padding: '2px 8px',
    }),
    loadingIndicator: styles => ({
      ...styles,
      padding: '2px 8px',
    }),
    menu: styles => ({
      ...styles,
      zIndex: 3, // Without this menu will be overlapped by other fields
    })
  }
}
