import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useForm, Controller } from "react-hook-form"
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Loading from '~/components/Loading'
import { TopSide, Container, BottomSide, LoginCard } from '../SignIn/styles'
import logoIMG from '../../assets/logo.png'
import Button from '~/components/Form/Button'
import { getAuth, sendPasswordResetEmail } from '@firebase/auth'
import history from '~/services/history'

export default function ResetPassword() {
  const { control, handleSubmit } = useForm()
  const [loading, setLoading] = useState(false)
  const auth = getAuth()


  const onSubmit = data => {
    if (!data.email) {
      toast.error('O email é obrigatório.')
    } else {
      setLoading(true)
      sendPasswordResetEmail(auth, data.email)
        .then(() => {
          toast.success("Email para redefinição de senha enviado com sucesso. Verifique em 'spam' caso não encontre na caixa de entrada.",
            { autoClose: 10000 })
          setLoading(false)
          setTimeout(() => {
            history.push('/')
          }, 4000)
        })
        .catch(() => {
          toast.error("Falha ao enviar email.")
          setLoading(false)
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Container>
        <TopSide>
          <img src={logoIMG} alt="logoIMG" />
        </TopSide>
        <BottomSide>
          <LoginCard>
            <p>Trocar senha</p>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      label="Digite o seu e-mail"
                      id={field.name}
                      xl='12'
                      lg='12'
                      type="email"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Row>
              <Button
                type="submit"
                className="btn-default form-control mt-4"
                title="Entrar"
              />
            </form>
          </LoginCard>
        </BottomSide>
      </Container>
    </>
  )
}
