import React from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../components/Form/Button'
import { signOut } from '../../store/modules/auth/actions'
import { Container, BottomSide, LoginCard } from '../SignIn/styles'

export default function InactiveUser() {
  const dispatch = useDispatch()
  return (
    <Container style={{ height: '100vh' }}>
      <BottomSide>
        <LoginCard>
          <p
            style={{
              textAlign: 'center',
              fontSize: '32px',
              fontWeight: 'bold',
            }}
          >
            Usuário inativado
          </p>
          <p
            style={{
              fontSize: '26px',
            }}
          >
            Entre em contato com o suporte para obter mais informações.
          </p>
          <Button
            className="btn-outline-success mt-4 form-control"
            type="button"
            onClick={() => {
              dispatch(signOut())
            }}
            title="Voltar"
          />
        </LoginCard>
      </BottomSide>
    </Container>
  )
}
