import styled from "styled-components"
import { Card } from "~/components/Card"

export const ContainerParticipantes = styled.div`
  display: flex;
`


export const CardFile = styled(Card)`
  display: flex;
  padding: 10px;
  justify-content: center;
  min-height: 100px;
  flex-wrap: nowrap;
  white-space: nowrap;
  flex-direction: column;
  svg {
    cursor: pointer;
  }
  span a, small {
    margin-left: .4em;
  }
  a {
    color: rgba(0,0,0, .75);
  }
`
export const ContainerAcao = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  svg {
    cursor: pointer;
  }
  .row:nth-child(1){
    align-items: flex-end;
    margin-bottom: .5em;
  }
  div {
    .d-flex.flex-column{
      span {
        color: rgba(0,0,0, .75);
        cursor: pointer;
      }
      small {
        color: #aaa;
      }
    }
  }
  button {
    padding: 10px 10px;
  }
`


export const ContainerPauta = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  svg {
    cursor: pointer;
  }
  .row:nth-child(1){
    align-items: flex-end;
    margin-bottom: .5em;
  }
  div {
    .d-flex.flex-column{
      span {
        margin-left: 1em;
        color: rgba(0,0,0, .75);
        cursor: pointer;
      }
      small {
        color: #aaa;
        margin-left: 1.2em;
      }
    }
  }
  button {
    padding: 10px 10px;
  }
`

export const ContainerAvatar = styled.div`
  img{
    border:
  ${props => {
    if (props.confirmed)
      return '2px solid #1b9f36'
    else if (!props.answered)
      return ''
    else if (!props.confirmed)
      return '2px solid #c82333'
  }};
    border-radius: 50%;
  }
  div>span {
    border:
  ${props => {
    if (props.confirmed)
      return '2px solid #1b9f36'
    else if (!props.answered)
      return ''
    else if (!props.confirmed)
      return '2px solid #c82333'
  }};
    border-radius: 50%
  }
`
export const ContainerRSVP = styled.div`

  div.col-12 {
    padding-right: 40px;
  }
  svg {
    cursor: pointer;
    margin-right: 15px;
  }
  span.text-wrap {
    margin-left: .4em;
  }
`

export const ContainerReuniao = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  small {
    color: #aaa;
    font-weight: 500;
  }
  dl{
    margin-top: 20px;
    dt {
      @media screen and (min-width: 768px) {
        display: flex;
        &:nth-last-child(-n+4){
          align-items: center;
        }
        align-items: center;
        justify-content: flex-end;
      }
      float: left;
      width: 160px;
      clear: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 3px;
      font-weight: 600;
      font-size: 15px;
      padding-top: 1px;
    }
    dd {
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: flex-start;
      }
      color: #aaa;
      a {
        color: #55a7fa
      }
    }
  }
`
