import React from 'react'
import PropTypes from 'prop-types'

import makeAnimated from 'react-select/animated'
import CSelect from 'react-select/creatable'
export default function CreatableSelect({
  defaultValue,
  name,
  options,
  // reponsividade breakpoints (Extra small <576px)
  col,
  // reponsividade breakpoints (Small >=576px)
  sm,
  // reponsividade breakpoints (Medium >=768px)
  md,
  // reponsividade breakpoints (Large >=992px)
  lg,
  // reponsividade breakpoints (Extra Large >=1200px)
  xl,
  label,
  value,
  handleChangeValue,
  handleCreate,
  disabled,
  style,
  selectColor,
  isClearable
}) {
  const animatedComponents = makeAnimated()

  return (
    <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
      <div className="form-group">
        {label && <label htmlFor={name} style={style}>{label}</label>}
        <CSelect
          onCreateOption={handleCreate}
          isClearable={isClearable}
          defaultValue={defaultValue}
          components={animatedComponents}
          value={value}
          onChange={handleChangeValue}
          name={name}
          options={options}
          maxMenuHeight={200}
          className="basic-multi-select"
          classNamePrefix="select"
          theme="primary75"
          isDisabled={disabled}
          styles={{
            control: styles => ({
              ...styles,
              border: `${selectColor ? selectColor + ' 2px solid' : ''}`,
              minHeight: '34px',
              height: "calc(2.25rem + 2px)"
            }),
            clearIndicator: styles => ({
              ...styles,
              padding: '2px 8px',
            }),
            dropdownIndicator: styles => ({
              ...styles,
              padding: '2px 8px',
            }),
            loadingIndicator: styles => ({
              ...styles,
              padding: '2px 8px'
            }),
            menu: styles => ({
              ...styles,
              zIndex: 3, // Without this menu will be overlapped by other fields
            }),
          }}
          loadingMessage={() => 'Carregando ...'}
          noOptionsMessage={() => 'Opção não encontrada'}
          placeholder="Selecione"
          formatCreateLabel={inputValue => `Cadastrar "${inputValue}"`}
        />
      </div>
    </div>
  )
}

CreatableSelect.propTypes = {
  /** um nome identificador para o componente */
  name: PropTypes.string.isRequired,
  /** Opções de seleção para o componente */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** O espaço que ele irá ocupar dentro de uma <Row> - entre 1 e 12 */
  col: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
  /** Nome da label */
  label: PropTypes.string,
  /** valor atual do select */
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Função responsável por alterar o estado do select */
  handleChangeValue: PropTypes.func.isRequired,
  /** Habilita a função de receber multiplas opções */
  defaultValue: PropTypes.object,
  handleCreate: PropTypes.func,
  style: PropTypes.object,
  selectColor: PropTypes.string,
  isClearable: PropTypes.bool,
}

CreatableSelect.defaultProps = {
  label: '',
  col: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
}
