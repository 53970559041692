import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loading from '~/components/Loading'
import api from '~/services/api'
import history from '~/services/history'

export default function MeetingMinuteSignatureRedirect({ match }) {
  const tp = match.params.id
  const id = parseInt(tp, 10) / 84576
  const { profile } = useSelector(state => state.auth)


  api.get(`meeting/${id}`)
    .then(({ data }) => {
      // console.log(summary)
      api.put(`meeting/${id}`, {
        summary: data.summary +
          `
            <p>___________________________________________</p>
            <p>${profile.name} | ${profile.email}</p>
            <p>&nbsp;</p>
          `
      }).then(() => {
        history.push('/reuniao/ata', {
          id: parseInt(id, 10)
        })
        toast.success('Assinatura adicionada com sucesso.')
      }).catch(() => {
        toast.error('Erro ao adicionar assinatura.')
        history.push('/reuniao/ata', {
          id: parseInt(id, 10)
        })
      })
    }).catch(() => {
      toast.error('Erro ao adicionar assinatura.')
      history.push('/home')
    })
  return <Loading />
}


MeetingMinuteSignatureRedirect.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}
