import * as types from '../types'

export function createWorkgroupRequest(data) {
  return {
    type: types.CREATE_WORKGROUP_REQUEST,
    payload: { data },
  }
}
export function createWorkgroupSuccess() {
  return {
    type: types.CREATE_WORKGROUP_SUCCESS,
    payload: {},
  }
}

export function getWorkgroupsRequest(data) {
  return {
    type: types.GET_WORKGROUPS_REQUEST,
    payload: { data },
  }
}

export function getWorkgroupsSuccess(data) {
  return {
    type: types.GET_WORKGROUPS_SUCCESS,
    payload: { data },
  }
}

export function workgroupFailure() {
  return {
    type: types.WORKGROUP_FAILURE,
  }
}
