import React from 'react'
import PropTypes from 'prop-types'
export default function InputIcon({
  // reponsividade breakpoints (Extra small <576px)
  col,
  // reponsividade breakpoints (Small >=576px)
  sm,
  // reponsividade breakpoints (Medium >=768px)
  md,
  // reponsividade breakpoints (Large >=992px)
  lg,
  // reponsividade breakpoints (Extra Large >=1200px)
  xl,
  className,
  value,
  name,
  label,
  onChange,
  id,
  type,
  placeholder,
  handleOnBlur,
  disabled,
  handleKeyUp,
  ref,
  icon,
  onClickIcon,
  styleIcon,
}) {
  return (
    <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
      <div className={'form-group'}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className="input-group">
          <span style={styleIcon} className="input-group-text" onClick={onClickIcon}>
            {icon}
          </span>
          <input
            type={type}
            className={`form-control ${className}`}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            onBlur={handleOnBlur}
            disabled={disabled}
            ref={ref}
            onKeyUp={handleKeyUp}
          />
        </div>
      </div>
    </div>
  )
}

InputIcon.propTypes = {
  col: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleOnBlur: PropTypes.func,
  handleKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  styleIcon: PropTypes.object,
  ref: PropTypes.string,
  icon: PropTypes.element
}

InputIcon.defaultProps = {
  col: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
  disabled: false,
  placeholder: '',
  className: '',
  handleOnBlur: () => { },
}
