import styled from "styled-components"


export const FormFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  label {
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      margin-right: 6px;
    }
  }
`
