/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {
  ColumnCard,
  ColumnCardBody,
  ColumnContent,
  ColumnHeader,
  ColumnHeaderTitle,
  ColumnWrapperContent,
  Container,
  ContainerCard,
  ContainerCardBody,
  ContainerCardHeader,
  ContainerColumn,
  Content,
  ContentCard
} from "./styles"
import PropTypes from 'prop-types'
import api from "~/services/api"
import Loading from "~/components/Loading"
import { toast } from "react-toastify"
import { addHours, format, formatISO } from "date-fns"
import { ptBR } from "date-fns/locale"
import { Modal } from "react-bootstrap"
import { FaFilter, FaCog, FaBell, FaBellSlash } from "react-icons/fa"
import Action from '~/pages/Meeting/Action'
import Row from "~/components/Bootstrap/Row"
import SwitchButton from "~/components/Form/SwitchButton"
import { Controller, useForm } from "react-hook-form"
import Button from "~/components/Form/Button"
import Select from "~/components/Form/Select"
import Avatar from "react-avatar"
import Input from "~/components/Form/Input"

import history from "~/services/history"
import Col from "~/components/Bootstrap/Col"

export default function ActionPanel({ location }) {
  const { profile } = useSelector(state => state.auth)

  const { control, handleSubmit } = useForm()

  const [loadingAction, setLoadingAction] = useState(false)
  const [loadingResponsibles, setLoadingResponsibles] = useState(false)
  const [loadingMonitors, setLoadingMonitors] = useState(false)
  const data = location.state

  const [acaoAtiva, setAcaoAtiva] = useState(false)
  const [filtroAtivo, setFiltroAtivo] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [actionId, setActionId] = useState(null)

  const [afazerItems, setAFazer] = useState([])
  const [pausadoItems, setPausado] = useState([])
  const [fazendoItems, setFazendo] = useState([])
  const [vencidoItems, setVencido] = useState([])
  const [realizadoItems, setRealizado] = useState([])
  const [revisarItems, setRevisar] = useState([])

  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  const [responsiblesOptions, setResponsiblesOptions] = useState([])
  const [monitorsOptions, setMonitorsOptions] = useState([])

  const [aditionalQuery, setAditionalQuery] = useState('')

  const [queryMonitor, setQueryMonitor] = useState(null)

  // shows

  const [showResponsible, setShowResponsible] = useState(true)
  const [showMonitor, setShowMonitor] = useState(false)
  const [showTopic, setShowTopic] = useState(false)
  const [showMeeting, setShowMeeting] = useState(false)
  const [showPriority, setShowPriority] = useState(false)
  const [showDeadline, setShowDeadline] = useState(false)
  const [showCreatedAt, setShowCreatedAt] = useState(false)


  const onSubmitShow = (data) => {
    setShowResponsible(data.responsible)
    setShowMonitor(data.monitor)
    setShowTopic(data.topic)
    setShowMeeting(data.meeting)
    setShowPriority(data.priority)
    setShowDeadline(data.deadline)
    setShowCreatedAt(data.createdAt)
    setShowOptions(false)
  }

  // filters

  const onSubmitFilter = (data) => {
    const dataReponsibles = data.actionResponsible ? data.actionResponsible.map(({ value }) => value) : []
    const dataMonitors = data.monitors ? data.monitors.map(({ value }) => value) : []
    const dateStart = data.date_start
      ? formatISO(addHours(
        new Date(data.date_start).setUTCHours(0, 0, 0, 0),
        3
      ))
      : null
    const dateEnd = data.date_end
      ? formatISO(addHours(
        new Date(data.date_end).setUTCHours(23, 59, 59, 999),
        3
      ))
      : null
    let query = ''
    let i = 2
    dataReponsibles.forEach(e => {
      query += `&&where.OR[${i++}].responsibles.some.userId=${e}`
    })
    dataMonitors.forEach(e => {
      query += `&&where.OR[${i++}].topic.monitors.some.userId=${e}`
    })
    if (dateStart)
      query += `&&where.deadline.dateMarking.date.gte=${dateStart}`
    if (dateEnd)
      query += `&&where.deadline.dateMarking.date.lte=${dateEnd}`
    if (data.IAmResponsible)
      setQueryMonitor(`&&where.topic.monitors.some.userId=${profile.id}`)
    setAditionalQuery(query)
    setFiltroAtivo(false)
  }

  const columnsFromBackend = useCallback(() => ({
    ['1']: {
      name: "A fazer",
      items: afazerItems
    },
    ['2']: {
      name: "Pausado",
      items: pausadoItems
    },
    ['3']: {
      name: "Fazendo",
      items: fazendoItems
    },
    ['4']: {
      name: "Vencido",
      items: vencidoItems
    },
    ['5']: {
      name: "Realizado",
      items: realizadoItems
    },
    ['6']: {
      name: "Revisar",
      items: revisarItems
    }
  }), [afazerItems, fazendoItems, pausadoItems, realizadoItems, vencidoItems, revisarItems])

  const [columns, setColumns] = useState([])

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return
    const { source, destination } = result
    if (source.droppableId !== destination.droppableId) {
      // setLoadingAction(true)
      if (
        (destination.droppableId === '5' && source.droppableId !== '6')
        || (!queryMonitor && destination.droppableId === '5' && source.droppableId === '6')) {
        toast.error('Apenas o responsável pelo o acompanhamento pode finalizar a ação.', { autoClose: 6000 })
        const column = columns[source.droppableId]
        const copiedItems = [...column.items]
        const [removed] = copiedItems.splice(source.index, 1)
        copiedItems.splice(destination.index, 0, removed)
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems
          }
        })
      } else {
        api.put(`action/${parseInt(result.draggableId)}`, {
          statusId: parseInt(destination.droppableId)
        })
          .then((e) => {
            // setLoadingAction(false)
          })
          .catch(() => {
            toast.error('Erro ao mover ação.')
            const column = columns[source.droppableId]
            const copiedItems = [...column.items]
            const [removed] = copiedItems.splice(source.index, 1)
            copiedItems.splice(destination.index, 0, removed)
            setColumns({
              ...columns,
              [source.droppableId]: {
                ...column,
                items: copiedItems
              }
            })
          })

        const sourceColumn = columns[source.droppableId]
        const destColumn = columns[destination.droppableId]
        const sourceItems = [...sourceColumn.items]
        const destItems = [...destColumn.items]
        const [removed] = sourceItems.splice(source.index, 1)
        destItems.splice(destination.index, 0, removed)
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems
          }
        })
      }
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      })
    }
  }

  function handleRedirectToAction(row) {
    setActionId(row.id)
    setAcaoAtiva(true)
  }

  function handleCloseActionModal() {
    getActions()
    setAcaoAtiva(false)
  }

  function handleCloseFiltroModal() {
    setFiltroAtivo(false)
  }

  function handleCloseShowOptionsModal() {
    setShowOptions(false)
  }

  const getActions = useCallback(() => {
    let query
    if (adminTotal)
      query = `action?include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true${aditionalQuery}&&include.deadline.include.dateMarking=true&&include.extendedDeadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc&&include.topic.include.meeting.include.title=true${data?.meetingId ? `&&where.topic.meetingId=${data?.meetingId}` : ''}`
    else if (adminLocal)
      query = `action?include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true${!queryMonitor ? `&&where.OR[0].topic.meeting.convener.user.companyId=${profile.companyId}&&where.OR[1].inviteds.some.userId=${profile.id}&&where.OR[2].responsibles.some.userId=${profile.id}` : queryMonitor}${aditionalQuery}&&include.deadline.include.dateMarking=true&&include.extendedDeadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc&&include.topic.include.meeting.include.title=true${data?.meetingId ? `&&where.topic.meetingId=${data?.meetingId}` : ''}`
    else
      query = `action?include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true${!queryMonitor ? `&&where.OR[0].responsibles.some.userId=${profile.id}&&where.OR[1].inviteds.some.userId=${profile.id}` : queryMonitor}&&include.deadline.include.dateMarking=true&&include.extendedDeadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc&&include.topic.include.meeting.include.title=true${data?.meetingId ? `&&where.topic.meetingId=${data?.meetingId}` : ''}${aditionalQuery}`
    setLoadingAction(true)
    api
      .get(query)
      .then(({ data: action }) => {
        const parseAction = action.map((e) => {
          e.id = e.id.toString()
          e.deadline = e.deadline ? e.deadline.dateMarking.date : null
          e.extendedDeadline = e.extendedDeadline ? e.extendedDeadline.dateMarking.date : null
          return e
        })
        setAFazer(parseAction?.filter((e) => e.statusId === 1))
        setPausado(parseAction?.filter((e) => e.statusId === 2))
        setFazendo(parseAction?.filter((e) => e.statusId === 3))
        setVencido(parseAction?.filter((e) => e.statusId === 4))
        setRealizado(parseAction?.filter((e) => e.statusId === 5))
        setRevisar(parseAction?.filter((e) => e.statusId === 6))
        setLoadingAction(false)
      })
      .catch(e => {
        setLoadingAction(false)
      })
  }, [adminTotal, aditionalQuery, data?.meetingId, adminLocal, profile.companyId, profile.id, queryMonitor])

  const getResponsibles = useCallback(() => {
    let query
    if (adminTotal)
      query = `responsible?include.user.include.company=true`
    else if (adminLocal)
      query = `responsible?include.user.include.company=true&&where.user.companyId=${profile.companyId}`
    setLoadingResponsibles(true)
    api
      .get(query)
      .then(({ data: responsible }) => {
        setResponsiblesOptions(
          responsible.map(({ user }) => user)
            .map(({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                email,
                name,
                logoLink,
                company
              }
            })
        )
        setLoadingResponsibles(false)
      })
      .catch(e => {
        setLoadingResponsibles(false)
      })
  }, [profile, adminTotal, adminLocal])

  const getMonitors = useCallback(() => {
    let query
    if (adminTotal)
      query = `monitor?include.user.include.company=true`
    else if (adminLocal)
      query = `monitor?include.user.include.company=true&&where.OR[0].user.companyId=${profile.companyId}&&where.OR[1].topics.some.actions.some.responsibles.some.userId=${profile.id}`
    setLoadingMonitors(true)
    api
      .get(query)
      .then(({ data: monitor }) => {
        setMonitorsOptions(
          monitor.map(({ user }) => user)
            .map(({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                email,
                name,
                logoLink,
                company
              }
            })
        )
        setLoadingMonitors(false)
      })
      .catch(e => {
        setLoadingMonitors(false)
      })
  }, [profile, adminTotal, adminLocal])


  useEffect(() => {
    setColumns(columnsFromBackend)
  }, [columnsFromBackend])

  useEffect(() => {
    getActions()
  }, [getActions])

  useEffect(() => {
    getResponsibles()
  }, [getResponsibles])

  useEffect(() => {
    getMonitors()
  }, [getMonitors])

  return (
    <Container className='content mt-4 row'>
      <Modal
        size="xl"
        show={acaoAtiva}
        onHide={handleCloseActionModal}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            Ação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Action actionId={parseInt(actionId)} />
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showOptions}
        onHide={handleCloseShowOptionsModal}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            Configuração dos Cards
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmitShow)}>
            <Row>
              <Controller
                name="monitor"
                control={control}
                defaultValue={showMonitor}
                render={({ field }) => (
                  <SwitchButton
                    label="Responsável pelo acompanhamento"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
              <Controller
                name="responsible"
                control={control}
                defaultValue={showResponsible}
                render={({ field }) => (
                  <SwitchButton
                    label="Responsável pela entrega"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
            </Row>
            <Row>
              <Controller
                name="priority"
                control={control}
                defaultValue={showPriority}
                render={({ field }) => (
                  <SwitchButton
                    label="Prioridade"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
              <Controller
                name="topic"
                control={control}
                defaultValue={showTopic}
                render={({ field }) => (
                  <SwitchButton
                    label="Pauta"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
            </Row>
            <Row>
              <Controller
                name="meeting"
                control={control}
                defaultValue={showMeeting}
                render={({ field }) => (
                  <SwitchButton
                    label="Reunião"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
              <Controller
                name="deadline"
                control={control}
                defaultValue={showDeadline}
                render={({ field }) => (
                  <SwitchButton
                    label="Prazo"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
            </Row>
            <Row>
              <Controller
                name="createdAt"
                control={control}
                defaultValue={showCreatedAt}
                render={({ field }) => (
                  <SwitchButton
                    label="Data de criação"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
            </Row>
            <Button
              type="submit"
              className="btn-default form-control mt-4"
              title='Atualizar configurações'
            />
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={filtroAtivo}
        onHide={handleCloseFiltroModal}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            Filtros
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmitFilter)}>
            {(adminLocal || adminTotal) &&
              <Row>
                <Controller
                  name="actionResponsible"
                  control={control}
                  defaultValue={null}
                  render={({ field }) =>
                    <Select
                      label='Responsáveis'
                      value={field.value}
                      handleChangeValue={field.onChange}
                      name={field.name}
                      multiOptions
                      options={responsiblesOptions}
                      style={{
                        multiValue: styles => ({
                          ...styles,
                          background: "transparent"
                        }),
                        multiValueRemove: styles => ({
                          ...styles,
                          ":hover": {
                            background: 'none',
                            color: '#DE350B'
                          }
                        })
                      }}
                      formatOptionLabel={(user) => (
                        <div>
                          <Avatar
                            color="#999"
                            title={
                              user.name
                              + ' | '
                              + user.email
                            }
                            key={user.id}
                            name={user.name}
                            src={user.logoLink}
                            maxInitials={2}
                            style={{
                              marginRight: '0.4em'
                            }}
                            size='32px'
                            round />
                          <span title={user.email + ' | ' + user.company.fantasyName}>
                            {user.name.split(' ')[0]}
                          </span>
                        </div>
                      )}
                    />}
                />
                <Controller
                  name="monitors"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Select
                      label='Responsáveis pelo acompanhamento'
                      value={field.value}
                      handleChangeValue={field.onChange}
                      name={field.name}
                      multiOptions
                      options={monitorsOptions}
                      style={{
                        multiValue: styles => ({
                          ...styles,
                          background: "transparent"
                        }),
                        multiValueRemove: styles => ({
                          ...styles,
                          ":hover": {
                            background: 'none',
                            color: '#DE350B'
                          }
                        })
                      }}
                      formatOptionLabel={(user) => (
                        <div>
                          <Avatar
                            color="#999"
                            title={
                              user.name
                              + ' | '
                              + user.email
                            }
                            key={user.id}
                            name={user.name}
                            src={user.logoLink}
                            maxInitials={2}
                            style={{
                              marginRight: '0.4em'
                            }}
                            size='32px'
                            round />
                          <span title={user.email + ' | ' + user.company.fantasyName}>
                            {user.name.split(' ')[0]}
                          </span>
                        </div>
                      )}
                    />
                  )}
                />
              </Row>
            }
            <Row>
              <Controller
                name="date_start"
                control={control}
                defaultValue={''}
                render={({ field }) =>
                  <Input
                    xl="3"
                    label="Prazo de"
                    id={field.name}
                    type="date"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                  />}
              />
              <Controller
                name="date_end"
                control={control}
                defaultValue={''}
                render={({ field }) =>
                  <Input
                    xl="3"
                    label="Prazo até"
                    id={field.name}
                    type="date"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                  />}
              />
              <Controller
                name="IAmResponsible"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchButton
                    label="Ações que acompanho"
                    id={field.name}
                    name={field.name}
                    onChange={(name, val) => {
                      field.onChange(val)
                    }}
                    size="md"
                    checked={field.value}
                  />
                )}
              />
            </Row>
            <Button
              type="submit"
              className="btn-default form-control mt-4"
              title='Filtrar ações'
            />
          </form>
        </Modal.Body>
      </Modal>

      {(loadingAction || loadingMonitors || loadingResponsibles) && <Loading />}

      <ContainerCard className="col">
        <ContainerCardHeader className="row">
          <div className='col'>
            <Button
              type="button"
              name="btnVoltar"
              onClick={() => { history.goBack() }}
              className="btn-default btn"
              title="Voltar"
            />
          </div>
          <div className="col d-flex justify-content-end">
            <FaCog
              color="#686b6d"
              title="Configurações"
              size='25px'
              onClick={() => setShowOptions(true)} />
            <FaFilter
              color="#686b6d"
              title="Filtros"
              size='25px'
              onClick={() => setFiltroAtivo(true)} />
          </div>
        </ContainerCardHeader>
        <ContainerCardBody className="card-body row">
          <DragDropContext
            onDragEnd={result => onDragEnd(result, columns, setColumns)}>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <ColumnCard key={columnId} className="col-xl-4 col-lg-4 col-md-6">
                  <ColumnCardBody>
                    <ContainerColumn key={columnId}>
                      <ColumnHeader>
                        <ColumnHeaderTitle>
                          <h2>{column.name}</h2>
                          <span className="float-right">{column.items?.length}</span>
                        </ColumnHeaderTitle>
                      </ColumnHeader>
                      <ColumnContent>
                        <Droppable droppableId={columnId} key={columnId}>
                          {(provided, snapshot) => {
                            return (
                              <ColumnWrapperContent
                                {...provided.droppableProps}
                                key={columnId}
                                ref={provided.innerRef}
                                snapshot={snapshot}>
                                {column.items.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}>
                                      {(provided, snapshot) => {
                                        return (
                                          <ContentCard
                                            key={item.id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            snapshot={snapshot}
                                            provided={provided}>
                                            <Content onClick={() => handleRedirectToAction(item)}>
                                              <div className="d-flex align-items-center justify-content-between">
                                                <span
                                                  title={`Pauta: ${item.topic.agendaTopic}`}
                                                  className="text-wrap text-justify">
                                                  {item.description}
                                                </span>
                                                {item.activeAlert
                                                  ? <button title="Alertas ativados" type="button" style={{ padding: '2px' }} className="btn">
                                                    <FaBell size="20px" />
                                                  </button>
                                                  : <button title="Alertas desativados" type="button" style={{ padding: '2px' }} className="btn">
                                                    <FaBellSlash size="20px" className="float-right" />
                                                  </button>
                                                }

                                              </div>
                                              {showMeeting && (
                                                <small className="text-muted">
                                                  Reunião - {item.topic.meeting.title.value}
                                                </small>)}
                                              {showPriority &&
                                                <small className="text-muted">
                                                  {`Prioridade - ${item.priority.label}`}
                                                </small>
                                              }
                                              {showMonitor &&
                                                <div style={{ marginTop: '15px' }}>
                                                  <small className="text-muted">
                                                    Acompanhamento
                                                  </small>
                                                  {item.topic.monitors?.map(({ user }) => {
                                                    return (
                                                      <Avatar
                                                        color="#999"
                                                        title={user.name}
                                                        name={user.name}
                                                        className='float-right'
                                                        src={user.logoLink}
                                                        style={{
                                                          marginLeft: '0.4em'
                                                        }}
                                                        key={user.id}
                                                        maxInitials={2}
                                                        size='26px'
                                                        round />
                                                    )
                                                  })}
                                                </div>}
                                              {showDeadline && (
                                                <small className="text-muted">
                                                  Prazo -
                                                  {item.deadline && item.extendedDeadline &&
                                                    format(
                                                      new Date(item.extendedDeadline),
                                                      " dd MMM HH:mm",
                                                      {
                                                        locale: ptBR
                                                      })
                                                  }
                                                  {!item.deadline && item.extendedDeadline &&
                                                    format(
                                                      new Date(item.extendedDeadline),
                                                      " dd MMM HH:mm",
                                                      {
                                                        locale: ptBR
                                                      })
                                                  }
                                                  {item.deadline && !item.extendedDeadline &&
                                                    format(
                                                      new Date(item.deadline),
                                                      " dd MMM HH:mm",
                                                      {
                                                        locale: ptBR
                                                      })
                                                  }
                                                </small>)}
                                              {showCreatedAt && (
                                                <small className="text-muted">
                                                  Criado em - {' ' +
                                                    format(
                                                      new Date(item.createdAt),
                                                      "dd MMM HH:mm",
                                                      {
                                                        locale: ptBR
                                                      })
                                                  }
                                                </small>
                                              )}
                                              {showResponsible &&
                                                <div className="row" style={{ marginTop: '15px' }}>
                                                  <Col xl='4' lg='4'>
                                                    <small className="text-muted">
                                                      Entrega
                                                    </small>
                                                  </Col>
                                                  <Col xl='4' lg='4'>
                                                    <small className="text-muted">
                                                      {item.deadline && item.extendedDeadline &&
                                                        format(
                                                          new Date(item.extendedDeadline),
                                                          "dd MMM - HH:mm",
                                                          {
                                                            locale: ptBR
                                                          })
                                                      }
                                                      {!item.deadline && item.extendedDeadline &&
                                                        format(
                                                          new Date(item.extendedDeadline),
                                                          "dd MMM - HH:mm",
                                                          {
                                                            locale: ptBR
                                                          })
                                                      }
                                                      {item.deadline && !item.extendedDeadline &&
                                                        format(
                                                          new Date(item.deadline),
                                                          "dd MMM - HH:mm",
                                                          {
                                                            locale: ptBR
                                                          })
                                                      }
                                                    </small>
                                                  </Col>
                                                  <Col xl='4' lg='4' className="align-items-end">
                                                    {item.responsibles.map(({ user }) => {
                                                      return (
                                                        <Avatar
                                                          color="#999"
                                                          title={user.name}
                                                          key={user.id}
                                                          name={user.name}
                                                          src={user.logoLink}
                                                          style={{
                                                            marginLeft: '0.4em',
                                                          }}
                                                          maxInitials={2}
                                                          size='26px'
                                                          round />
                                                      )
                                                    })}
                                                  </Col>
                                                </div>}
                                              {showTopic &&
                                                <div>
                                                  <small className='text-muted'>
                                                    Pauta:&nbsp;
                                                  </small>
                                                  <ul>
                                                    <li>
                                                      <small className='text-muted'>
                                                        Titulo:&nbsp;
                                                        <span>
                                                          {item.topic.agendaTopic}
                                                        </span>
                                                      </small>
                                                    </li>
                                                    <li>
                                                      <small className='text-muted'>
                                                        Descrição:&nbsp;
                                                        <span>
                                                          {item.topic.subject}
                                                        </span>
                                                      </small>
                                                    </li>
                                                  </ul>
                                                </div>}
                                            </Content>
                                          </ContentCard>
                                        )
                                      }}
                                    </Draggable>
                                  )
                                })}
                                {provided.placeholder}
                              </ColumnWrapperContent>
                            )
                          }}
                        </Droppable>
                      </ColumnContent>
                    </ContainerColumn>
                  </ColumnCardBody>
                </ColumnCard>
              )
            })}
          </DragDropContext>
        </ContainerCardBody>
      </ContainerCard>
    </Container >
  )
}

ActionPanel.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      meetingId: PropTypes.number,
    }),
  }),
}

ActionPanel.defaultProps = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      meetingId: '',
    }),
  }),
}
