import styled from "styled-components"


export const ContainerSidebar = styled.div`
  z-index: 2;
`
export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 110px;
    width: auto;
    height: auto;
    max-width: 100%;
    padding: 8%;
    padding-top: 1.5em;
  }
`
