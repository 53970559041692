import React from 'react'
import DashboardMeeting from './Meeting'
import DashboardActions from './Actions'
import DashboardNotifications from './Notifications'
import Col from '~/components/Bootstrap/Col'
import { useSelector } from 'react-redux'
export default function Dashboard() {
  const { profile } = useSelector(state => state.auth)
  const adminLocal = profile.roleId === 2
  const adminTotal = profile.roleId === 1

  return (
    <div className="content mt-4">
      <div className="row">
        <Col xl='5'>
          <>
            <DashboardMeeting />
            {(adminLocal || adminTotal) && (
              <DashboardNotifications />
            )}
          </>
        </Col>
        <Col xl='7'>
          <DashboardActions />
        </Col>
      </div>
    </div>
  )
}
