import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import animationFloatingDocument from './floating-document-green.json'

import { Container } from './styles'

export default function Loading({
  card
}) {
  return (
    <Container card={card}>
      <Player
        autoplay
        loop
        speed={1.5}
        src={animationFloatingDocument}
        style={{ height: '300px', width: '300px' }}
      >
      </Player>
    </Container>
  )
};
