import React from 'react'
import PropTypes from 'prop-types'

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'

export default function Table({
  data,
  columns,
  extrasColumns,
  keyField,
  rowStyles = () => {
    const style = {}
    style.whiteSpace = 'nowrap'
    return style
  },
  rowEvents,
  striped,
  paginationOptions,
  search,
  ...rest
}) {
  const { SearchBar } = Search
  function customColumns(column, colIndex, { sortElement }) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          cursor: 'pointer'
        }}
      >
        {column.text}
        {sortElement}
      </div>
    )
  }

  function createExtraColumn(columns) {
    const newColumns = extrasColumns.map(item => {

      if (!item.keyConditionButtonText) {
        return {
          text: item.text,
          dataField: item.buttonText,
          mode: 'button',
          headerStyle: () => ({ width: '10%', whiteSpace: 'nowrap' }),
          headerAlign: 'center',
          align: 'center',
          formatter: (cell, row) => (
            <button
              key={row.id}
              type="button"
              className={item.className}
              onClick={() => item.onClick(row)}
            >
              {!item.keyConditionButtonText && item.buttonText}
            </button>
          ),
        }
      }

      return {
        text: item.text,
        dataField: item.buttonText,
        mode: 'button',
        headerStyle: () => ({ width: '10%', whiteSpace: 'nowrap' }),
        headerAlign: 'center',
        align: 'center',
        formatter: (cell, row) => (
          <button
            type="button"
            className={item.className}
            onClick={() => item.onClick(row)}
          >
            {item.keyConditionButtonText && row[item.keyConditionButtonText]
              ? 'Inativar'
              : 'Ativar'}
          </button>
        ),
      }
    })

    const parseColumns = columns.map(item => {
      item.headerFormatter = customColumns
      return item
    })

    return [...parseColumns, ...newColumns]
  }

  return (
    <ToolkitProvider
      keyField={keyField}
      data={data}
      columns={createExtraColumn(columns)}
      search={search}
    >
      {props => (
        <>
          {search && (
            <SearchBar
              {...props.searchProps}
              style={{
                borderRadius: "12px"
              }}
              placeholder='Pesquisar' />)}
          <BootstrapTable
            {...props.baseProps}
            {...rest}
            bootstrap4
            striped={striped}
            wrapperClasses="table-responsive"
            pagination={paginationFactory(paginationOptions)}
            rowStyle={rowStyles}
            rowEvents={rowEvents}
            noDataIndication="Sem resultados"
            bordered={false}
          />
        </>
      )}
    </ToolkitProvider>
  )
}

Table.propTypes = {
  keyField: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  extrasColumns: PropTypes.arrayOf(PropTypes.object),
  rowStyles: PropTypes.func,
  rowEvents: PropTypes.func,
  paginationOptions: PropTypes.object,
  striped: PropTypes.bool,
  search: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

Table.defaultProps = {
  data: [],
  extrasColumns: [],
  striped: true,
  search: true,
}
