import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'

import SignIn from '../pages/SignIn'

import FirstLogin from '../pages/FirstLogin'

import ResetPassword from '../pages/ResetPassword'

import Dashboard from '~/pages/Dashboard'

import Meeting from '~/pages/Meeting'
import Minute from '~/pages/Meeting/Minute'

import Action from '~/pages/Panel/Action'
import ActionMeeting from '~/pages/Panel/Meeting'

import Company from '~/pages/Settings/Company'
import User from '~/pages/Settings/User'
import Workgroup from '~/pages/Settings/Workgroup'
import Message from '~/pages/Settings/Message'

import Notification from '~/pages/Notification'

import Box from '~/pages/Box'

import MeetingRedirect from '~/pages/Redirect/Meeting'
import MeetingMinuteRedirect from '~/pages/Redirect/MeetingMinute'
import MeetingMinuteSignatureRedirect from '~/pages/Redirect/MeetingMinute/Signature'


export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} role={[1, 2, 3]} />

      <Route
        exact
        path="/home"
        component={Dashboard}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/primeiroAcesso"
        component={FirstLogin}
        role={[1, 2, 3]}
      />


      <Route
        exact
        path="/resetarSenha"
        component={ResetPassword}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/acoes"
        component={Box}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/reuniao"
        component={Meeting}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/reuniao/ata"
        component={Minute}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/reuniao/:id"
        component={MeetingRedirect}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/reuniao/ata/:id"
        component={MeetingMinuteRedirect}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />


      <Route
        exact
        path="/reuniao/ata/assinatura/:id"
        component={MeetingMinuteSignatureRedirect}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/painel/acoes"
        component={Action}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />


      <Route
        exact
        path="/painel"
        component={ActionMeeting}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/empresas"
        component={Company}
        isPrivate
        permissions={[1, 2]}
        role={[1]}
      />

      <Route
        exact
        path="/usuarios"
        component={User}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />

      <Route
        exact
        path="/workgroups"
        component={Workgroup}
        isPrivate
        permissions={[1, 2]}
        role={[1]}
      />

      <Route
        exact
        path="/mensagens"
        component={Message}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />


      <Route
        exact
        path="/notificacoes"
        component={Notification}
        isPrivate
        permissions={[1, 2]}
        role={[1, 2, 3]}
      />


    </Switch>
  )
}
