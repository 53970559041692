import PropTypes from 'prop-types'
import history from '~/services/history'

export default function MeetingRedirect({ match }) {
  const tp = match.params.id
  const id = parseInt(tp, 10) / 84576

  history.push('/reuniao', {
    id: id,
    cadastrar: true
  })
  return ''
}


MeetingRedirect.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}
