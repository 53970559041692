import React, { memo, useEffect, useState } from 'react'
import { FaHome, FaUsers, FaSignOutAlt, FaBoxes, FaBuilding, FaUserFriends, FaUsersCog, FaEnvelope } from 'react-icons/fa'
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar'
import { signOut } from '~/store/modules/auth/actions'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { ContainerSidebar, Header } from './styled'
import Collapse from './Collapse'
import { useMediaQuery } from 'react-responsive'
import { MdMoveToInbox } from 'react-icons/md'

function SidebarFunc() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [collapsed, setCollapsed] = useState(false)
  const dispatch = useDispatch()
  const { profile } = useSelector(state => state.auth)

  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  function handleSignOut() {
    dispatch(signOut())
  }

  useEffect(() => {
    setCollapsed(isTabletOrMobile)
  }, [isTabletOrMobile])

  function collapseInMobile() {
    setCollapsed(isTabletOrMobile ? !collapsed : collapsed)
  }
  return (
    <>
      <Collapse
        collapsed={collapsed}
        mobile={isTabletOrMobile}
        style={
          isTabletOrMobile ? {
            left: collapsed ? "20px" : "80px",
            zIndex: '3',
            transition: 'ease-out all .3s'
          } : {
            left: collapsed ? "80px" : "230px",
            zIndex: '3',
            transition: 'ease all .3s'
          }}
        onClick={() => setCollapsed(!collapsed)} />
      <ContainerSidebar>
        <ProSidebar
          style={
            isTabletOrMobile ? {
              boxShadow: "0px 0px 12px 0px #eee",
              minWidth: '80px',
              width: '80px',
              display: collapsed ? 'none' : ''
            } : {
              boxShadow: "0px 0px 12px 0px #eee",
              minWidth: collapsed ? '80px' : '230px',
              width: collapsed ? '80px' : '230px'
            }}
          collapsed={isTabletOrMobile ? !collapsed : collapsed}>
          <SidebarHeader>
            <Header>
              {profile.company.logoLink
                ? <img src={profile.company.logoLink} alt={profile.company.fantasyName} />
                : <img src='https://meeting-system-test.s3.sa-east-1.amazonaws.com/8632e662-b446-4296-90f5-03085c662d8c.png' alt={profile.company.fantasyName} />
              }
            </Header>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem title="Início" icon={<FaHome />}>Início <Link to="/home"></Link> </MenuItem>
              <MenuItem title="Reuniões" icon={<FaUserFriends />}>Reuniões <Link to="/reuniao"></Link> </MenuItem>
              <MenuItem title="Painel" icon={<FaBoxes />}>Painel de ações <Link to="/painel"></Link> </MenuItem>
              <MenuItem title="Ações" icon={<MdMoveToInbox />}>Ações <Link to="/acoes"></Link> </MenuItem>
              {(adminLocal || adminTotal) && (
                <>
                  {adminTotal && (
                    <MenuItem title="Empresas" icon={<FaBuilding />}>Empresas <Link onClick={collapseInMobile} to="/empresas"></Link></MenuItem>)}
                  <MenuItem title="Usuários" icon={<FaUsers />}>Usuários <Link onClick={collapseInMobile} to={{
                    pathname: "/usuarios",
                    state: { cadastrar: false, id: undefined }
                  }}></Link></MenuItem>
                  <MenuItem title="Workgroups" icon={<FaUsersCog />}>Workgroups <Link onClick={collapseInMobile} to="/workgroups"></Link></MenuItem>
                  <MenuItem title="Mensagens" icon={<FaEnvelope />}>Mensagens <Link onClick={collapseInMobile} to="/mensagens"></Link></MenuItem>
                </>
              )}
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem title="Sair" onClick={handleSignOut} icon={<FaSignOutAlt />}>Sair</MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </ContainerSidebar>
    </>
  )
}

export const Sidebar = memo(SidebarFunc)
