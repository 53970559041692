import styled from "styled-components"
import { Card, CardBody } from "~/components/Card"

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
`

export const ContainerCard = styled(Card)`
`
export const ContainerCardHeader = styled.div`
  display: flex;
  padding: 1.5em 0 0 1.6em;
  align-items: flex-end;
  width: 100%;
  form {
    width: 100%;
    input:focus {
      /* border-color: transparent; */
      box-shadow: 0 0 0 1px rgb(0 119 247 / 25%);
    }
  }
`


export const ContainerCardBody = styled(CardBody)`
  display: flex;
  justify-content: flex-start;
`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const ColumnCard = styled(Card)`
  padding: 0;
  border: none;
  box-shadow: none;
`

export const ColumnCardBody = styled(CardBody)`
  padding: 5px;
`

export const ColumnHeader = styled.div`
  padding: 4px;
`
export const ColumnHeaderTitle = styled.div`
  /* text-align: center; */
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    /* padding-left: .25rem; */
    margin-bottom: 20px;
    color: #686B6D;
    /* display: inline-block; */
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  span {
    background-color: #5e5e5e;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    right: 0.8em;
    top: 0em;
  }
`


export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
`


export const ColumnWrapperContent = styled.div`
  border-radius: 5px;
  padding: 4px;
`

export const ContentCard = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 320px;
  padding: 10px;
  margin: 0 0 18px 0;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 28%);
  flex-direction: column;
  transition: all ease .2s;
  &:hover {
    box-shadow: 0 4px 16px rgb(0 0 0 / 40%);
  }
`


export const ContentCardHeader = styled.div`
  i {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }
  small {
    font-size: 15px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 500;
  }
`

export const ContentCardBottom = styled.div`
  img {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    object-fit: contain;
    margin-left: -4px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  span {
    &:last-child {
      margin-bottom: 30px;
    }
    color: #777;
    padding: 2px 10px 2px 10px;
    font-size: 13px;
  }
`
export const ContentProgress = styled.div`
  height: 100%;
  width: 100%;
  margin: 40px 0 0 0;
  height: 10px !important;
  display: inline-block;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 5px;
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`

