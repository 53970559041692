import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import * as types from '../types'

import {
  meetingFailure,
  getMeetingsSuccess,
  createMeetingSuccess
} from './actions'

export function* createMeeting({ payload }) {
  try {
    let response
    const { id } = payload.data
    if (!id) {
      response = yield call(api.post, 'meeting', payload.data)
      toast.success('Reunião cadastrada com sucesso!')
    } else {
      response = yield call(api.put, `meeting/${id}`, payload.data)
      toast.success('Reunião atualizada com sucesso!')
    }
    yield put(createMeetingSuccess(response.data))
  } catch (error) {
    yield put(meetingFailure())
    // if (error.response.data?.message?.length > 0) {
    //   error.response.data.message.forEach(e => {
    //     toast.error(e)
    //   })
    // } else {
    //   toast.error(error.response.data?.message)
    // }
    toast.error("Erro interno.")
  }
}
export function* getMeeting({ payload }) {
  try {

    const {
      id,
      adminLocal,
      adminTotal,
      companyId,
      queryMeeting,
    } = payload.data
    let query
    if (adminLocal) {
      query = `meeting?include.title=true&&include.status=true&&include.convener.include.user.include.company&&where.OR[0].participants.some.userId=${id}&&where.OR[1].convener.user.companyId=${companyId}${queryMeeting ?? ''}`
    } else if (adminTotal) {
      query = `meeting?include.title=true&&include.status=true&&include.convener.include.user.include.company${queryMeeting ?? ''}`
    } else {
      query = `meeting?include.title=true&&include.status=true&&include.convener.include.user.include.company&&where.participants.some.userId=${id}${queryMeeting ?? ''}`
    }
    const response = yield call(api.get, query)
    yield put(getMeetingsSuccess(response.data))
  } catch (e) {
    yield put(meetingFailure())
    toast.error("Erro interno.")
  }
}
export default all([
  takeLatest(types.CREATE_MEETING_REQUEST, createMeeting),
  takeLatest(types.GET_MEETINGS_REQUEST, getMeeting),
])
