import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import * as types from '../types'

import {
  messageFailure,
  createMessageSuccess,
  getMessagesSuccess
} from './actions'

export function* createMessage({ payload }) {
  try {
    const { id } = payload.data
    if (!id) {
      yield call(api.post, 'action-email-message', payload.data)
      toast.success('Configurações cadastradas com sucesso!')
    } else {
      yield call(api.put, `action-email-message/${id}`, payload.data)
      toast.success('Configurações atualizadas com sucesso!')
    }
    yield put(createMessageSuccess())
  } catch (error) {
    yield put(messageFailure())
    // if (error.response.data?.message?.length > 0) {
    //   error.response.data.message.forEach(e => {
    //     toast.error(e)
    //   })
    // } else {
    //   toast.error(error.response.data?.message)
    // }
    toast.error("Erro interno.")
  }
}
export function* getMessages({ payload }) {
  try {
    const {
      companyId
    } = payload.data


    const response = yield call(api.get, `action-email-message?where.companyId=${companyId}`)
    yield put(getMessagesSuccess(response.data))
  } catch (e) {
    yield put(messageFailure())
    toast.error("Erro interno.")
  }
}
export default all([
  takeLatest(types.CREATE_MESSAGE_REQUEST, createMessage),
  takeLatest(types.GET_MESSAGES_REQUEST, getMessages),
])
