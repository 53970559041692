import { io } from "socket.io-client"
import React from 'react'

export const connect = (token) => {
  return io(`${process.env.REACT_APP_BACKEND_URL}`, {
    reconnectionDelayMax: 10000,
    extraHeaders: {
      token: token
    },
  })
}

// export const connect = (token) => {
//   return io("https://api-thpm4lmfzq-uc.a.run.app/", {
//     reconnectionDelayMax: 10000,
//     extraHeaders: {
//       token: token
//     },
//   })
// }
export const SocketContext = React.createContext()
