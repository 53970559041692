import styled from "styled-components"

export const ContainerHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
`
export const ContainerAction = styled.div`
  .td-action {
    .row{
      align-items: center;
    }
    i {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2px;
    }
    strong {
      color: #23527c;
      font-size: 14px;
      padding: 0px;
      margin: 0px;
    }
  }
`
