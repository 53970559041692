import * as types from '../types'


export function updateRequest(email, password) {
  return {
    type: types.UPDATE_REQUEST,
    payload: { email, password },
  }
}

export function updateSuccess(user) {
  return {
    type: types.UPDATE_SUCCESS,
    payload: { user },
  }
}

export function signInRequest(email, password) {
  return {
    type: types.SIGN_IN_REQUEST,
    payload: { email, password },
  }
}

export function signInSuccess(token, user) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: { token, user },
  }
}

export function signFailure() {
  return {
    type: types.SIGN_FAILURE,
  }
}

export function companySelect(userId) {
  return {
    type: types.COMPANY_SELECT,
    payload: { userId },
  }
}

export function companySelected() {
  return {
    type: types.COMPANY_SELECTED,
  }
}

export function signOut() {
  return {
    type: types.SIGN_OUT,
  }
}
