import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import * as types from '../types'

import {
  topicFailure,
  getTopicsSuccess,
  createTopicSuccess
} from './actions'

export function* createTopic({ payload }) {
  try {
    const { id } = payload.data
    if (!id) {
      yield call(api.post, 'topic', payload.data)
      toast.success('Pauta cadastrada com sucesso!')
    } else {
      yield call(api.put, `topic/${id}`, payload.data)
      toast.success('Pauta atualizada com sucesso!')
    }
    yield put(createTopicSuccess())
  } catch (error) {
    yield put(topicFailure())
    // if (error.response.data?.message?.length > 0) {
    //   error.response.data.message.forEach(e => {
    //     toast.error(e)
    //   })
    // } else {
    //   toast.error(error.response.data?.message)
    // }
    toast.error("Erro interno.")

  }
}
export function* getTopic({ payload }) {
  try {

    const { meetingId } = payload.data

    const response = yield call(api.get,
      `topic?where.meetingId=${meetingId}`
    )
    yield put(getTopicsSuccess(response.data))

  } catch (e) {
    yield put(topicFailure())
    toast.error("Erro interno.")
  }
}
export default all([
  takeLatest(types.CREATE_TOPIC_REQUEST, createTopic),
  takeLatest(types.GET_TOPICS_REQUEST, getTopic),
])
