
import firebase from "firebase/compat/app"
import "firebase/compat/storage"

const firebaseConfig = {
  apiKey: "AIzaSyBk-lrBj7YYV3nl4UmqZtXJL85Y48oj7o8",
  authDomain: "meeting-system-dd020.firebaseapp.com",
  projectId: "meeting-system-dd020",
  storageBucket: "meeting-system-dd020.appspot.com",
  messagingSenderId: "675792243056",
  appId: "1:675792243056:web:ec5afce879b579e46c27b5"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCGrBfKR1e8C-Yw4qByFiPDn9WSAH7FWiE",
//   authDomain: "meeting-system-test.firebaseapp.com",
//   projectId: "meeting-system-test",
//   storageBucket: "meeting-system-test.appspot.com",
//   messagingSenderId: "654748150216",
//   appId: "1:654748150216:web:1567d84406cf66a8da78fd",
//   measurementId: "G-M4XDZVP48Q"
// }


firebase.initializeApp(firebaseConfig)
const storage = firebase.storage()

export { storage, firebase as default }
