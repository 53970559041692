import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, Controller } from "react-hook-form"

import { toast } from 'react-toastify'

// components
import { Card, CardBody } from '~/components/Card'
import Row from '~/components/Bootstrap/Row'
import Button from '~/components/Form/Button'
import Loading from '~/components/Loading'
import Select from '~/components/Form/Select'
import { Editor } from '@tinymce/tinymce-react'

// reducers
import { getWorkgroupsRequest } from '~/store/modules/workgroup/actions'

// services
import history from '~/services/history'
import api from '~/services/api'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ContainerAta } from './styles'
import { Modal } from 'react-bootstrap'
import Avatar from 'react-avatar'
import Input from '~/components/Form/Input'
import Col from '~/components/Bootstrap/Col'
import { FaTrash } from 'react-icons/fa'
// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'

export default function Minute({ location }) {
  const dispatch = useDispatch()

  const { profile } = useSelector(state => state.auth)
  const { data: workgroupOptions, loading: loadingWorkgroup } = useSelector(state => state.workgroup)

  const data = useMemo(() => location.state, [location.state])

  const { control, handleSubmit, setValue, watch, getValues, reset } = useForm()

  const [assinatura, setAssinatura] = useState(false)
  // const [adicionarAssinatura, setAdicionarAssinatura] = useState(false)

  const workgroup = watch('workgroup')

  const [meeting, setMeeting] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  // const [participantsOptions, setParticipantsOptions] = useState(false)
  const [loadingParticipantesWorkgroup, setLoadingParticipantesWorkgroup] = useState(false)

  const [loadingPdf, setLoadingPdf] = useState(false)

  const [isCreator, setIsCreator] = useState(false)
  const [creator, setCreator] = useState(profile)

  const [ataModal, setAtaModal] = useState(false)

  const [externalParticipants, setExternalParticipants] = useState([])

  const [usersOptions, setUsersOptions] = useState([])


  // const handleSalvarAta = () => {
  //   setLoading(true)
  //   api.put(`meeting/${meeting?.id}`, {
  //     summary: ataValue
  //   })
  //     .then(() => {
  //       toast.success('ATA salva com sucesso.')
  //       setLoading(false)
  //     }).catch(() => {
  //       setLoading(false)
  //       toast.error('Erro ao salvar ATA.')
  //     })
  // }

  const [ataValue, setAtaValue] = useState('')

  const internalMeeting = profile.permissions?.map(e => e.id).includes(1)
  const externalMeeting = profile.permissions?.map(e => e.id).includes(2)
  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  // const onSubmitAssinatura = (data) => {
  //   if (data.usersAssinatura) {
  //     setAssinatura(data.usersAssinatura.reduce((r, e, i) =>
  //       (i % 1 ? r[r.length - 1].push(e) : r.push([e])) && r
  //       , []))
  //   } else {
  //     setAssinatura([])
  //   }
  //   setAdicionarAssinatura(false)
  // }

  const adicionarAssinatura = () => {
    if (!assinatura) setAtaValue(e => e +
      `
      <p>___________________________________________</p>
      <p>${profile.name} | ${profile.email}</p>
      <p>&nbsp;</p>
    `
    )
    setLoading(true)
    api.put(`meeting/${meeting.id}/sign`, {
      userId: profile.id
    }).then(() => {
      setAssinatura(true)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  const onSubmitUsers = (datas) => {
    if (datas.users && datas.users.length) {
      setLoadingPdf(true)
      api.post('/pdf', {
        html: ataValue
      }).then(({ data: dataUrl }) => {
        api.post('meeting/send-ata', {
          userIds: datas.users.map(({ value }) => value),
          ataLink: dataUrl.url,
          meetingId: meeting?.id
        }).then(() => {
          if (externalParticipants && externalParticipants.length > 0)
            externalParticipants.forEach(({ email, name }) => {
              api.post('meeting/send-external-ata', {
                email,
                name,
                meetingId: meeting?.id
              }).then(() => {
                setLoadingPdf(false)
                toast.success("PDFs da ATA enviados com sucesso.")
                setLoading(false)
                api.put(`meeting/${meeting?.id}`, {
                  statusId: 2
                })
                  .then(() => {
                    setAtaModal(false)
                  }).catch(() => {
                    setLoading(false)
                  })
              })
            })
          else {
            setLoadingPdf(false)
            toast.success("PDFs da ATA enviados com sucesso.")
            setLoading(false)
          }
        }).catch(() => {
          toast.error("Erro ao enviar PDFs da ATA aos paricipantes selecionados.")
          setLoadingPdf(false)
        })
      }).catch(() => {
        toast.error("Erro ao enviar PDF.")
        setLoadingPdf(false)
      })
    } else
      toast.error("Selecione pelo menos um participante.")
  }


  function handleVoltar() {
    history.push('/reuniao', {
      id: data?.id,
      cadastrar: true
    })
  }

  useEffect(() => {
    function loadMeetings() {
      setLoading(true)
      api
        .get(
          `meeting/${data.id}?include.topics.include.actions.include.comments.include.user=true` +
          `&&include.convener.include.user.include.company=true&&include.topics.include.monitors.include.user` +
          `&&include.topics.include.actions.include.responsibles.include.user=true&&` +
          `include.title=true&&include.local=true&&include.topics.include.actions.` +
          `include.deadline.include.dateMarking=true&&include.topics.include.actions.` +
          `include.extendedDeadline.include.dateMarking=true&&include.participants.include.user.include.company=true` +
          `&&include.externalSignatures=true`)
        .then(({ data: loadMeeting }) => {
          setMeeting(loadMeeting)
          const parseUsers =
            loadMeeting.participants
              .map(({ user }) => user)
              .map(({ id, name, email, logoLink, company }) => {
                return {
                  value: id,
                  label: name + ' | ' + email,
                  logoLink,
                  name,
                  id,
                  email,
                  company
                }
              })
          setCreator(loadMeeting.convener.user)
          setValue('users', parseUsers)
          setExternalParticipants(loadMeeting.externalSignatures.map(({ name, email }) => ({ name, email })))
          // setParticipantsOptions(parseUsers)
          // if (loadMeeting.summary) {
          //   setAtaValue(loadMeeting.summary)
          // }
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
    }
    if (data?.id) loadMeetings()
  }, [data, dispatch, setValue])

  useEffect(() => {
    dispatch(
      getWorkgroupsRequest({
        companyId: profile.companyId,
        adminLocal,
        adminTotal,
        id: profile.id,
      })
    )
  }, [adminLocal, adminTotal, dispatch, profile.companyId, profile.id])


  useEffect(() => {
    function getUsers() {
      let query
      if (internalMeeting && !externalMeeting) {
        query = `user?include.company=true&&where.companyId=${profile?.companyId}`
      } else if (adminTotal || adminLocal || externalMeeting) {
        query = 'user?include.company=true'
      }
      if (query) {
        setLoadingUser(true)
        api.get(query).then((response) => {
          const parseResp = response.data.map(({ id, name, email, logoLink, company }) => {
            return {
              value: id,
              label: name + ' | ' + email,
              email,
              name,
              logoLink,
              company
            }
          })
          setLoadingUser(false)
          setUsersOptions(parseResp)
        }).catch(() => {
          setLoadingUser(false)
        })
      }
    }
    function getParticipantesWorkgroup() {
      setLoadingParticipantesWorkgroup(true)
      api.get(`work-group/${workgroup.value}?include.users.include.company=true`)
        .then(({ data: workgroupData }) => {
          const parseWorkgroup = workgroupData.users.map(
            ({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                email,
                name,
                logoLink,
                company
              }
            }
          )
          setValue('users', parseWorkgroup)
          setLoadingParticipantesWorkgroup(false)
        }).catch(() => {
          setLoadingParticipantesWorkgroup(false)
        })
    }
    getUsers()
    if (workgroup) getParticipantesWorkgroup()
  }, [
    adminLocal,
    adminTotal,
    externalMeeting,
    internalMeeting,
    profile?.companyId,
    setValue,
    workgroup
  ])


  useEffect(() => {
    if (meeting) setAtaValue(`
    <img src="${meeting.convener.user.company.logoLink}" height="80px" width="180px" alt="Logo" />
    <h3 style="text-align: center">${meeting.convener.user.company.fantasyName}</h3>
    <p></p>
    <p style="text-align: center"><strong>ATA DE ${meeting.title.value.toUpperCase()}</strong></p>
    <p></p>
      <p></p>
      <ol style="font-size: 13px">
        <li><strong style="line-height: 1.8em">Data, hora e local:</strong> dia  ${format(new Date(meeting.start),
      "dd 'de' MMMM 'de' yyyy', às' HH:mm",
      { locale: ptBR }
    )}, realizada em ${meeting.local.value}.</li>
        <li>
        <strong style="line-height: 1.8em">Participantes:</strong>
          ${meeting.participants
        .map(({ user }) => user)
        .map(({ name }) => {
          return name
        }).join('; ')}
        </li>
        <li>
          <strong style="line-height: 1.8em">Pautas:</strong>
          <ul>
            ${meeting.topics.map(({ id, agendaTopic }) => {
          return (
            `<li key=${id}>
            ${agendaTopic}
        </li>`
          )
        }).join('')}
        </ul>
      <br />
      <li>
      <strong>Ações:</strong>
      <ul>
        ${meeting.topics.map(({ monitors, actions }) => {

          return (`
                  ${actions.map(({ deadline, description, responsibles, comments }) => {
            return (
              `<li style="line-height: 2em">
                ${monitors.map(({ user }) => user.name).join(', ')}
                ${monitors.length > 1 ? 'delegaram' : 'delegou'} a ação
                "${description}" a ${responsibles.map(({ user }) => user.name).join(', ')}
                com o prazo até ${format(new Date(deadline.dateMarking.date),
                "dd 'de' MMMM 'de' yyyy', às' HH:mm",
                { locale: ptBR }
              )}.
              <ul>
              ${comments.map(({ user, value }) => {
                return (`
                  <li>
                    ${user.name} comentou:
                    <ul><li>${value}</li></ul>
                  </li>
                `)
              }).join('')}
              </ul>
              </li>`
            )
          }).join('')}`)
        }).join('')}
        </ul>
      </li>
      ${meeting.topics.map(({ conclusion }) => conclusion).filter(n => n).length > 0 ? `
      <li><strong style="line-height: 2.2em">Conclusões:</strong>
        <ul>
          ${meeting.topics.map(({ id, agendaTopic, conclusion }) => {
          if (conclusion)
            return (
              `<li key=${id} style="line-height: 1.9em">
                Pauta "${agendaTopic}": ${conclusion}
              </li>`
            )
          return ''
        }).join('')}
        </ul>
      </li>` : ''}
    </ol>
    <p></p>
  `
    )
  }, [meeting])

  useEffect(() => {
    setIsCreator(creator?.id === profile?.id)
  }, [creator?.id, profile?.id])

  const addParticipant = () => {
    if (!getValues('nameExternalMinute'))
      toast.error('Digite o nome do participante.')
    else if (!getValues('emailExternalMinute'))
      toast.error('Digite o email do participante.')
    else setExternalParticipants(ex =>
      [...ex, { name: getValues('nameExternalMinute'), email: getValues('emailExternalMinute') }]
    )
    reset()
  }

  const removeExternalParticipant = (emailParticipant) => {
    setExternalParticipants(ex => ex.filter(({ email }) => email !== emailParticipant))
  }

  return (
    <>
      {(loading || loadingWorkgroup || loadingUser || loadingPdf || loadingParticipantesWorkgroup) && <Loading />}
      <Modal
        size="xl"
        show={ataModal}
        onHide={() => setAtaModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            Opções
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingPdf && <Loading card={true} />}
          {!loadingPdf && (
            <form autoComplete="off" onSubmit={handleSubmit(onSubmitUsers)}>
              <Row>
                <Controller
                  name="workgroup"
                  control={control}
                  defaultValue={null}
                  render={({ field }) =>
                    <Select
                      label='Workgroup'
                      isClearable={false}
                      value={field.value}
                      lg='12'
                      xl='12'
                      handleChangeValue={field.onChange}
                      name={field.name}
                      options={workgroupOptions?.map(({ id, name }) => {
                        return {
                          value: id,
                          label: name
                        }
                      })}
                    />}
                />
              </Row>
              <Row>
                <Controller
                  name="users"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      xl='12'
                      lg='12'
                      label='Enviar ATA para'
                      value={field.value}
                      handleChangeValue={field.onChange}
                      name={field.name}
                      multiOptions
                      options={usersOptions}
                      style={{
                        multiValue: styles => ({
                          ...styles,
                          background: "transparent"
                        }),
                        multiValueRemove: styles => ({
                          ...styles,
                          ":hover": {
                            background: 'none',
                            color: '#DE350B'
                          }
                        })
                      }}
                      formatOptionLabel={(user) => (
                        <div>
                          <Avatar
                            color="#999"
                            title={
                              user.name
                              + ' | '
                              + user.email
                            }
                            key={user.id}
                            name={user.name}
                            src={user.logoLink}
                            maxInitials={2}
                            style={{
                              marginRight: '0.4em'
                            }}
                            size='32px'
                            round />
                          <span title={user.email + ' | ' + user.company.fantasyName}>
                            {user.name.split(' ')[0]}
                          </span>
                        </div>
                      )}
                    />
                  )}
                />
              </Row>
              <Button
                type="submit"
                className="btn-default form-control mt-4"
                title='Enviar'
              />
            </form>)}
        </Modal.Body>
      </Modal>
      {!data?.pdf && (
        <Row className='mt-4'>
          <div className="col-lg-8 col-xl-9">
            <Card>
              <CardBody className='overflow-auto'>
                <ContainerAta>
                  <Row>
                    <Button
                      type="button"
                      name="btnVoltar"
                      onClick={handleVoltar}
                      className="btn-default btn"
                      title="Voltar"
                    />
                  </Row>
                </ContainerAta>
                <Row>
                  <ContainerAta>
                    <Controller
                      name="ata"
                      control={control}
                      defaultValue={null}
                      render={({ field }) =>
                        <Editor
                          apiKey="478hxhqnrft0a0o3ycsfagcd280purqk32seb710lryi22oz"
                          // tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce.min.js'}
                          value={ataValue}
                          plugins={['autoresize']}
                          disabled={(!isCreator && !adminTotal)}
                          onEditorChange={(event) => setAtaValue(event)}
                          init={{
                            visual: false,
                            height: 500,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code pagebreak'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | pagebreak',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                            language: 'pt_BR',
                            name: `${field.name}`,
                            statusbar: false
                          }}
                        />
                      }
                    />
                  </ContainerAta>
                </Row>
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-4 col-xl-3">
            <Card>
              <CardBody>
                <div>
                  <h6>Opções</h6>
                  {/* <Button
                    type="button"
                    name="btnSalvarAta"
                    onClick={handleSalvarAta}
                    disabled={(!isCreator && !adminTotal)}
                    className="btn-default mt-2 form-control"
                    title="Savar ata"
                  /> */}
                  <Button
                    type="button"
                    name="btnAdicionarAssinatura"
                    onClick={adicionarAssinatura}
                    className="btn-default mt-2 form-control"
                    title="Assinar"
                  />
                  <Button
                    type="button"
                    name="btnEnviarAta"
                    onClick={() => setAtaModal(true)}
                    className="btn-default mt-2 form-control"
                    title="Enviar ata"
                  />
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <form autoComplete="off" onSubmit={handleSubmit(onSubmitUsers)}>
                  <div>
                    <h6>Adicionar participantes</h6>
                    <Controller
                      name="nameExternalMinute"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Input
                          label="Nome"
                          id={field.name}
                          type="text"
                          lg='12'
                          xl='12'
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Controller
                      name="emailExternalMinute"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Input
                          label="Email"
                          id={field.name}
                          type="email"
                          lg='12'
                          xl='12'
                          name={field.name}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Button
                      type="button"
                      name="btnEnviarAta"
                      onClick={() => addParticipant()}
                      className="btn-default mt-2 form-control"
                      title="Adicionar"
                    />
                  </div>
                </form>
              </CardBody>
            </Card>
            {!!externalParticipants.length && (
              <Card>
                <CardBody>
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmitUsers)}>
                    <div>
                      <h6>Participantes adicionados</h6>
                      {externalParticipants?.map(e => {
                        return (
                          <React.Fragment key={e.email}>
                            <Card
                              style={{ borderRadius: '13px' }}>
                              <CardBody
                                className="d-flex flex-column justify-content-center row">
                                <Row>
                                  <Col xl='12' lg='12' className='d-flex justify-content-between'>
                                    <span
                                      className="text-wrap">
                                      Nome: {e.name}
                                    </span>
                                    <FaTrash
                                      size='12px'
                                      title="Remover participante"
                                      color="#f59384" style={{
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => removeExternalParticipant(e.email)}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl='12' lg='12'>
                                    <span
                                      className="text-wrap">
                                      Email: {e.email}
                                    </span>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </form>
                </CardBody>
              </Card>
            )}

            {/* {adicionarAssinatura && (
              <Card>
                <CardBody>
                  <form onSubmit={handleSubmit(onSubmitAssinatura)}>
                    <Row>
                      <Controller
                        name="usersAssinatura"
                        control={control}
                        defaultValue={null}
                        render={({ field }) =>
                          <Select
                            label='Responsáveis'
                            value={field.value}
                            handleChangeValue={field.onChange}
                            name={field.name}
                            multiOptions
                            xl='12'
                            lg='12'
                            options={participantsOptions}
                            style={{
                              multiValue: styles => ({
                                ...styles,
                                background: "transparent"
                              }),
                              multiValueRemove: styles => ({
                                ...styles,
                                ":hover": {
                                  background: 'none',
                                  color: '#DE350B'
                                }
                              })
                            }}
                            formatOptionLabel={(user) => (
                              <div>
                                <Avatar
                                  color="#999"
                                  title={
                                    user.name
                                    + ' | '
                                    + user.email
                                  }
                                  key={user.id}
                                  name={user.name}
                                  src={user.logoLink}
                                  maxInitials={2}
                                  style={{
                                    marginRight: '0.4em'
                                  }}
                                  size='32px'
                                  round />
                                <span title={user.email + ' | ' + user.company.fantasyName}>
                                  {user.name.split(' ')[0]}
                                </span>
                              </div>
                            )}
                          />}
                      />
                    </Row>
                    <Button
                      type="submit"
                      className="btn-default form-control mt-4"
                      title='Adicionar'
                    />
                  </form>
                </CardBody>
              </Card>
            )} */}
          </div>
        </Row>
      )
      }
    </>
  )
}

Minute.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      pdf: PropTypes.bool,
    }),
  }),
}

Minute.defaultProps = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: '',
      pdf: false,
    }),
  }),
}
