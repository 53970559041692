import styled from 'styled-components'

export const ContainerNotificationSettings = styled.div`
  padding: 30px;
  span{
    font-family: 'Lucida Sans','Lucida Sans Regular','Lucida Grande','Lucida Sans Unicode',Geneva,Verdana,sans-serif;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 20px;
  }
`
