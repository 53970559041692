import React from 'react'
import InputMask from 'react-input-mask'
import PropTypes from 'prop-types'
export default function Input({
  // reponsividade breakpoints (Extra small <576px)
  col,
  // reponsividade breakpoints (Small >=576px)
  sm,
  // reponsividade breakpoints (Medium >=768px)
  md,
  // reponsividade breakpoints (Large >=992px)
  lg,
  // reponsividade breakpoints (Extra Large >=1200px)
  xl,
  label,
  value = '',
  name,
  onChange,
  id,
  type,
  placeholder,
  mask,
  handleOnBlur,
  error,
  disabled,
  handleKeyUp,
  style,
  ref,
  appendBtn,
  handleAppendBtn,
  autoComplete = "off",
  accept,
  hidden,
  min,
  max,
  maxLength,
  className
}) {
  return (
    !hidden && (
      <div className={`col-${col} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl}`}>
        <div className={'form-group'}>
          {!appendBtn && label && <label htmlFor={id} style={style}>{label}</label>}
          {mask && (
            <InputMask
              type={type}
              className={`form-control ${className} ${error && 'is-invalid'}`}
              id={id}
              maxLength={maxLength}
              name={name}
              hidden={hidden}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              mask={mask}
              onBlur={handleOnBlur}
              disabled={disabled}
              ref={ref}
              autoComplete={autoComplete}
              onKeyUp={handleKeyUp}
            />

          )}
          {!mask && (
            <>
              {!appendBtn && (
                <input
                  type={type}
                  maxLength={maxLength}
                  hidden={hidden}
                  className={`form-control ${className} `}
                  id={id}
                  accept={accept}
                  name={name}
                  min={min}
                  max={max}
                  autoComplete={autoComplete}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  onBlur={handleOnBlur}
                  disabled={disabled}
                  ref={ref}
                  onKeyUp={handleKeyUp}
                />)}
              {appendBtn && (
                <>
                  <label htmlFor={id} style={style}>{label}</label> <br />
                  <div className="input-group">
                    <input
                      maxLength={maxLength}
                      type={type}
                      className={`form-control ${className} `}
                      id={id}
                      name={name}
                      hidden={hidden}
                      autoComplete={autoComplete}
                      value={value}
                      onChange={onChange}
                      placeholder={placeholder}
                      onBlur={handleOnBlur}
                      disabled={disabled}
                      ref={ref}
                      onKeyUp={handleKeyUp}
                    />

                    <span className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={handleAppendBtn}>
                        {appendBtn}
                      </button>
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    )
  )
}

Input.propTypes = {
  col: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  xl: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  handleOnBlur: PropTypes.func,
  handleKeyUp: PropTypes.func,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  style: PropTypes.object,
  autoComplete: PropTypes.string,
  ref: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.number,
  className: PropTypes.string,
}

Input.defaultProps = {
  mask: '',
  disabled: false,
  placeholder: '',
  value: '',
  col: '12',
  sm: '12',
  md: '12',
  lg: '6',
  xl: '6',
  autoComplete: '',
  className: '',
  handleOnBlur: () => { },
  error: '',
}
