import React from 'react';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import PropTypes from 'prop-types';
import { FaBars } from 'react-icons/fa';

export default function Collapse({
  collapsed = false,
  style,
  mobile = false,
  onClick
}) {

  return (
    <>
      <button
        style={style}
        className="collapse-sidebar"
        onClick={onClick}>
        {!mobile && (
          collapsed ? <FiChevronsRight size="28" /> : <FiChevronsLeft size="28" />
        )}
        {mobile && (
          collapsed ? <FaBars size="17" /> : <FiChevronsLeft size="28" />
        )}
      </button>
    </>
  )
}

Collapse.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func
}