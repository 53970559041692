import produce from 'immer'
import * as types from '../types'
const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  profile: {
    id: '',
    email: '',
    firebaseId: '',
    name: '',
    roleId: '',
    roleName: '',
    loggedFirstTime: false,
    active: false,
    logoLink: '',
    companyId: '',
    companyName: '',
    createdAt: ''
  },
  companySelected: false,
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SIGN_IN_REQUEST: {
        draft.loading = true
        break
      }
      case types.UPDATE_REQUEST: {
        draft.loading = true
        break
      }
      case types.UPDATE_SUCCESS: {
        draft.profile = action.payload.user
        draft.loading = false
        break
      }
      case types.SIGN_IN_SUCCESS: {
        draft.token = action.payload.token
        draft.profile = action.payload.user
        draft.signed = true
        draft.companySelected = false
        draft.loading = false
        break
      }
      case types.SIGN_FAILURE: {
        draft.loading = false
        draft.token = null
        draft.signed = false
        draft.profile = null
        draft.companySelected = false
        break
      }
      case types.SIGN_OUT: {
        draft.token = null
        draft.signed = false
        draft.profile = {
          id: '',
          email: '',
          firebaseId: '',
          name: '',
          roleId: '',
          roleName: '',
          loggedFirstTime: false,
          active: false,
          logoLink: '',
          companyId: '',
          companyName: '',
          permissions: [],
          createdAt: ''
        }
        // draft.companySelected = false
        break
      }
      case types.COMPANY_SELECT: {
        draft.loading = true
        break
      }
      case types.COMPANY_SELECTED: {
        draft.loading = false
        draft.companySelected = true
        break
      }
      default:
    }
  })
}
