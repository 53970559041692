import PropTypes from 'prop-types'
import history from '~/services/history'

export default function MeetingMinuteRedirect({ match }) {
  const tp = match.params.id
  const id = parseInt(tp, 10) / 84576

  history.push('/reuniao/ata', {
    id: parseInt(id, 10)
  })
  return ''
}


MeetingMinuteRedirect.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}
