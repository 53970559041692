import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
export function Card({
  children,
  className = "mb-3 box-shadow",
  border,
  style = {
    textAlign: 'left',
    borderRadius: '13px',
    border: border
  },
  ref
}) {
  return (
    <div
      className={`card ${className}`}
      style={style}
      ref={ref}>
      {children}
    </div>
  )
}

export function CardHeader({
  title,
  description,
  className = '',
  classTitle = "my-0 font-weight-bold",
  classDescription = "my-0 font-weight-normal",
  style = {
    textAlign: 'center'
  }
}) {
  return (
    <div className={`card-header ${className}`} style={style}>
      <h3 className={classTitle}>
        {title}
      </h3>
      <h6 className={classDescription}>
        {description}
      </h6>
    </div>
  )
}

export function CardBody({
  children,
  className = "d-flex flex-column",
  title,
  classTitle = 'my-0 font-weight-normal',
  style,
  path,
  ref,
  link = false
}) {
  return (
    <div ref={ref} className={`card-body ${className}`} style={style}>
      {title &&
        <h1 className={classTitle}>
          {title}
        </h1>
      }
      {children}
      {link &&
        <Link
          to={path}
          className={`mt-auto btn btn-lg btn-block btn-default`}>
          Ver mais
        </Link>
      }
    </div>
  )
}

export function CardFooter({ children, className, style }) {
  return <div className={`card-footer ${className}`} style={style}>{children}</div>
}


Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.objectOf(PropTypes.element),
    PropTypes.element
  ]),
  style: PropTypes.object,
  className: PropTypes.string,
  border: PropTypes.string,
  ref: PropTypes.string,
}

CardHeader.propTypes = {
  className: PropTypes.string,
  classTitle: PropTypes.string,
  classDescription: PropTypes.string,
  style: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
}

CardBody.propTypes = {
  className: PropTypes.string,
  classTitle: PropTypes.string,
  classDescription: PropTypes.string,
  style: PropTypes.object,
  path: PropTypes.string,
  link: PropTypes.bool,
  title: PropTypes.string
}

CardFooter.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  style: PropTypes.string
}
