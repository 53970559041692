import { combineReducers } from 'redux'

import auth from './auth/reducer'

// reuniões
import meeting from './meeting/reducer'
import topic from './topic/reducer'
import action from './action/reducer'

// configurações
import user from './user/reducer'
import company from './company/reducer'
import workgroup from './workgroup/reducer'
import message from './message/reducer'

export default combineReducers({
  auth,
  // configurações
  user,
  company,
  workgroup,
  meeting,
  topic,
  action,
  message,
})
