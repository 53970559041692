import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'

// components
import { Card, CardBody } from '~/components/Card'
import Header from '~/components/Header'
import Description from '~/components/Description'
import Row from '~/components/Bootstrap/Row'
import Loading from '~/components/Loading'
import Button from '~/components/Form/Button'
import { toast } from 'react-toastify'


// services

import { Editor } from '@tinymce/tinymce-react'
import { ContainerNotificationSettings } from './styles'
import { createMessageRequest, getMessagesRequest } from '~/store/modules/message/actions'
import Col from '~/components/Bootstrap/Col'

export default function Message() {
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.auth)

  const { data: messageData, loading } = useSelector((state) => state.message)

  const { control, handleSubmit, setValue, register } =
    useForm()



  const [beforeDate, setBeforeDate] = useState('')
  const [onDate, setOnDate] = useState('')
  const [afterDate, setAfterDate] = useState('')

  async function onSubmit(data) {
    if (!beforeDate) {
      toast.error('A mensagem de um dia antes é obrigatória.')
    } else if (!onDate) {
      toast.error('A mensagem no dia é obrigatória.')
    } else if (!onDate) {
      toast.error('A mensagem de um dia após é obrigatória.')
    } else {
      dispatch(
        createMessageRequest({
          id: data.id,
          beforeExpiryDate: beforeDate,
          onExpiryDate: onDate,
          afterExpiryDate: afterDate,
          companyId: profile.companyId
        })
      )
    }
  }

  useEffect(() => {
    dispatch(getMessagesRequest({
      companyId: profile.companyId
    }))
  }, [dispatch, profile.companyId])

  useEffect(() => {
    if (messageData) {
      setBeforeDate(messageData[0]?.beforeExpiryDate)
      setOnDate(messageData[0]?.onExpiryDate)
      setAfterDate(messageData[0]?.afterExpiryDate)
      setValue('id', messageData[0]?.id)
    }
  }, [messageData, setValue])

  return (
    <>
      {loading && <Loading />}
      <div className="row mb-2 mt-4 d-flex align-items-center ml-2">
        <Header
          title='Configuração dos cards'
          ativo
        ></Header>
      </div>
      <Card>
        <CardBody>
          <Description title="Mensagens" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <input hidden {...register("id")} />
            <ContainerNotificationSettings>
              <Col xl='12' lg='12'>
                <Row>
                  <span>
                    Um dia antes de vencer
                  </span>
                </Row>
                <Row>
                  <Controller
                    name="beforeDate"
                    control={control}
                    defaultValue=''
                    render={({ field }) =>
                      <Editor
                        apiKey="478hxhqnrft0a0o3ycsfagcd280purqk32seb710lryi22oz"
                        // tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce.min.js'}
                        value={beforeDate}
                        plugins={['autoresize']}
                        onEditorChange={(e) => setBeforeDate(e)}
                        init={{
                          visual: false,
                          height: 500,
                          menubar: false,
                          width: '100%',
                          plugins: [
                            'paste code'
                          ],
                          toolbar: 'undo redo | ' +
                            'bold italic | alignleft aligncenter | alignright alignjustify',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 12px; }',
                          language: 'pt_BR',
                          name: `${field.name}`,
                          statusbar: false
                        }}
                      />
                    }
                  />
                </Row>
                <Row className="flex-column">
                  <span>
                    No dia do vencimento
                  </span>
                </Row>
                <Row>
                  <Controller
                    name="onDate"
                    control={control}
                    defaultValue=''
                    render={({ field }) =>
                      <Editor
                        apiKey="478hxhqnrft0a0o3ycsfagcd280purqk32seb710lryi22oz"
                        // tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce.min.js'}
                        value={onDate}
                        plugins={['autoresize']}
                        onEditorChange={(e) => setOnDate(e)}
                        init={{
                          visual: false,
                          height: 500,
                          menubar: false,
                          width: '100%',
                          plugins: [
                            'paste code'
                          ],
                          toolbar: 'undo redo | ' +
                            'bold italic | alignleft aligncenter | alignright alignjustify',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 12px; }',
                          language: 'pt_BR',
                          name: `${field.name}`,
                          statusbar: false
                        }}
                      />
                    }
                  />
                </Row>
                <Row className='flex-column'>
                  <span>
                    Um dia após o vencimento
                  </span>
                </Row>
                <Row>
                  <Controller
                    name="afterDate"
                    control={control}
                    defaultValue=''
                    render={({ field }) =>
                      <Editor
                        apiKey="478hxhqnrft0a0o3ycsfagcd280purqk32seb710lryi22oz"
                        // tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce.min.js'}
                        value={afterDate}
                        plugins={['autoresize']}
                        onEditorChange={(e) => setAfterDate(e)}
                        init={{
                          visual: false,
                          height: 500,
                          menubar: false,
                          width: '100%',
                          plugins: [
                            'paste code'
                          ],
                          toolbar: 'undo redo | ' +
                            'bold italic | alignleft aligncenter | alignright alignjustify',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size: 12px; }',
                          language: 'pt_BR',
                          name: `${field.name}`,
                          statusbar: false
                        }}
                      />
                    }
                  />
                </Row>
              </Col>
              <Row>
                <Button
                  type="submit"
                  className="btn-default form-control mt-4"
                  title='Atualizar'
                />
              </Row>
            </ContainerNotificationSettings>
          </form>
        </CardBody>
      </Card>
    </>
  )
}

