import React from 'react'
import PropTypes from 'prop-types'
export default function Row({ className, children }) {
  return <div className={`row ${className}`}>{children}</div>
}

Row.propTypes = {
  className: PropTypes.string,
}

Row.defaultProps = {
  className: '',
}
