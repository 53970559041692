import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm, Controller } from 'react-hook-form'

import { companySelect } from '../../store/modules/auth/actions'
import Row from '../../components/Bootstrap/Row'
import Loading from '../../components/Loading'
import { Container, BottomSide, LoginCard, TopSide } from './styles'
import Button from '../../components/Form/Button'
import Select from '../../components/Form/Select'
import api from '../../services/api'

export default function CompanySelect() {
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { loading: loadingAuth, profile } = useSelector((state) => state.auth)

  const [companiesData, setCompaniesData] = useState([])
  const [loading, setLoading] = useState(false)

  const onSubmit = (data) => {
    if (!data.company) {
      toast.error('Selecione alguma empresa.')
    } else {
      setLoading(true)
      api
        .put(`user/${profile.id}`, { companyId: data.company.value })
        .then(() => {
          setLoading(false)
          dispatch(companySelect({ userId: profile.id }))
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    const getCompanies = () => {
      setLoading(true)
      const profileCompanies = JSON.parse(profile?.companies)
      let query = ''
      profileCompanies.forEach((elem, i) => {
        query += `${i > 0 ? '&&' : ''}where.OR[${i}].id=${elem}`
      })
      if (query)
        api
          .get(`company?${query}`)
          .then(({ data: dataCompany }) => {
            setLoading(false)
            setCompaniesData(dataCompany)
          })
          .catch(() => {
            setLoading(false)
          })
    }
    getCompanies()
  }, [dispatch, profile])

  return (
    <Container>
      {(loading || loadingAuth) && <Loading />}

      <TopSide>
        <img src={profile?.company?.logoLink} alt="servnacLogo" />
      </TopSide>

      <BottomSide>
        <LoginCard>
          <p>Selecione uma empresa</p>

          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Controller
                name="company"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    label="Empresa"
                    xl="12"
                    lg="12"
                    isClearable={false}
                    value={field.value}
                    handleChangeValue={field.onChange}
                    name={field.name}
                    options={companiesData?.map(({ id, fantasyName }) => ({
                      value: id,
                      label: fantasyName,
                    }))}
                    style={{
                      multiValue: (styles) => ({
                        ...styles,
                        background: 'transparent',
                      }),
                      multiValueRemove: (styles) => ({
                        ...styles,
                        ':hover': {
                          background: 'none',
                          color: '#DE350B',
                        },
                      }),
                    }}
                    formatOptionLabel={(user) => (
                      <div>
                        <span>{user.label}</span>
                      </div>
                    )}
                  />
                )}
              />
            </Row>
            <Button
              type="submit"
              className="btn-outline-success form-control mt-4"
              title="Selecionar"
            />
          </form>
        </LoginCard>
      </BottomSide>
    </Container>
  )
}
