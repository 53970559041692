import * as types from '../types'

export function createProfileRequest(data) {
  return {
    type: types.CREATE_PROFILE_REQUEST,
    payload: { data },
  }
}
export function createProfileSuccess() {
  return {
    type: types.CREATE_PROFILE_SUCCESS,
    payload: {},
  }
}

export function getUsersRequest(data) {
  return {
    type: types.GET_USERS_REQUEST,
    payload: { data },
  }
}

export function getUsersSuccess(data) {
  return {
    type: types.GET_USERS_SUCCESS,
    payload: { data },
  }
}

export function profileFailure() {
  return {
    type: types.USERS_FAILURE,
  }
}
