import styled, { keyframes } from "styled-components"

export const dropdownAnimation = keyframes`
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
`

export const ContainerNavbar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  overflow: hidden;
  z-index: 1;
`

export const ContainerNavbarProfile = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  button > span {
    @media screen and (max-width: 389px) {
      display: none;
    }
  }
  .dropdown-menu {
    &:after {
      content: ' ';
      position: absolute;
      right: 26px;
      top: -15px;
      border-top: none;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-bottom: 15px solid #f8f8f8;
    }
    &.show{
      animation: ${dropdownAnimation} ease 0.5s forwards;
    }
  }
  .btn-primary {
    color: #000;
    background-color: transparent;
    border: none;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    background-color: white;
    color: #000;
  }
  .dropdown-toggle::after {
    content: none;
  }
`

export const NavNotifications = styled.div`
  color: #000;
  position: relative;
  .btn {
    color: #000;
    background-color: transparent;
    border: none;
  }
  .btn:not(:disabled):not(.disabled):active,
  .btn:not(:disabled):not(.disabled).active,
  .show > .btn.dropdown-toggle {
    background-color: transparent;
    color: #000;
  }
  .dropdown-toggle::after {
    content: none;
  }
  .dropdown-menu.show {
    animation: ${dropdownAnimation} ease 0.5s forwards;
  }
  .dropdown-menu {
    z-index: 2;
    position: fixed;
    top: 55px;
    right: ${props => `calc(${props.dropdownProfileSize}px - 7px)`};
    @media screen and (max-width: 390px) {
      right: 49px;
    }
    max-width: 350px;
    &:after {
      content: ' ';
      position: absolute;
      right: 30px;
      top: -13px;
      border-top: none;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-bottom: 15px solid #f8f8f8;
    }
  }
  .dropdown-item:not(:nth-child(1)) {
    border-top: solid 1px;
  }
`


export const Notifications = styled.span`
  position: absolute;
  background-color: #1b9f36;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  right: 0.8em;
  top: 0em;
`
