import axios from 'axios'
import { } from './firebase'
import { getAuth } from "firebase/auth"
import { signFailure } from '~/store/modules/auth/actions'
// import { store } from '~/store'

// const axiosApi = axios.create({
//   baseURL: 'http://127.0.0.1:3001',
// })


const axiosApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
})

async function configCredentials() {
  return new Promise((resolve) => {
    const auth = getAuth()
    auth.onAuthStateChanged(user => {
      user && user.getIdToken().then((e) => {
        axiosApi.defaults.headers.Authorization = `Bearer ${e}`
        resolve()
      }).catch((err) => {
        signFailure()
      })
    })
  })
}
async function getToken() {
  return new Promise((resolve) => {
    const auth = getAuth()
    auth.onAuthStateChanged(user => {
      user && user.getIdToken().then((e) => {
        axiosApi.defaults.headers.Authorization = `Bearer ${e}`
        resolve(e)
      }).catch((err) => {
        signFailure()
      })
    })
  })
}

async function get(params, data, config) {
  await configCredentials()
  const response = await axiosApi.get(params, data, config)
  return response
}

async function post(params, data, config) {
  await configCredentials()
  const response = await axiosApi.post(params, data, config)
  return response
}

async function put(params, data, config) {
  await configCredentials()
  const response = await axiosApi.put(params, data, config)
  return response
}

async function axiosDelete(params, data, config) {
  await configCredentials()
  const response = await axiosApi.delete(params, data, config)
  return response
}
const api = {
  get,
  post,
  put,
  delete: axiosDelete,
  axiosApi,
  getToken
}
export default api
