/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import {
  ColumnCard,
  ColumnCardBody,
  ColumnContent,
  ColumnWrapperContent,
  Container,
  ContainerCard,
  ContainerCardBody,
  ContainerCardHeader,
  ContainerColumn,
  Content,
  ContentCard,
  ContentCardBottom,
  ContentCardHeader,
  ContentProgress
} from "./styles"
import api from "~/services/api"
import Loading from "~/components/Loading"
import { Controller, useForm } from "react-hook-form"
import Avatar from "react-avatar"
import history from "~/services/history"
import { ProgressBar } from 'react-bootstrap'
import InputIcon from "~/components/Form/InputIcon"
import { FaArrowLeft, FaArrowRight, FaSearch } from "react-icons/fa"
import Button from "~/components/Form/Button"
import Col from "~/components/Bootstrap/Col"

export default function MeetingPanel() {
  const { profile } = useSelector(state => state.auth)

  const { control, handleSubmit } = useForm()

  const [loading, setLoading] = useState(false)

  // const [meetingItems1, setMeetingItems1] = useState([])
  // const [meetingItems2, setMeetingItems2] = useState([])
  // const [meetingItems3, setMeetingItems3] = useState([])
  // const [meetingItems4, setMeetingItems4] = useState([])

  const [meeting, setMeeting] = useState([])

  const [meetingAction, setMeetingAction] = useState([])
  const [meetingActionAFazer, setMeetingActionAFazer] = useState([])
  const [meetingActionPausado, setMeetingActionPausado] = useState([])
  const [meetingActionFazendo, setMeetingActionFazendo] = useState([])
  const [meetingActionRealizado, setMeetingActionRealizado] = useState([])
  const [meetingActionVencido, setMeetingActionVencido] = useState([])
  const [meetingActionRevisar, setMeetingActionRevisar] = useState([])


  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  const [aditionalQuery, setAditionalQuery] = useState('')

  const [paginationSkip, setPaginationSkip] = useState(0)

  // filters

  const onSubmitFilter = (data) => {
    setAditionalQuery(data.meetingTitle ? `&&where.title.value.contains=${data.meetingTitle}` : '')
  }


  function handleRedirectToActions(row) {
    history.push('/painel/acoes', {
      meetingId: row.id
    })
  }

  function handleRedirectToAllActions() {
    history.push('/painel/acoes')
  }

  const getMeetings = useCallback(() => {
    let query
    if (adminTotal)
      query = `meeting?include.topics.include.actions=true&&include.title=true&&`
        + `include.convener.include.user.include.company=true&&include.participants.include.user=true` +
        `${aditionalQuery}&&orderBy.createdAt=desc&&skip=${paginationSkip}&&take=8`
    else if (adminLocal)
      query = `meeting?include.topics.include.actions=true&&include.title=true&&` +
        `include.convener.include.user.include.company=true&&include.participants.include.user=true&&` +
        `where.convener.user.companyId=${profile.companyId}${aditionalQuery}&&orderBy.createdAt=desc&&` +
        `skip=${paginationSkip}&&take=8`
    else
      query = `meeting?include.topics.include.actions=true&&include.title=true&&` +
        `include.convener.include.user.include.company=true&&include.participants.include.user=true` +
        `&&where.participants.some.user.id=${profile.id}&&where.topics.some.actions.some.responsibles` +
        `.some.userId=${profile.id}${aditionalQuery}&&orderBy.createdAt=desc&&` +
        `skip=${paginationSkip}&&take=8`
    setLoading(true)
    api
      .get(query)
      .then(({ data: meeting }) => {
        setMeeting(meeting)
        meeting.forEach(element => {
          setMeetingAction(e => [
            ...e,
            element.topics.length > 0
              ? element.topics
                .map(({ actions }) => actions)
                .map(e => e.length)
                .reduce((a, b) => a + b, 0)
              : 0
          ])
          setMeetingActionAFazer(e => [
            ...e,
            element.topics.length > 0
              ? element.topics
                .map(({ actions }) => actions)
                .map((e) =>
                  e.map(({ statusId }) => statusId)
                    .filter(e => e === 1).length
                ).reduce((a, b) => a + b, 0)
              : 0
          ])
          setMeetingActionPausado(e => [
            ...e,
            element.topics.length > 0
              ? element.topics
                .map(({ actions }) => actions)
                .map((e) =>
                  e.map(({ statusId }) => statusId)
                    .filter(e => e === 2).length
                ).reduce((a, b) => a + b, 0)
              : 0
          ])
          setMeetingActionFazendo(e => [
            ...e,
            element.topics.length > 0
              ? element.topics
                .map(({ actions }) => actions)
                .map((e) =>
                  e.map(({ statusId }) => statusId)
                    .filter(e => e === 3).length
                ).reduce((a, b) => a + b, 0)
              : 0
          ])
          setMeetingActionVencido(
            e => [
              ...e,
              element.topics.length > 0
                ? element.topics
                  .map(({ actions }) => actions)
                  .map((e) =>
                    e.map(({ statusId }) => statusId)
                      .filter(e => e === 4).length
                  ).reduce((a, b) => a + b, 0)
                : 0
            ])
          setMeetingActionRealizado(
            e => [
              ...e,
              element.topics.length > 0
                ? element.topics
                  .map(({ actions }) => actions)
                  .map((e) =>
                    e.map(({ statusId }) => statusId)
                      .filter(e => e === 5).length
                  ).reduce((a, b) => a + b, 0)
                : 0
            ])
          setMeetingActionRevisar(
            e => [
              ...e,
              element.topics.length > 0
                ? element.topics
                  .map(({ actions }) => actions)
                  .map((e) =>
                    e.map(({ statusId }) => statusId)
                      .filter(e => e === 6).length
                  ).reduce((a, b) => a + b, 0)
                : 0
            ])
        })

        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [adminTotal, aditionalQuery, paginationSkip, adminLocal, profile.companyId, profile.id])

  useEffect(() => {
    getMeetings()
  }, [getMeetings])

  return (
    <Container className='content mt-4 row'>
      {loading && <Loading />}
      <ContainerCard className="col">
        <ContainerCardHeader>
          <form className="row" onSubmit={handleSubmit(onSubmitFilter)}>
            <Col xl='3' lg='3'>
              <Button
                type="button"
                name="btnTodasReunioes"
                onClick={handleRedirectToAllActions}
                className="btn-default mb-2 form-control btn"
                title="Todas ações"
              />
            </Col>
            <Controller
              name="meetingTitle"
              control={control}
              defaultValue=""
              render={({ field }) =>
                <InputIcon
                  id={field.name}
                  xl='9'
                  lg='9'
                  type='text'
                  className='float-right'
                  onChange={field.onChange}
                  name={field.name}
                  icon={<FaSearch />}
                  value={field.value}
                />}
            />
          </form>
        </ContainerCardHeader>
        <ContainerCardBody className="row">
          {meeting.map((item, index) => {
            return (
              <ColumnCard key={index} className="col-xl-3 col-lg-4 col-md-6">
                <ColumnCardBody>
                  <ContainerColumn>
                    <ColumnContent>
                      <ColumnWrapperContent>
                        <ContentCard key={item.id} onClick={() => handleRedirectToActions(item)}>
                          <ContentCardHeader>
                            <i style={{ backgroundColor: item.color }}></i>
                            <small>
                              {item.title.value}
                            </small>
                          </ContentCardHeader>
                          <Content>
                            <ContentProgress>
                              <ProgressBar>
                                <ProgressBar
                                  key={1}
                                  variant='success'
                                  now={
                                    meetingActionRealizado[index]
                                    /
                                    meetingAction[index] * 100
                                  } />
                                <ProgressBar
                                  key={2}
                                  variant='danger'
                                  now={
                                    meetingActionVencido[index]
                                    /
                                    meetingAction[index] * 100
                                  } />
                              </ProgressBar>
                            </ContentProgress>
                            <span
                              style={{
                                color: '#28a745'
                              }}>
                              {`${meetingActionRealizado[index]} finalizada`}
                            </span>

                            <span
                              style={{
                                color: '#dc3545'
                              }}>
                              {`${meetingActionVencido[index]} vencida`}
                            </span>

                            <span>
                              {meetingActionAFazer[index]} a fazer
                            </span>

                            <span>
                              {meetingActionFazendo[index]} fazendo
                            </span>

                            <span>
                              {meetingActionPausado[index]} pausado
                            </span>

                            <span>
                              {meetingActionRevisar[index]} a revisar
                            </span>
                          </Content>
                          <ContentCardBottom>
                            <small className='text-muted'>
                              {item.participants.length + ' membros'}
                            </small>
                            {item.participants.length > 4 && (
                              <Avatar
                                color="#999"
                                className='float-right'
                                value={`+${item.participants.length - 4} `}
                                size='25px'
                                textSizeRatio={1.5}
                                round />)}
                            {item.participants.map(({ user }) => user).slice(0, 5).map((e) => {
                              return (
                                <Avatar
                                  color="#999"
                                  title={e.name}
                                  name={e.name}
                                  key={e.id}
                                  className='float-right'
                                  src={e.logoLink}
                                  style={{
                                    marginLeft:
                                      item.participants.length > 3
                                        ? '-4px'
                                        : '.4em'
                                  }}
                                  textSizeRatio={2}
                                  maxInitials={2}
                                  size='25px'
                                  round />
                              )
                            })}
                          </ContentCardBottom>
                        </ContentCard>
                      </ColumnWrapperContent>
                    </ColumnContent>
                  </ContainerColumn>
                </ColumnCardBody>
              </ColumnCard>)
          })}
          <Col xl="12" lg="12" className="d-flex justify-content-center align-items-center">
            <Button
              type="button"
              name="btnVoltar"
              onClick={() => {
                setPaginationSkip(e => e - 12)
              }}
              className="btn-default btn"
              disabled={paginationSkip < 12}
              title={<FaArrowLeft />}
            />
            <Button
              type="button"
              name="btnAvançar"
              onClick={() => {
                setPaginationSkip(e => e + 12)
              }}
              className="btn-default btn"
              title={<FaArrowRight />}
            />
          </Col>
        </ContainerCardBody>
      </ContainerCard>
    </Container >
  )
}
