import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from '~/services/api'
import * as types from '../types'

import {
  profileFailure,
  getUsersSuccess,
  createProfileSuccess
} from './actions'
// import history from '~/services/history'

function sendFile(file) {
  return api.post('upload', file,
    {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${file._boundary}`,
      },
    }
  )
}

export function* createProfile({ payload }) {
  try {
    const { id, file } = payload.data
    let link
    if (file.has('file'))
      link = yield call(sendFile, file)
    if (!id) {
      yield call(
        api.post,
        'user/collaborator',
        link ?
          { ...payload.data, logoLink: link.data.url }
          : payload.data
      )
      toast.success('Usuário cadastrado com sucesso!')
    } else {
      yield call(
        api.put,
        `user/${id}`,
        link ?
          { ...payload.data, logoLink: link.data.url }
          : payload.data
      )
      toast.success('Usuário atualizado com sucesso!')
    }
    yield put(createProfileSuccess())
  } catch (error) {
    yield put(profileFailure())
    if (error.response)
      if (Array.isArray(error.response.data.message)) {
        error.response.data?.message?.forEach(e => {
          toast.error(e)
        })
      } else {
        toast.error(error.response.data?.message)
      }
    else
      toast.error("Erro interno.")
  }
}
export function* getUsers({ payload }) {
  try {
    const response = payload.data
      ? yield call(api.get, `user?include.role&&where.companyId=${payload.data.companyId}`)
      : yield call(api.get, `user?include.role&&include.company`)
    yield put(getUsersSuccess(response.data))
  } catch (e) {
    yield put(profileFailure())
    toast.error("Erro interno.")
  }
}
export default all([
  takeLatest(types.CREATE_PROFILE_REQUEST, createProfile),
  takeLatest(types.GET_USERS_REQUEST, getUsers),
])
