import styled from "styled-components"

export const ContainerHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
`

export const ContainerDate = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 18px 0;
  small{
    text-align: center;
  }
`
export const ContainerMeeting = styled.div`
  .td-meeting {
    cursor: pointer;
    .row{
      align-items: center;
    }
    i {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-left: 2px;
    }
    strong {
      color: #23527c;
      font-size: 14px;
      padding: 0px;
      margin: 0px;
    }
  }
  th.sortable div {
    display: inline;
    /* i {
      &.up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;

        border-bottom: 5px solid black;
      }
      &.down {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;

        border-top: 20px solid #f00;
      }
      &.active{
        border-color: red;
      }
      margin-left: 5px;
      margin-right: 2px;
    } */
  }
`
