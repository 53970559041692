import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import AuthLayout from '~/_layouts/auth'

import DefaultLayout from '~/_layouts/default'
import { useSelector } from 'react-redux'
import FirstLogin from '../pages/FirstLogin'
import InactiveUser from '~/pages/InactiveUser'
import CompanySelect from '~/pages/CompanySelect'

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  role = [],
  ...rest
}) {
  const { signed, profile, companySelected } = useSelector(state => state.auth)

  const internalMeeting = profile?.permissions?.map(e => e.id).includes(1)
  const externalMeeting = profile?.permissions?.map(e => e.id).includes(2)

  const Layout =
    signed
      && profile.loggedFirstTime
      && (companySelected
        || JSON.parse(profile?.companies ?? '[]').length <= 1)
      ? DefaultLayout
      : AuthLayout

  if (!signed && isPrivate) {
    return <Redirect to="/" />
  }

  if (signed && !profile.active) {
    return (
      <Route
        {...rest}
        render={() => (
          <Layout>
            <InactiveUser />
          </Layout>
        )}
      />
    )
  }

  if (signed && profile && !companySelected && JSON.parse(profile?.companies ?? '[]').length > 1)
    return (
      <Route
        {...rest}
        render={() => (
          <Layout>
            <CompanySelect />
          </Layout>
        )}
      />
    )

  if (
    signed &&
    !isPrivate && profile.loggedFirstTime &&
    (!role.includes(profile.roleId) || internalMeeting || externalMeeting)
  ) {
    return <Redirect to="/home" />
  }

  if (signed && !isPrivate && profile.loggedFirstTime) {
    return <Redirect to="/home" />
  }

  if (signed && profile && !profile.loggedFirstTime) {
    return <Route
      {...rest}
      render={props => (
        <Layout>
          <FirstLogin />
        </Layout>
      )}
    />
  }

  // if (profile) {
  //   if (
  //     !permissions.includes(profile.roleId)
  //   ) {
  //     return <Redirect to="/home" />;
  //   }
  // }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  role: PropTypes.array,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}
