import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, Controller } from "react-hook-form"

import { toast } from 'react-toastify'

import { Markup } from 'interweave'

// components
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Button from '~/components/Form/Button'
import Loading from '~/components/Loading'
import Select from '~/components/Form/Select'

// services
import api from '~/services/api'
import { format, formatDistanceToNow, isBefore } from 'date-fns'
import { createActionRequest } from '~/store/modules/action/actions'
import { ptBR } from 'date-fns/locale'
import { CardComment, CardFile, Comment, CommentContent, CommentHeader, ContainerComment, FormFile } from './styles'
import Col from '~/components/Bootstrap/Col'
import { Editor } from '@tinymce/tinymce-react'
import Avatar from 'react-avatar'
import { FaPaperclip, FaTrash } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import SwitchButton from '~/components/Form/SwitchButton'
import Header from '~/components/Header'
import { Card, CardBody } from '~/components/Card'
import history from '~/services/history'

export default function Action({
  // deleting,
  actionId,
  meetingId }) {
  const { profile } = useSelector(state => state.auth)

  const { loading: loadingAction } = useSelector(state => state.action)

  const dispatch = useDispatch()

  const { control, handleSubmit, setValue, register, reset, watch } = useForm()

  const [createdAt, setCreatedAt] = useState(null)


  const [prioridadeOptions, setPrioridadeOptions] = useState([])
  const [usuarioOptions, setUsuarioOptions] = useState([])
  const [todosUsuarioOptions, setTodosUsuariosOptions] = useState([])

  const [statusOptions, setStatusOptions] = useState([])
  const [topicsOptions, setTopicsOptions] = useState([])

  const [loadingActions, setLoadingActions] = useState(false)
  const [loadingStatusOptions, setLoadingStatusOptions] = useState(false)
  const [loadingFile, setLoadingFile] = useState(false)
  const [loadingComment, setLoadingComment] = useState(false)
  const [loadingPriority, setLoadingPriority] = useState(false)
  const [loadingUser, setLoadingUser] = useState(false)
  const [loadingMeeting, setLoadingMeeting] = useState(false)


  const file = watch('file')

  const [action, setAction] = useState(null)
  const [meetingTitle, setMeetingTitle] = useState(false)
  const [meetingActionId, setMeetingActionId] = useState(null)

  const [creator, setCreator] = useState(null)
  const [monitors, setMonitors] = useState(null)

  const [deadline, setDeadline] = useState(null)
  const [extendedDeadline, setExtendedDeadline] = useState(null)

  const [actionComments, setActionComments] = useState(null)
  const [actionCommentValue, setActionCommentValue] = useState('')


  const [arquivos, setArquivos] = useState(null)

  const [abaComentarios, setAbaComentarios] = useState(true)
  const [abaArquivos, setAbaArquivos] = useState(false)
  const [abaConvidados, setAbaConvidados] = useState(false)


  const adminTotal = profile.roleId === 1

  const onSubmit = data => {
    if (!data.actionDescription) {
      toast.error('Adicione uma descrição.')
    } else if (!data.actionPriority) {
      toast.error('Adicione uma prioridade.')
    } else if (!data.actionTopic?.value) {
      toast.error('Referencie a ação à alguma pauta.')
    } else if (!data.actionResponsible || !data.actionResponsible.length) {
      toast.error('Adicione um responsável pela entrega.')
    } else if (!data.actionDeadlineDate) {
      toast.error('Digite um prazo para concluir esta ação.')
    } else {
      const dateDeadline = new Date(data.actionDeadlineDate).toISOString()
      const maiorData = extendedDeadline ?? deadline
      const jaVenceu = isBefore(new Date(maiorData), new Date())
      let regex = /<p>|(&nbsp;\s)+|(&nbsp;)+|<\/p>/g
      let comment = ''
      if (jaVenceu && data.id && action?.statusId !== 5) {
        if (data.actionStatus?.value === 6)
          comment = "<p>Alterei o status da atividade vencida para revisar.</p>"
        else if (data.actionStatus?.value === 5)
          comment =
            `<p>Finalizei a atividade que estava vencida em `
            + `${format(
              new Date(maiorData),
              'dd/MM/yyyy - HH:mm',
              { locale: ptBR }
            )}.</p>`

        setLoadingComment(true)
        api.post('comment', {
          userId: profile.id,
          actionId: actionId,
          value: comment
        })
          .then(() => setLoadingComment(false))
          .catch(() => setLoadingComment(false))
      }
      dispatch(
        createActionRequest({
          id: data.id,
          name: data.actionName,
          description: data.actionDescription,
          deadline: !deadline && data.actionDeadlineDate
            ? dateDeadline
            : undefined,
          extendedDeadline: deadline && (deadline !== dateDeadline) && data.actionDeadlineDate
            ? dateDeadline
            : undefined,
          priorityId: data.actionPriority.value,
          responsibleUserIds: data.actionResponsible.map(e => e.value),
          topicId: data.actionTopic?.value,
          statusId: data.actionStatus?.value,
          activeAlert: data.activeAlert,
          comment:
            (actionCommentValue.replace(regex, '').trim() !== ''
              ? actionCommentValue
              : undefined),
          invitedUserIds: data.actionInviteds ? data.actionInviteds.map(e => e.value) : []
        })
      )
      /*
      Usuário x finalizou a atividade na data X que estava vencida desde a data Y
      Usuário Y alterou o status da atividade de vencida para revisar na data Z */
      setActionCommentValue('')
      if (!actionId)
        reset({
          actionResponsible: null,
          actionPriority: null,
          actionTopic: null
        })
    }
  }

  function handleRedirectToMeeting() {
    history.push('/reuniao', {
      id: meetingActionId,
      cadastrar: true
    })
  }

  useEffect(() => {
    function getPriority() {
      setLoadingPriority(true)
      api.get('priority').then((e) => {
        const parseResp = e.data.map(({ id, label }) => {
          return {
            value: id,
            label
          }
        })
        setLoadingPriority(false)
        setPrioridadeOptions(parseResp)
      }).catch((error) => {
        setLoadingPriority(false)
        toast.error('Erro ao carregar prioridades.')
      })
    }
    function getUsers() {
      setLoadingUser(true)
      api.get('user?include.company=true')
        .then((e) => {
          const parseResp = e.data.map(({ id, name, email, logoLink, company }) => {
            return {
              value: id,
              label: name + ' | ' + email,
              email,
              name,
              logoLink,
              company
            }
          })
          setLoadingUser(false)
          setTodosUsuariosOptions(parseResp)
        }).catch((error) => {
          setLoadingUser(false)
          toast.error('Erro ao carregar usuários.')
        })
    }
    function getStatusOptions() {
      setLoadingStatusOptions(true)
      api.get('action-item-status').then((e) => {
        const parseResp = e.data.map(({ id, name }) => {
          return {
            value: id,
            label: name
          }
        })
        setLoadingStatusOptions(false)
        setStatusOptions(parseResp.filter(({ value }) => value !== 4))
      }).catch(() => {
        setLoadingStatusOptions(false)
        toast.error('Erro ao carregar status.')
      })
    }
    getPriority()
    getUsers()
    getStatusOptions()
  }, [setValue, actionId, meetingId, action?.topic.meetingId, meetingActionId])

  useEffect(() => {
    function getMeeting() {
      setLoadingMeeting(true)
      api.get(`meeting/${meetingActionId ? meetingActionId : meetingId}?include.participants.include.user.include.company=true&&include.title=true`)
        .then(({ data: loadMeeting }) => {
          const parseParticipantes =
            loadMeeting.participants
              .map(({ user }) => user)
              .map(({ id, name, email, logoLink, company }) => {
                return {
                  value: id,
                  label: name + ' | ' + email,
                  email,
                  name,
                  logoLink,
                  company
                }
              })
          setLoadingMeeting(false)
          setMeetingTitle(loadMeeting.title.value)
          setUsuarioOptions(parseParticipantes)
        }).catch(() => {
          setLoadingMeeting(false)
          toast.error('Erro ao carregar reunião.')
        })

    }
    function getTopics() {
      // setLoadingTopic(true)
      api.get(`topic?where.meetingId=${meetingId ?? meetingActionId}`)
        .then(({ data: topic }) => {
          // setLoadingTopic(false)
          setTopicsOptions(topic.map(({ id, agendaTopic }) => {
            return {
              value: id,
              label: agendaTopic
            }
          }))
        })
        .catch(e => {
          // setLoadingTopic(false)
          toast.error('Erro ao carregar pautas.')
        })
    }
    if (!!meetingId || !!meetingActionId) {
      getTopics()
      getMeeting()
    }
  }, [meetingActionId, meetingId])

  useEffect(() => {
    function loadAction() {
      setLoadingActions(true)
      api
        .get(`action/${actionId}?include.topic.include.monitors&&include.inviteds.include.user.include.company=true&&include.priority=true&&include.status=true&&include.topic.include.meeting.include.participants.include.user.include.company=true&&include.responsibles.include.user.include.company=true&&include.topic.include.meeting.include.convener=true&&include.deadline.include.dateMarking=true&&include.extendedDeadline.include.dateMarking=true`)
        .then(({ data: loadAction }) => {
          setCreator(loadAction.topic.meeting.convener.userId)
          setExtendedDeadline(loadAction.extendedDeadline ? loadAction.extendedDeadline.dateMarking.date : null)
          setDeadline(loadAction.deadline
            ? loadAction.deadline.dateMarking.date
            : null)
          // console.log(loadAction.topic.monitors?.map(({ userId }) => userId))
          setMonitors(loadAction.topic.monitors?.map(({ userId }) => userId))
          setCreatedAt(loadAction.createdAt)
          setValue('id', loadAction.id)
          setValue('actionDescription', loadAction.description)
          setValue('actionPriority', { value: loadAction.priority.id, label: loadAction.priority.label })
          setValue('actionStatus', { value: loadAction.status.id, label: loadAction.status.name })
          setValue('actionTopic', {
            value: loadAction.topic.id,
            label: loadAction.topic.agendaTopic
          })
          if (!loadAction.extendedDeadline) {
            setValue('actionDeadlineDate', loadAction.deadline
              ? format(new Date(loadAction.deadline.dateMarking.date), "yyyy-MM-dd'T'HH:mm", { locale: ptBR })
              : '')
          } else {
            setValue('actionDeadlineDate',
              format(new Date(loadAction.extendedDeadline.dateMarking.date), "yyyy-MM-dd'T'HH:mm", { locale: ptBR })
            )
          }
          setUsuarioOptions(
            loadAction.topic.meeting.participants
              .map(({ user }) => user)
              .map(({ id, name, email, logoLink, company }) => {
                return {
                  value: id,
                  label: name + ' | ' + email,
                  email,
                  name,
                  logoLink,
                  company
                }
              })
          )
          setValue('actionInviteds',
            loadAction.inviteds.map(({ user }) => user)
              .map(({ id, name, email, logoLink, company }) => {
                return {
                  value: id,
                  label: name + ' | ' + email,
                  email,
                  name,
                  logoLink,
                  company
                }
              })
          )
          setMeetingActionId(loadAction.topic.meetingId)
          setValue('actionResponsible', loadAction.responsibles
            .map(({ user }) => user)
            .map(({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                email,
                name,
                logoLink,
                company
              }
            }))
          setValue('activeAlert', loadAction.activeAlert)
          setAction(loadAction)
          api.get(`topic?where.meetingId=${loadAction.topic.meetingId}`)
            .then(({ data: topic }) => {
              setTopicsOptions(
                topic.map(({ id, agendaTopic }) => {
                  return {
                    value: id,
                    label: agendaTopic
                  }
                })
              )
            })
            .catch(e => {
              toast.error('Erro ao carregar pautas.')
            })
          setLoadingActions(false)
        }).catch(() => {
          setLoadingActions(false)
        })
    }
    if (actionId)
      loadAction()
  }, [actionId, setValue])

  const getActionComments = useCallback(() => {
    if (actionId) {
      // setLoadingComment(true)
      api
        .get(`comment?where.actionId=${actionId}&&include.user=true&&orderBy.createdAt=asc`)
        .then(({ data: comment }) => {
          setActionComments(comment)
          // setLoadingComment(false)
        })
        .catch(e => {
          // setLoadingComment(false)
        })
    }
  }, [actionId])

  useEffect(() => {
    getActionComments()
  }, [getActionComments])

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  useInterval(getActionComments, 6000)

  function handleDeletarArquivo(item) {
    confirmAlert({
      title: 'Exclusão',
      message:
        'Deseja realmente excluir permanentemente esse arquivo? Essa ação não poderá ser desfeita.',
      buttons: [
        {
          label: 'Sim. Eu desejo.',
          onClick: () => {
            setLoadingFile(true)
            api.delete(`attachment/${item.id}`).then(() => {
              toast.success(`Arquivo ${item.name} deletado com sucesso.`)
              setLoadingFile(false)
              getFiles()
            }).catch(() => {
              setLoadingFile(false)
              toast.error('Erro ao deletar arquivo.')
            })
          },
        },
        {
          label: 'Cancelar essa ação.',
          onClick: () => { },
        },
      ],
    })
  }

  const getFiles = useCallback(() => {
    if (actionId) {
      setLoadingFile(true)
      api.get(`attachment?include.user=true&&where.actionId=${actionId}&&orderBy.createdAt=asc`)
        .then(({ data: file }) => {
          setArquivos(file)
          setLoadingFile(false)
        })
        .catch(e => {
          setLoadingFile(false)
        })
    }
  }, [actionId])

  const handleSendFile = useCallback(() => {
    const formData = new FormData()
    if (file?.length > 0) {
      formData.append('file', file[0])
      setLoadingFile(true)
      api.post('upload', formData,
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          },
        }
      ).then(({ data: dataLink }) => {
        api.post('attachment', {
          url: dataLink.url,
          userId: profile.id,
          name: file[0].name,
          size: file[0].size,
          extension: file[0].type,
          actionId: actionId
        }).then(() => {
          setLoadingFile(false)
          toast.success('Arquivo enviado com sucesso.')
          getFiles()
          setValue('file', null)
        }).catch(() => {
          toast.error('Falha ao enviar arquivo.')
          setLoadingFile(false)
        })
      }).catch(() => {
        setLoadingFile(false)
      })
    }
  }, [actionId, file, getFiles, profile.id, setValue])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  function handleDeleteComment(item) {
    let canDelete = true
    if (item.value.includes("Alterei o status da atividade vencida para revisar."))
      canDelete = false
    else if (item.value.includes("Finalizei a atividade que estava vencida em"))
      canDelete = false
    confirmAlert({
      title: 'Exclusão',
      message:
        canDelete
          ? 'Deseja realmente excluir permanentemente esse comentário? Essa ação não poderá ser desfeita.'
          : 'Você não pode deletar esse tipo de comentário.',
      buttons: canDelete ? [
        {
          label: 'Sim. Eu desejo.',
          onClick: () => {
            setLoadingActions(true)
            api.delete(`comment/${item.id}`).then(() => {
              toast.success(`Comentário deletado com sucesso.`)
              setLoadingActions(false)
              getActionComments()
            }).catch(() => {
              setLoadingActions(false)
              toast.error('Erro ao deletar comentário.')
            })
          },
        },
        {
          label: 'Cancelar essa ação.',
          onClick: () => { },
        },
      ] : [{
        label: 'Entendido.',
        onClick: () => { },
        className: 'justify-content-center'
      },],
    })
  }


  useEffect(() => {
    handleSendFile()
  }, [handleSendFile])

  useEffect(() => {
    getFiles()
  }, [getFiles])

  // useEffect(() => {
  //   if(deleting)
  //     setLoadingActions(true)
  //   else(setLo)
  // })

  return (
    <>
      {(loadingAction || loadingUser || loadingActions || loadingMeeting ||
        loadingPriority || loadingStatusOptions || loadingFile || loadingComment
      ) && <Loading card={true} />}
      {!(loadingAction || loadingUser || loadingActions || loadingMeeting ||
        loadingPriority || loadingStatusOptions || loadingFile || loadingComment
      ) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {!!actionId && (
              <Row className='mb-4'>
                <Col xl='12' lg='12'>
                  <small className='text-muted'>
                    {'Criada em ' + format(new Date(createdAt), "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                      { locale: ptBR }
                    )}
                  </small>
                </Col>
              </Row>
            )}
            <input hidden {...register("id")} />
            <Row>
              <Controller
                name="actionDescription"
                control={control}
                defaultValue=""
                render={({ field }) =>
                  <Input
                    label="Descrição"
                    xl='8'
                    lg='8'
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    type="text"
                    disabled={!!actionId && (creator !== profile.id) && !adminTotal}
                  />}
              />
              <Controller
                name="actionDeadlineDate"
                control={control}
                defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm", { locale: ptBR })}
                render={({ field }) =>
                  <Input
                    xl="4"
                    lg="4"
                    label={extendedDeadline
                      ? `Prazo inicial ${format(new Date(deadline), "dd/MM/yyyy - HH:mm", { locale: ptBR })}`
                      : 'Prazo'}
                    id={field.name}
                    type="datetime-local"
                    // min={format(new Date(), "yyyy-MM-dd'T'HH:mm", { locale: ptBR })}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={!!actionId && (creator !== profile.id) && !adminTotal}
                  />}
              />
            </Row>
            <Row>
              <Controller
                name="actionTopic"
                control={control}
                defaultValue={null}
                render={({ field }) =>
                  <Select
                    label='Pauta'
                    value={field.value}
                    handleChangeValue={field.onChange}
                    name={field.name}
                    options={topicsOptions}
                    disabled={!!actionId && (creator !== profile.id) && !adminTotal}
                  />}
              />
              <Controller
                name="actionResponsible"
                control={control}
                defaultValue={null}
                render={({ field }) =>
                  <Select
                    label='Responsáveis'
                    value={field.value}
                    handleChangeValue={field.onChange}
                    name={field.name}
                    multiOptions
                    options={usuarioOptions}
                    disabled={!!actionId && (creator !== profile.id) && !adminTotal}
                    style={{
                      multiValue: styles => ({
                        ...styles,
                        background: "transparent"
                      }),
                      multiValueRemove: styles => ({
                        ...styles,
                        ":hover": {
                          background: 'none',
                          color: '#DE350B'
                        }
                      }),
                      menu: styles => ({
                        ...styles,
                        zIndex: '2'
                      })
                    }}
                    formatOptionLabel={(user) => (
                      <div>
                        <Avatar
                          color="#999"
                          title={
                            user.name
                            + ' | '
                            + user.email
                          }
                          key={user.id}
                          name={user.name}
                          src={user.logoLink}
                          maxInitials={2}
                          style={{
                            marginRight: '0.4em'
                          }}
                          size='32px'
                          round />
                        <span title={user.email + ' | ' + user.company.fantasyName}>
                          {user.name.split(' ')[0]}
                        </span>
                      </div>
                    )}
                  />}
              />
            </Row>
            <Row>
              <Controller
                name="actionPriority"
                control={control}
                defaultValue={null}
                render={({ field }) =>
                  <Select
                    label='Prioridade'
                    value={field.value}
                    handleChangeValue={field.onChange}
                    name={field.name}
                    options={prioridadeOptions}
                    disabled={!!actionId && (creator !== profile.id) && !adminTotal}
                  />}
              />
              <Controller
                name="actionStatus"
                control={control}
                defaultValue={{ value: 1, label: 'A fazer' }}
                render={({ field }) =>
                  <Select
                    label='Status'
                    value={field.value}
                    handleChangeValue={field.onChange}
                    name={field.name}
                    disabled={!actionId}
                    options={
                      ((monitors?.includes(profile.id)) || (creator === profile.id) || adminTotal)
                        ? statusOptions
                        : statusOptions.filter(({ value }) => value !== 5)
                    }
                  />}
              />
            </Row>
            {!!actionId && (
              <>
                <Row>
                  <Controller
                    name="activeAlert"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <SwitchButton
                        label="Alertas de vencimento"
                        id={field.name}
                        name={field.name}
                        onChange={(name, val) => {
                          field.onChange(val)
                        }}
                        size="md"
                        checked={field.value}
                      />
                    )}
                  />
                  <div className="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                    <div className="form-group d-flex flex-column">
                      <label htmlFor='meetingTitle'>Reunião</label>
                      <strong
                        name='meetingTitle'
                        id='meetingTitle'
                        className='text-muted'
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={
                          (creator === profile.id) || adminTotal || usuarioOptions.map(({ value }) => value).includes(profile.id) ? handleRedirectToMeeting : () => { }}>
                        {meetingTitle}
                      </strong>
                      {/* {console.log(usuar)} */}
                    </div>
                  </div>
                </Row>
              </>
            )}
            {!!actionId && (
              <Row>
                <div className="row mb-4 mt-4 d-flex align-items-center ml-2 w-100">
                  <div className='col'>
                    <Header
                      title="Comentários"
                      ativo={abaComentarios}
                      onClick={() => {
                        setAbaArquivos(false)
                        setAbaComentarios(true)
                        setAbaConvidados(false)
                      }}>
                    </Header>
                    <Header
                      title="Arquivos"
                      ativo={abaArquivos}
                      onClick={() => {
                        setAbaArquivos(true)
                        setAbaComentarios(false)
                        setAbaConvidados(false)
                      }}>
                    </Header>
                    <Header
                      title="Convidados"
                      ativo={abaConvidados}
                      onClick={() => {
                        setAbaArquivos(false)
                        setAbaComentarios(false)
                        setAbaConvidados(true)
                      }}>
                    </Header>
                  </div>
                  {abaArquivos && (
                    <FormFile className='col d-flex justify-content-end'>
                      <label
                        title="Clique para enviar o arquivo"
                        htmlFor="file"
                        className="form-control"
                      >
                        Enviar arquivo <FaPaperclip />
                      </label>
                      <input
                        id="file"
                        type="file"
                        {...register('file')}
                      />
                    </FormFile>)}
                </div>
              </Row>)}
            {abaConvidados && (
              <Row>
                <Controller
                  name="actionInviteds"
                  control={control}
                  defaultValue={null}
                  render={({ field }) =>
                    <Select
                      label='Convidados'
                      value={field.value}
                      handleChangeValue={field.onChange}
                      name={field.name}
                      xl='12'
                      lg='12'
                      multiOptions
                      options={todosUsuarioOptions}
                      style={{
                        multiValue: styles => ({
                          ...styles,
                          background: "transparent"
                        }),
                        multiValueRemove: styles => ({
                          ...styles,
                          ":hover": {
                            background: 'none',
                            color: '#DE350B'
                          }
                        }),
                        menu: styles => ({
                          ...styles,
                          zIndex: '2'
                        })
                      }}
                      formatOptionLabel={(user) => (
                        <div>
                          <Avatar
                            color="#999"
                            title={
                              user.name
                              + ' | '
                              + user.email
                            }
                            key={user.id}
                            name={user.name}
                            src={user.logoLink}
                            maxInitials={2}
                            style={{
                              marginRight: '0.4em'
                            }}
                            size='32px'
                            round />
                          <span title={user.email + ' | ' + user.company.fantasyName}>
                            {user.name.split(' ')[0]}
                          </span>
                        </div>
                      )}
                    />}
                />
              </Row>
            )}
            {abaComentarios && (
              <>
                <Row>
                  <Col xl='12' lg='12'>
                    <CardComment>
                      {actionComments && actionComments.map((e) => {
                        return (
                          <Comment key={e.id} className='row'>
                            <Col lg='12' xl='12'>
                              <>
                                <CommentHeader className="float-left">
                                  <Avatar
                                    color="#999"
                                    name={e.user.name}
                                    size='34px'
                                    src={e.user.logoLink}
                                    style={{
                                      marginRight: '.4em'
                                    }}
                                    maxInitials={2}
                                    title={e.user.name}
                                    round />
                                </CommentHeader>
                                <Row>
                                  <Col xl='8' lg='8' md='8' sm='8' col='8'>
                                    <strong className="text-wrap">
                                      {e.user.name}
                                    </strong>
                                  </Col>
                                  <Col xl='4' lg='4' md='4' sm='4' col='4'>
                                    <small className="text-muted float-right">
                                      {formatDistanceToNow(
                                        new Date(e.createdAt),
                                        {
                                          locale: ptBR
                                        }
                                      )}
                                    </small>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xl='12' lg='12'>
                                    <small className="text-muted text-wrap">
                                      {format(
                                        new Date(e.createdAt),
                                        "dd 'de' MMMM 'de' yyyy HH:mm",
                                        {
                                          locale: ptBR
                                        }
                                      )}
                                      &nbsp;
                                    </small>
                                    <FaTrash
                                      size='12px'
                                      title="Deletar comentário"
                                      color="#f59384" style={{
                                        cursor: 'pointer'
                                      }}
                                      onClick={() => handleDeleteComment(e)}
                                    />
                                  </Col>
                                </Row>
                                <CommentContent className="row mb-2">
                                  <Col xl='12' lg='12'>
                                    <Markup content={e.value} />
                                  </Col>
                                </CommentContent>
                              </>
                            </Col>
                          </Comment>
                        )
                      })}
                    </CardComment>
                  </Col>
                </Row>
                <Row>
                  <ContainerComment className="col">
                    <Controller
                      name="actionComment"
                      control={control}
                      defaultValue=''
                      render={({ field }) =>
                        <Editor
                          apiKey="478hxhqnrft0a0o3ycsfagcd280purqk32seb710lryi22oz"
                          // tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce.min.js'}
                          value={actionCommentValue}
                          plugins={['autoresize']}
                          onEditorChange={(e) => setActionCommentValue(e)}
                          init={{
                            visual: false,
                            height: 500,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                            language: 'pt_BR',
                            name: `${field.name}`,
                            statusbar: false
                          }}
                        />
                      }
                    />
                  </ContainerComment>
                </Row>
              </>)}
            {!!actionId && abaArquivos && (
              <Row>
                <Col xl='12' lg='12'>
                  <CardFile>
                    {arquivos && (
                      arquivos.map(e => {
                        return (
                          <Row key={e.id}>
                            <Col xl='12' lg='12'>
                              <Card>
                                <CardBody className="d-flex align-items-center row">
                                  <Col xl='1' lg='1' sm='1' md='1'>
                                    <Avatar
                                      color="#999"
                                      title={e.user.name}
                                      key={e.user.id}
                                      name={e.user.name}
                                      maxInitials={2}
                                      src={e.user.logoLink}
                                      style={{
                                        marginRight: '0.4em'
                                      }}
                                      size='32px'
                                      round />
                                  </Col>
                                  <Col className="d-flex flex-column" xl='10' lg='10' sm='10' md='10'>
                                    <>
                                      <span className="text-wrap">
                                        <a href={e.url} target="_blank" rel="noreferrer">
                                          {e.name}
                                        </a>
                                      </span>
                                      <small className="text-muted">
                                        {formatBytes(e.size * 1.024)}
                                      </small>
                                      <small className="text-muted text-wrap">
                                        {format(Date.parse(e.createdAt),
                                          "'Enviado em ' EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm",
                                          { locale: ptBR })}
                                        {` por ${e.user.name}`}
                                      </small>
                                    </>
                                  </Col>
                                  <Col xl='1' lg='1' sm='1' md='1'>
                                    <FaTrash
                                      color="#f59384"
                                      title="Deletar"
                                      type="button"
                                      className="float-right"
                                      onClick={() => handleDeletarArquivo(e)} />
                                  </Col>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>)
                      }))}
                  </CardFile>
                </Col>
              </Row>
            )}
            <Button
              type="submit"
              className="btn-default form-control mt-4"
              title={!actionId ? 'Cadastrar ação' : 'Atualizar ação'}
            />
          </form>)
      }
    </>
  )
}

Action.propTypes = {
  actionId: PropTypes.number,
  meetingId: PropTypes.number,
  // deleting: PropTypes.bool,
}

