import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import { toast } from 'react-toastify'

// components
import { Card, CardBody } from '~/components/Card'
import Header from '~/components/Header'
import Description from '~/components/Description'
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Table from '~/components/Table'
import TooltipCustom from '~/components/TooltipCustom'
import Loading from '~/components/Loading'
import Button from '~/components/Form/Button'

// icons
import { FaUpload, FaUser, FaUsers } from 'react-icons/fa'

import { Modal } from 'react-bootstrap'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop from 'react-image-crop'
// actions (reducers)
import { getCompanyRequest, saveCompanyRequest } from '~/store/modules/company/actions'

// services
import history from '~/services/history'
import api from '~/services/api'
// import { cnpjValidate, cpfValidate } from '~/utils/validation'
import Col from '~/components/Bootstrap/Col'
import { FormFile } from './styles'

export default function Company({ location }) {
  const dispatch = useDispatch()

  const { profile } = useSelector((state) => state.auth)
  const { data: companyData, loading: loadingCompany, created } = useSelector((state) => state.company)

  const adminTotal = profile.roleId === 1

  const data = location.state

  const [loading, setLoading] = useState(false)

  const { control, handleSubmit, setValue, register, reset, watch } = useForm()

  // const [logoBool, setLogoBool] = useState(false)

  // const file = watch('file')
  const [profilePic, setProfilePic] = useState(null)
  const [profilePicCropped, setProfilePicCropped] = useState(null)
  const [modalCrop, setModalCrop] = useState(false)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState(null)

  const imgRef = useRef(null)

  const file = watch('file')

  const previewCanvasRef = useRef(null)

  const formData = new FormData()
  async function onSubmit(datas) {
    if (!data?.id && !datas.email)
      toast.error('O email é obrigatório.')
    else if (!data?.id && !datas.password)
      toast.error('A senha é obrigatória.')
    // if (!cpfValidate(datas.cpf)) {
    //   toast.error('O CPF está inválido.')
    // }
    else if (!data?.id && !datas.name) toast.error('O nome do usuário é obrigatório.')
    else if (!data?.id && datas.password !== datas.password2)
      toast.error('As senhas inseridas não estão iguais.')
    else if (!datas.fantasyName)
      toast.error('O nome da empresa é obrigatório.')
    // else if (!logoBool) {
    //   toast.error('A logo da empresa é obrigatória.')
    // }
    //  else if (!cnpjValidate(datas.cnpj)) {
    //   toast.error('O CNPJ está inválido.')
    // }
    // if (!cpfValidate(datas.cpf)) {
    //   toast.error('O CPF está inválido.')
    // }
    else {
      if (profilePicCropped) formData.append('file', profilePicCropped)
      dispatch(
        saveCompanyRequest({
          fantasyName: datas.fantasyName,
          file: formData,
          userAdmin: !data?.id ? {
            name: datas.name,
            email: datas.email,
            password: datas.password,
            permissions: [1, 2]
          } : undefined,
          id: datas.id
        })
      )
    }
  }

  const columns = [
    {
      dataField: 'fantasyName',
      text: 'Nome',
    },
    {
      dataField: 'userAdmin',
      text: 'Administrador',
    },
  ]

  function handleChangeToForm(item) {
    if (adminTotal) {
      history.push('/empresas', {
        id: item ? item.id : null,
        cadastrar: true,
      })
    }
  }

  function handleChangeToList() {
    history.push('/empresas', {
      cadastrar: false,
    })
    dispatch(getCompanyRequest())
    reset()
  }

  useEffect(() => {
    dispatch(getCompanyRequest())
  }, [dispatch, profile.companyId])

  useEffect(() => {
    function loadCompanies() {
      setLoading(true)
      api
        .get(`user?include.company=true&&where.company.id=${data.id}
        ${!adminTotal ? '&&where.roleId=2' : ''}`)
        .then(({ data: loadUser }) => {
          setValue('id', loadUser[0].company.id)
          setValue('fantasyName', loadUser[0].company.fantasyName ? loadUser[0].company.fantasyName : '')
          setValue('name', loadUser[0].name)
          setValue('email', loadUser[0].email)
          // setLogoBool(true)
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
    }
    if (data?.id) loadCompanies()
  }, [adminTotal, data, setValue])

  useEffect(() => {
    if (created) {
      reset()
    }
  }, [created, reset])

  // useEffect(() => {
  //   if (file?.length > 0) {
  //     setLogoBool(true)
  //     toast.success('Foto carregada com sucesso.', { autoClose: 4000 })
  //   }
  // }, [file])

  function getCroppedImg() {
    return new Promise((resolve, reject) => {
      previewCanvasRef.current.toBlob(
        (blob) => {
          blob.name = 'file'
          resolve(blob)
        },
        'image/jpeg',
        1
      )
    })
  }

  async function handleUploadFile() {
    if (
      previewCanvasRef.current.width !== 0 ||
      previewCanvasRef.current.height !== 0
    ) {
      const croppedImage = await getCroppedImg()
      setProfilePicCropped(croppedImage)
    } else setProfilePicCropped(file[0])
    setModalCrop(false)
    toast.success('Foto carregada com sucesso.')
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (file?.length > 0) {
      setModalCrop(true)
      const reader = new FileReader()
      reader.addEventListener('load', () => setProfilePic(reader.result))
      reader.readAsDataURL(file[0])
    }
  }, [file])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
  }, [completedCrop])


  return (
    <>
      {(loading || loadingCompany) && <Loading />}
      {adminTotal && (
        <div className="row mb-2 mt-4 d-flex align-items-center ml-2">
          <Header
            title="Listar empresas"
            ativo={!data?.cadastrar}
            onClick={handleChangeToList}
          />
          <Header
            title={data?.id
              ? 'Atualizar empresa'
              : 'Cadastrar empresa'}
            ativo={!!data?.cadastrar}
            onClick={handleChangeToForm}
          />
          {adminTotal && (
            <TooltipCustom text="Nessa página você tem acesso a visualização, cadastro e edição de empresas" />
          )}
        </div>
      )}
      {adminTotal && !!data?.cadastrar && (
        <div className="content mt-4">
          <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardBody>
                <Description
                  icon={<FaUsers color="#495057" size={30} className="mr-2" />}
                  title="Dados da empresa"
                />
                <Row>
                  <input hidden {...register('id')} />
                  <Controller
                    name="fantasyName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        xl="12"
                        lg="12"
                        label="Nome"
                        id={field.name}
                        type="text"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <Col
                    className="d-flex align-items-center mb-2"
                    col="12"
                    sm="6"
                    md="6"
                  >
                    <FormFile>
                      <label
                        title="O arquivo deve ser em .png ou .jpg ou .jpeg."
                        htmlFor="file"
                        className="btn btn-default form-control"
                      >
                        <FaUpload /> Enviar logo
                      </label>
                      <input
                        id="file"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        {...register('file')}
                      />
                    </FormFile>
                    <Modal
                      size="xl"
                      show={modalCrop}
                      backdrop="static"
                      onHide={() => {
                        setModalCrop(false)
                        setValue('file', null)
                      }}
                    >
                      <Modal.Header>
                        <Modal.Title className="w-100">Ajustar tamanho</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row>
                          <Col>
                            <ReactCrop
                              onImageLoaded={onLoad}
                              src={profilePic}
                              onChange={(newCrop) => setCrop(newCrop)}
                              crop={crop}
                              onComplete={(c) => setCompletedCrop(c)}
                            />
                          </Col>
                          <Col>
                            <div>
                              <canvas
                                ref={previewCanvasRef}
                                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                style={{
                                  width: Math.round(completedCrop?.width ?? 0),
                                  height: Math.round(completedCrop?.height ?? 0),
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Button
                          type="submit"
                          className="btn-default form-control"
                          onClick={handleUploadFile}
                          title="Finalizar"
                        />
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
                <hr />
                {!data?.id && (
                  <>
                    <Description
                      icon={<FaUser color="#495057" size={30} className="mr-2" />}
                      title="Dados do administrador da empresa"
                    />
                    <Row>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            xl="12"
                            lg="12"
                            label="Nome"
                            id={field.name}
                            type="text"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>
                    <Row>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="E-mail"
                            id={field.name}
                            type="email"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="Senha"
                            id={field.name}
                            placeholder="********"
                            type="password"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>
                    <Row>
                      <Controller
                        name="password2"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Input
                            label="Repetir senha"
                            id={field.name}
                            placeholder="********"
                            type="password"
                            name={field.name}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>
                  </>)}
                <Button
                  type="submit"
                  className="btn-default form-control"
                  title={data && data.id ? 'Atualizar' : 'Cadastrar'}
                />
              </CardBody>
            </form>
          </Card>
        </div>
      )}
      {adminTotal && !data?.cadastrar && (
        <div className="content mt-4">
          <Table
            keyField="id"
            data={companyData}
            columns={columns}
            extrasColumns={[
              {
                text: 'Editar',
                className: 'btn btn-sm btn-default',
                onClick: handleChangeToForm,
                buttonText: 'Editar',
              },
            ]}
          />
        </div>
      )}
    </>
  )
}

Company.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      cadastrar: PropTypes.bool,
    }),
  }),
}

Company.defaultProps = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: '',
      cadastrar: false,
    }),
  }),
}
