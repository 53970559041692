import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'

import { toast } from 'react-toastify'

// components
import { Card, CardBody } from '~/components/Card'
import Header from '~/components/Header'
import Description from '~/components/Description'
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Table from '~/components/Table'
import TooltipCustom from '~/components/TooltipCustom'
import Loading from '~/components/Loading'

// icons
import { FaUsers } from 'react-icons/fa'

// actions (reducers)

import {
  createWorkgroupRequest,
  getWorkgroupsRequest,
} from '~/store/modules/workgroup/actions'

// services
import history from '~/services/history'
import api from '~/services/api'
import Button from '~/components/Form/Button'
import Select from '~/components/Form/Select'
import Avatar from 'react-avatar'
import { confirmAlert } from 'react-confirm-alert'
// import { confirmAlert } from 'react-confirm-alert'

export default function Workgroup({ location }) {
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.auth)

  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  const data = location.state

  const {
    data: workgroupsData,
    loading: loadingWorkgroup,
    created,
  } = useSelector((state) => state.workgroup)

  const { control, handleSubmit, setValue, register, reset } = useForm()
  const [usersOptions, setUsersOptions] = useState([])

  const [loading, setLoading] = useState(false)

  async function onSubmit(data) {
    if (!data.name) {
      toast.error('O nome do workgroup é obrigatório.')
    } else if (!data.users) {
      toast.error('Selecione pelo menos um participante.')
    } else {
      dispatch(
        createWorkgroupRequest({
          name: data.name,
          id: data.id,
          description: data.description,
          companyId: profile.companyId,
          users: data.users.map((e) => e.value),
        })
      )
    }
  }

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'name',
      text: 'Nome',
    },
  ]

  function handleChangeToForm(item) {
    if (adminLocal || adminTotal) {
      history.push('/workgroups', {
        id: item ? item.id : null,
        cadastrar: true,
      })
    }
  }

  function handleChangeToList() {
    history.push('/workgroups', {
      cadastrar: false,
    })
    dispatch(
      getWorkgroupsRequest({
        companyId: profile.companyId,
        adminLocal,
        adminTotal,
        id: profile.id,
      })
    )
    reset()
  }

  function handleDelete(item) {
    confirmAlert({
      title: 'Exclusão',
      message:
        'Deseja realmente excluir permanentemente esse WorkGroup? Essa ação não poderá ser desfeita.',
      buttons: [
        {
          label: 'Sim. Eu desejo.',
          onClick: () => {
            if (item.id)
              api
                .delete(`work-group/${item.id}`)
                .then(() => {
                  toast.success(`WorkGroup ${item.name} deletada com sucesso.`)
                  handleChangeToList()
                })
                .catch(() => {
                  toast.error('Erro ao deletar o WorkGroup.')
                })
          },
        },
        {
          label: 'Cancelar essa ação.',
          onClick: () => {},
        },
      ],
    })
  }

  useEffect(() => {
    dispatch(
      getWorkgroupsRequest({
        companyId: profile.companyId,
        adminLocal,
        adminTotal,
        id: profile.id,
      })
    )
  }, [adminLocal, adminTotal, dispatch, profile.companyId, profile.id])

  useEffect(() => {
    function getUsers() {
      let query
      if (adminTotal || adminLocal) query = 'user?include.company=true'

      api
        .get(query)
        .then((response) => {
          const parseResp = response.data.map(
            ({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                email,
                name,
                logoLink,
                company,
              }
            }
          )
          setLoading(false)
          setUsersOptions(parseResp)
        })
        .catch(() => {
          setLoading(false)
        })
    }
    getUsers()
  }, [adminLocal, adminTotal])

  useEffect(() => {
    function loadWorkgroups() {
      setLoading(true)
      api
        .get(`work-group/${data.id}?include.users.include.company=true`)
        .then(({ data: loadWorkgroup }) => {
          setValue('id', loadWorkgroup.id)
          setValue('name', loadWorkgroup.name)
          setValue('description', loadWorkgroup.description)
          const parseUsers = loadWorkgroup.users.map(
            ({ id, name, email, logoLink, company }) => {
              return {
                value: id,
                label: name + ' | ' + email,
                logoLink,
                name,
                company,
              }
            }
          )
          setLoading(false)
          setValue('users', parseUsers)
        })
        .catch(() => {
          setLoading(false)
        })
    }
    if (data && data.id) loadWorkgroups()
  }, [data, setValue])

  useEffect(() => {
    if (created) {
      reset({ users: null })
    }
  }, [created, reset, setValue])

  return (
    <>
      {(loadingWorkgroup || loading) && <Loading />}
      {(adminLocal || adminTotal) && (
        <div className="row mb-2 mt-4 d-flex align-items-center ml-2">
          <Header
            title="Listar WorkGroups"
            ativo={!data?.cadastrar}
            onClick={handleChangeToList}
          ></Header>
          <Header
            title={data?.id ? 'Atualizar WorkGroup' : 'Cadastrar WorkGroup'}
            ativo={!!data?.cadastrar}
            onClick={() => handleChangeToForm()}
          ></Header>
          {(adminLocal || adminTotal) && (
            <TooltipCustom text="Nessa página você tem acesso a visualização, cadastro e edição de WorkGroups" />
          )}
        </div>
      )}
      {(adminLocal || adminTotal) && !!data?.cadastrar && (
        <div className="content mt-4">
          <Card>
            <CardBody>
              <Description
                icon={<FaUsers color="#495057" size={30} className="mr-2" />}
                title="Dados do WorkGroup"
              />
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <input hidden {...register('id')} />
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        label="Nome"
                        xl="12"
                        lg="12"
                        id={field.name}
                        type="text"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        label="Descrição"
                        xl="12"
                        lg="12"
                        id={field.name}
                        type="text"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <Controller
                    name="users"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <Select
                        label="Participantes"
                        xl="12"
                        lg="12"
                        multiOptions
                        isClearable
                        value={field.value}
                        handleChangeValue={field.onChange}
                        name={field.name}
                        options={usersOptions}
                        style={{
                          multiValue: (styles) => ({
                            ...styles,
                            background: 'transparent',
                          }),
                          multiValueRemove: (styles) => ({
                            ...styles,
                            ':hover': {
                              background: 'none',
                              color: '#DE350B',
                            },
                          }),
                        }}
                        formatOptionLabel={(user) => (
                          <div>
                            <Avatar
                              color="#999"
                              title={user.name + ' | ' + user.email}
                              key={user.id}
                              name={user.name}
                              src={user.logoLink}
                              maxInitials={2}
                              style={{
                                marginRight: '0.4em',
                              }}
                              size="32px"
                              round
                            />
                            <span
                              title={
                                user.email + ' | ' + user.company.fantasyName
                              }
                            >
                              {user.name + ' | ' + user.company.fantasyName}
                            </span>
                          </div>
                        )}
                      />
                    )}
                  />
                </Row>
                <Button
                  type="submit"
                  className="btn-default form-control"
                  title={data && data.id ? 'Atualizar' : 'Cadastrar'}
                />
              </form>
            </CardBody>
          </Card>
        </div>
      )}
      {(adminLocal || adminTotal) && !data?.cadastrar && (
        <div className="content mt-4">
          <Table
            keyField="id"
            data={workgroupsData}
            columns={columns}
            extrasColumns={[
              {
                text: 'Editar',
                className: 'btn btn-sm btn-default',
                onClick: handleChangeToForm,
                buttonText: 'Editar',
              },
              {
                text: 'Deletar',
                className: 'btn btn-sm btn-default',
                onClick: handleDelete,
                buttonText: 'Deletar',
              },
            ]}
          />
        </div>
      )}
    </>
  )
}

Workgroup.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      cadastrar: PropTypes.bool,
    }),
  }),
}

Workgroup.defaultProps = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: '',
      cadastrar: false,
    }),
  }),
}
