import React from 'react'
import PropTypes from 'prop-types'
export default function Button({
  title,
  className,
  ...rest
}) {
  return (
    <button
      style={{
        fontFamily: "'Poppins', sans-serif",
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '15px',
        lineHeight: '16px',
        padding: '10px 10px'
      }}
      {...rest}
      className={`btn ${className}`}>
      {title}
    </button>
  )
}

Button.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
}
