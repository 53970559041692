import React, { useEffect, useState } from 'react'
import { Card } from '~/components/Card'
import CardBody from '~/components/Card/Body'
import Table from '~/components/Table'
import { Container, ContainerData, ContainerTable } from './styles'
import Loading from '~/components/Loading'
import api from '~/services/api'
import Avatar from 'react-avatar'
import Col from '~/components/Bootstrap/Col'
import Row from '~/components/Bootstrap/Row'
import { Modal } from 'react-bootstrap'
import Action from '../Meeting/Action'
import { ContainerDate } from '../Dashboard/Meeting/styles'
import { addDays, addHours, format, formatISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import Select from '~/components/Form/Select'
import Input from '~/components/Form/Input'
import Button from '~/components/Form/Button'
import history from '~/services/history'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

export default function Notifications() {

  const [loading, setLoading] = useState([])

  const { profile } = useSelector(state => state.auth)

  const [notifications, setNotifications] = useState([])

  const { control, handleSubmit, setValue } = useForm()

  const [acaoAtiva, setAcaoAtiva] = useState(false)

  const [actionId, setActionId] = useState(null)

  const adminLocal = profile.roleId === 2

  const [aditionalQuery, setAditionalQuery] = useState('')
  const [responsiblesOptions, setResponsiblesOptions] = useState([])



  const onSubmitFilter = (data) => {
    const dataUsers = data.users ? data.users.map(({ value }) => value) : []
    const dateStart = data.date_start
      ? formatISO(addHours(
        new Date(data.date_start).setUTCHours(0, 0, 0, 0),
        3
      ))
      : null
    const dateEnd = data.date_end
      ? formatISO(
        addDays(
          addHours(
            new Date(data.date_end).setUTCHours(23, 59, 59, 999),
            3
          ), 1)
      )
      : null
    let i = 0
    let query = ''
    dataUsers.forEach(e => {
      query += `&&where.OR[${i++}].userId=${e}`
    })
    if (dateStart)
      query += `&&where.createdAt.gte=${dateStart}`
    if (dateEnd)
      query += `&&where.createdAt.lte=${dateEnd}`
    setAditionalQuery(query)
  }


  const handleResetForm = () => {
    setValue('users', [])
    setValue('date_start', '')
    setValue('date_end', '')
    setAditionalQuery('')
  }


  const pageListRenderer = ({
    pages,
    onPageChange
  }) => {
    const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string')
    return (
      <div>
        {
          pageWithoutIndication.map(p => (
            <button key={p.page} className="btn btn-primary mr-1" onClick={() => onPageChange(p.page)}>{p.page}</button>
          ))
        }
      </div>
    )
  }

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'user.name',
      text: 'Usuário',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <ContainerData>
            <div className="row">
              <Col xl='1' lg='1' sm='1' md='1' col='1' className='d-flex align-items-center'>
                <Avatar
                  color="#999"
                  title={
                    row.user.name
                    + ' | '
                    + row.user.email
                  }
                  key={row.user.id}
                  name={row.user.name}
                  src={row.user.logoLink}
                  maxInitials={2}
                  style={{
                    marginRight: '0.4em'
                  }}
                  size='32px'
                  round />
              </Col>
              <Col xl='11' lg='11' md='11' sm='11' col='11' >
                <Row>
                  <small className="text-muted text-wrap">
                    {row.user.company.fantasyName}
                  </small>
                </Row>
                <Row>
                  <strong onClick={() => {
                    if (row.subject.name === 'a reunião' ||
                      row.subject.name === 'a pauta' ||
                      row.subject.name === 'a ata da reunião')
                      handleRedirectToMeeting(row)
                    else if (row.subject.name === 'o workgroup')
                      handleRedirectToWorkgroup(row)
                    else
                      handleRedirectToAction(row)

                  }}>
                    {row.user.name}
                  </strong>
                </Row>
                <Row>
                  <span className='text-muted text-wrap' onClick={() => {
                    if (row.subject.name === 'a reunião' ||
                      row.subject.name === 'a pauta' ||
                      row.subject.name === 'a ata da reunião')
                      handleRedirectToMeeting(row)
                    else if (row.subject.name === 'o workgroup')
                      handleRedirectToWorkgroup(row)
                    else
                      handleRedirectToAction(row)

                  }}>
                    {row.type.name + row.subject.name +
                      (
                        row.subject.name === 'a ação'
                          ? ' "' + row.subject.entity.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'um comentário'
                          ? ' na ação "' + row.subject.entity.action.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'a reunião'
                          ? ' "' + row.subject.entity.title.value + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'o workgroup'
                          ? ' "' + row.subject.entity.name + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'da ação'
                          ? ' "' + row.subject.entity.action.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'um arquivo'
                          ? ' na ação "' + row.subject.entity.action.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'a pauta'
                          ? ' "' + row.subject.entity.agendaTopic + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'a ata da reunião'
                          ? ' "' + row.subject.entity.title.value + '" para '
                          + row.subject.entity.recipients
                            .map(({ name }) => name).join(', ')
                          : ''
                      )
                    }
                  </span>
                </Row>
              </Col>
            </div>
          </ContainerData>
        )
      },
      headerFormatter: (column, e, { sortElement }) => {
        return (
          <div>
            {column.text}
            {sortElement}
          </div>
        )
      }
    },

    {
      dataField: 'subject.entity.createdAt',
      text: 'Data',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <ContainerDate>
            <Row onClick={() => {
              if (row.subject.name === 'a reunião' || row.subject.name === 'a pauta')
                handleRedirectToMeeting(row)
              else if (row.subject.name === 'o workgroup')
                handleRedirectToWorkgroup(row)
              else
                handleRedirectToAction(row)

            }}>
              <strong>{format(new Date(row.subject.entity.createdAt), 'dd MMM', { locale: ptBR })}</strong>
            </Row>
            <Row onClick={() => {
              if (row.subject.name === 'a reunião' || row.subject.name === 'a pauta')
                handleRedirectToMeeting(row)
              else if (row.subject.name === 'o workgroup')
                handleRedirectToWorkgroup(row)
              else
                handleRedirectToAction(row)
            }}>
              <small>{format(new Date(row.subject.entity.createdAt), 'HH:mm', { locale: ptBR })}</small>
            </Row>
          </ContainerDate>
        )
      },
      headerFormatter: (column, e, { sortElement }) => {
        return (
          <div>
            {column.text}
            {sortElement}
          </div>
        )
      }
    }
  ]



  function handleRedirectToMeeting(row) {
    if (row.subject.name === 'a pauta')
      history.push('/reuniao', {
        id: row.subject.entity.meetingId,
        cadastrar: true
      })
    else if (row.subject.name === 'a ata da reunião')
      history.push('/reuniao', {
        id: row.subject.entity.id,
        cadastrar: true
      })
    else
      history.push('/reuniao')
  }

  function handleRedirectToWorkgroup(row) {
    history.push('/workgroups')
  }

  const removeDuplicated = (a, key) => {
    return [
      ...new Map(
        a.map(x => [key(x), x])
      ).values()
    ]
  }
  useEffect(() => {
    function getNotifications() {
      setLoading(true)
      api.get(`log?include.type=true&&include.subject=true&&include.user.include.company=true&&orderBy.id=desc${adminLocal ? `&&where.user.companyId=${profile.companyId}` : ''}${aditionalQuery}`)
        .then(({ data }) => {
          setLoading(false)
          setResponsiblesOptions(
            data.map(
              ({ user }) => user)
              .map(({ id, name, email, logoLink, company }) => {
                return {
                  value: id,
                  label: name + ' | ' + email,
                  email,
                  name,
                  logoLink,
                  company
                }
              })
          )
          console.log(data)
          data.map(e => {
            e.subject.entity = JSON.parse(e.subject.entity)
            switch (e.subject.name) {
              case 'Action':
                e.subject.name = 'a ação'
                break
              case 'Comment':
                e.subject.name = 'um comentário'
                break
              case 'Meeting':
                if (!e.subject.entity.recipients)
                  e.subject.name = 'a reunião'
                else
                  e.subject.name = 'a ata da reunião'
                break
              case 'WorkGroup':
                e.subject.name = 'o workgroup'
                break
              case 'AnswerAction':
                e.subject.name = 'da ação'
                break
              case 'Attachment':
                e.subject.name = 'um arquivo'
                break
              case 'Topic':
                e.subject.name = 'a pauta'
                break
              default:
                break
            }
            switch (e.typeId) {
              case 1:
                e.type.name = 'Criou '
                break
              case (2):
                e.type.name = 'Atualizou '
                break
              case (3):
                e.type.name = 'Atualizou o status d'
                break
              case (4):
                e.type.name = 'Deletou '
                break
              case (5):
                e.type.name = 'Ficou ciente '
                break
              case (6):
                e.type.name = 'Enviou '
                break
              default:
                break
            }
            return e
          })
          setNotifications(data)
        }).catch(() => {
          setLoading(false)
        })
    }
    getNotifications()
  }, [aditionalQuery, adminLocal, profile.companyId])

  function handleRedirectToAction(row) {
    setActionId(row.subject.name === 'a ação' ? row.subject.entity.id : row.subject.entity.actionId)
    setAcaoAtiva(true)
  }

  return (
    <Container className="mt-4">
      <Modal
        size="xl"
        show={acaoAtiva}
        onHide={() => setAcaoAtiva(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            Ação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Action actionId={parseInt(actionId)} />
        </Modal.Body>
      </Modal>
      <Card>
        <CardBody>
          <ContainerTable>
            <form onSubmit={handleSubmit(onSubmitFilter)}>
              <Row>
                <Controller
                  name="users"
                  control={control}
                  defaultValue={null}
                  render={({ field }) =>
                    <Select
                      label='Usuários'
                      value={field.value}
                      handleChangeValue={field.onChange}
                      name={field.name}
                      multiOptions
                      options={removeDuplicated(responsiblesOptions, t => t.value)}
                      style={{
                        multiValue: styles => ({
                          ...styles,
                          background: "transparent"
                        }),
                        multiValueRemove: styles => ({
                          ...styles,
                          ":hover": {
                            background: 'none',
                            color: '#DE350B'
                          }
                        })
                      }}
                      formatOptionLabel={(user) => (
                        <div>
                          <Avatar
                            color="#999"
                            title={
                              user.name
                              + ' | '
                              + user.email
                            }
                            key={user.id}
                            name={user.name}
                            src={user.logoLink}
                            maxInitials={2}
                            style={{
                              marginRight: '0.4em'
                            }}
                            size='32px'
                            round />
                          <span title={user.email + ' | ' + user.company.fantasyName}>
                            {user.name.split(' ')[0]}
                          </span>
                        </div>
                      )}
                    />}
                />
                <Controller
                  name="date_start"
                  control={control}
                  defaultValue={''}
                  render={({ field }) =>
                    <Input
                      xl="3"
                      label="De"
                      id={field.name}
                      type="date"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                    />}
                />
                <Controller
                  name="date_end"
                  control={control}
                  defaultValue={''}
                  render={({ field }) =>
                    <Input
                      xl="3"
                      label="Até"
                      id={field.name}
                      type="date"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                    />}
                />
              </Row>
              <Button
                type="submit"
                className="btn-success btn mt-4 mb-4"
                title='Filtrar'
              />
              <Button
                type="button"
                onClick={handleResetForm}
                className="btn-default btn mt-4 mb-4 ml-2"
                title='Limpar filtros'
              />
            </form>
            {loading && <Loading card={true} />}
            {!loading && (
              <Table
                keyField="id"
                data={notifications}
                columns={columns}
                striped={false}
                search={false}
                paginationOptions={
                  {
                    sizePerPageList: [
                      {
                        text: '30', value: 30
                      },
                      {
                        text: '40', value: 40
                      },
                      {
                        text: '50', value: 50
                      }
                    ],
                    sizePerPage: 20,
                    pageListRenderer
                  }
                }
              />)}
          </ContainerTable>
        </CardBody>
      </Card>
    </Container>
  )
}
