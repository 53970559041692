import styled from "styled-components"

export const Button = styled.button`
    color: ${props => props.ativo ? '#363636' : '#b7b7b7'};
    border-bottom: ${props => props.ativo
    ? '1px solid #363636'
    : '1px solid transparent'};
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 23px;
    padding: 1px 4px;
    background: transparent;
    border-radius: 2px;
`
