import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import { FaBell, FaCheck, FaUserCircle } from 'react-icons/fa'

import { Navbar, Nav, Dropdown, Alert } from 'react-bootstrap'
import history from '~/services/history'
import Avatar from 'react-avatar'
import api from '~/services/api'
import { toast } from 'react-toastify'
import { format, formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { ContainerNavbar, ContainerNavbarProfile, NavNotifications, Notifications } from './styles'
import { confirmAlert } from 'react-confirm-alert'
import { SocketContext } from '~/services/websockets'
import Loading from '../Loading'

function NavbarFunc() {
  const { profile } = useSelector(state => state.auth)
  const userLogo = profile.logoLink

  const [loadingAlerts, setLoadingAlerts] = useState(false)
  const [loadingExpireAlerts, setLoadingExpireAlerts] = useState(false)

  const [alerts, setAlerts] = useState(null)
  const [actionAlerts, setActionAlerts] = useState(null)
  const [expireActionAlerts, setExpireActionAlerts] = useState(null)
  const [invitations, setInvitations] = useState(null)

  const [socketStarted, setSocketStarted] = useState(false)
  const connect = useContext(SocketContext)

  const dropdownProfileRef = useRef(null)
  const [dropdownProfileSize, setDropdownProfileSize] = useState(false)
  const [token, setToken] = useState(null)

  const getMeetingAlerts = useCallback(() => {
    api.get(`alert?include.invitation.include.meeting.include.title=true&&include.invitation.include.meeting.include.topics=true&&include.invitation.include.meeting.include.participants.include.user=true&&where.invitation.participant.userId=${profile.id}&&orderBy.createdAt=desc`)
      .then(({ data }) => {
        if (data.length > 0) {
          setAlerts([data])
          setInvitations(data.map(({ invitation }) => invitation))
        }
      })
      .catch(() => {
        toast.error("Erro ao buscar notificações.")
      })
  }, [profile.id])

  const getExpireActionAlerts = useCallback(() => {
    api.get(`alert?include.actions.include.topic=true&&include.actions.include.deadline.include.dateMarking=true&&where.visualized=false&&where.userId=${profile.id}&&where.OR[0].typeId=2&&where.OR[1].typeId=3&&where.OR[2].typeId=4&&orderBy.createdAt=desc`)
      .then(({ data }) => {
        setExpireActionAlerts(data)
        // (data.map(e => e.actions.map(({ description }) => console.log(description))))
      })
      .catch(() => {
        toast.error("Erro ao buscar notificações.")
      })
  }, [profile.id])

  const getActionAlerts = useCallback(() => {
    api.get(`action?include.topic=true&&include.deadline.include.dateMarking=true&&where.responsibles.some.userId=${profile.id}&&where.answers.some.responsible.userId=${profile.id}&&where.answers.some.isAware=false&&orderBy.createdAt=desc`)
      .then(({ data }) => {
        // if (data.length > 0) {
        //   // setAlerts([data])
        //   // setInvitations(data.map(({ invitation }) => invitation))
        // }
        setActionAlerts(data)
      })
      .catch(() => {
        toast.error("Erro ao buscar notificações.")
      })
  }, [profile.id])

  useEffect(() => {
    api.getToken().then((idToken) => {
      setToken(idToken)
    }).catch(() => { })
  }, [])

  useEffect(() => {
    getMeetingAlerts()
  }, [getMeetingAlerts])

  useEffect(() => {
    getExpireActionAlerts()
  }, [getExpireActionAlerts])

  useEffect(() => {
    getActionAlerts()
  }, [getActionAlerts])

  useEffect(() => {
    if (!socketStarted && token) {
      setSocketStarted(true)
      const alertListener = (data) => {
        // if (alerts)
        setAlerts(e => [...e, data])
        // else
        //   setAlerts([data])
        // if (invitations)
        setInvitations(e => [...e, data.invitation])
        // else
        //   setInvitations([data.invitation])

        toast.warn(
          `Reunião
          ${data.invitation.meeting.title.value + ' '}
          ${formatDistanceToNow(new Date(data.invitation.meeting?.start),
            {
              addSuffix: true,
              locale: ptBR
            }
          )}`,
          {
            autoClose: 10000,
            onClick: () => {
              history.push(`reuniao`, {
                id: data.invitation.meetingId,
                cadastrar: true
              })
            }
          }
        )
      }
      const newActionListener = (data) => {
        setActionAlerts(e => [...e, data.newAction.action].filter(({ newActionId }) => newActionId))
        // console.log(data)
        toast.warn(`Nova ação: ${data.newAction.action.description}`,
          {
            autoClose: 10000,
            onClick: () => {
              history.push(`reuniao`, {
                id: data.newAction.action.topic.meetingId,
                cadastrar: true
              })
            }
          }
        )
      }
      const connectWs = connect(token)
      connectWs.on('meeting_invitation', alertListener)
      connectWs.on('new_action', newActionListener)
    }
  }, [actionAlerts, alerts, connect, invitations, socketStarted, token])

  function handleRedirectToAction(action) {
    confirmAlert({
      childrenElement: () => (
        <div>
          Pauta: {action.topic.agendaTopic}<br />
          Prazo: {format(new Date(action.deadline.dateMarking.date), "dd/MM/yyyy 'às' HH:mm", { locale: ptBR })}<br />
          Está ciente desta ação?
        </div>
      ),
      title: `Ação: ${action.description}`,
      buttons: [
        {
          label: 'Ciente',
          onClick: () => {
            api.put(`action/${action.id}/aware`, {
              userId: profile.id,
            }).then(() => {
              history.go(0)
            }).catch(() => {
              toast.error('Erro ao confirmar ação.')
            })
          },
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    })
  }
  function handleRedirectToExpireAction(alert, action) {
    confirmAlert({
      childrenElement: () => (
        <div>
          Pauta: {action.topic.agendaTopic}<br />
          Prazo: {format(new Date(action.deadline.dateMarking.date), "dd/MM/yyyy 'às' HH:mm", { locale: ptBR })}<br />
          Está ciente do prazo dessa ação?
        </div>
      ),
      title: `Ação: ${action.description}`,
      buttons: [
        {
          label: 'Ciente',
          onClick: () => {
            api.put(`alert/${alert.id}`, {
              visualized: true,
            }).then(() => {
              history.go(0)
            }).catch(() => {
              toast.error('Erro ao confirmar reunião.')
            })
          },
        },
      ],
    })
  }
  function handleRedirectToMeeting(meeting, id) {
    // console.log(meeting)
    confirmAlert({
      childrenElement: () => (
        <div>
          Data e horário: {format(new Date(meeting.start), "dd/MM/yyyy 'às' HH:mm", { locale: ptBR })}<br />
          Pautas:
          <ul>
            {meeting.topics.map(({ id, agendaTopic }) => {
              return <li key={id}>{agendaTopic}</li>
            })
            }
          </ul>
          Participantes:
          <ul>

            {meeting.participants.map(({ user }) => user).map(({ id, name }) => {
              return <li key={id}>{name}</li>
            })}
          </ul>
          Deseja confirmar presença na reunião?
        </div>
      ),
      title: `Reunião: ${meeting.title.value}`,
      buttons: [
        {
          label: 'Irei participar',
          onClick: () => {
            api.put(`invitation/${id}`, {
              confirmed: true,
              answered: true,
            }).then(() => {
              history.push('/reuniao', {
                id: meeting.id,
                cadastrar: true
              })
              getMeetingAlerts()
            }).catch(() => {
              toast.error('Erro ao confirmar reunião.')
            })
          },
        },
        {
          label: 'Não irei',
          onClick: () => {
            api.put(`invitation/${id}`, {
              confirmed: false,
              answered: true,
            }).then(() => {
              getMeetingAlerts()
            }).catch(() => {
              toast.error('Erro ao confirmar reunião.')
            })
          },
        },
      ],
    })
  }

  useEffect(() => {
    if (dropdownProfileRef.current) {
      setDropdownProfileSize(dropdownProfileRef.current.offsetWidth)
    }
  }, [])

  const handleSeeAllNotifications = () => {
    if (expireActionAlerts?.length > 0) {
      setLoadingExpireAlerts(true)
      expireActionAlerts.forEach((expireActionAlert, i) => {
        api.put(`alert/${expireActionAlert.id}`, {
          visualized: true,
        }).then(() => {
          if (i === expireActionAlerts.length - 1) {
            setExpireActionAlerts(null)
            setLoadingExpireAlerts(false)
          }
        }).catch(() => {
          setLoadingExpireAlerts(false)
          toast.error('Erro ao marcar notificações.')
        })
      })
    }
    if (actionAlerts?.length > 0) {
      setLoadingAlerts(true)
      actionAlerts.forEach((actionAlert, i) => {
        api.put(`action/${actionAlert.id}/aware`, {
          userId: profile.id,
        }).then(() => {
          if (i === actionAlerts.length - 1) {
            setActionAlerts(null)
            setLoadingAlerts(false)
          }
        }).catch(() => {
          setLoadingAlerts(false)
          toast.error('Erro ao marcar notificações.')
        })
      })
    }
  }

  // useEffect(() => {
  //   console.log((invitations?.filter(({ answered }) => !answered).length ?? 0))
  //   console.log(alerts ?? 0)
  //   console.log(actionAlerts?.length)
  //   console.log(expireActionAlerts?.length)
  // }, [actionAlerts, alerts, expireActionAlerts, invitations])

  return (
    <>
      {(loadingAlerts || loadingExpireAlerts) && <Loading />}
      <ContainerNavbar>
        <Navbar expand="xl" className="justify-content-end">
          <Dropdown alignRight={true}>
            <NavNotifications dropdownProfileSize={dropdownProfileSize}>
              <Dropdown.Toggle variant="default" id="dropdown-notifications">
                <FaBell size="1.5em" />
                <Notifications>
                  {(invitations?.filter(({ answered }) => !answered).length ?? 0)
                    + (actionAlerts?.length ?? 0)
                    + (expireActionAlerts?.length ?? 0)
                  }
                </Notifications>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!((!invitations?.filter(({ answered }) => !answered).length ||
                  invitations?.filter(({ answered }) => !answered).length === 0) &&
                  actionAlerts?.length === 0
                  && expireActionAlerts?.length === 0) && (
                    <Dropdown.Item
                      key="marcarTodas"
                      title='Marcar todas como lidas'
                      className='text-wrap text-center text-danger'
                      onClick={() => handleSeeAllNotifications()}
                    >
                      <Alert variant="error">
                        <FaCheck className="mr-2" />
                        {" "}Marcar todas como lidas
                      </Alert>

                    </Dropdown.Item>
                  )}
                {(!invitations?.filter(({ answered }) => !answered).length ||
                  invitations?.filter(({ answered }) => !answered).length === 0) &&
                  actionAlerts?.length === 0
                  && expireActionAlerts?.length === 0 && (
                    <Dropdown.ItemText>
                      Nenhuma noitificação
                    </Dropdown.ItemText>
                  )}
                {invitations?.filter(({ answered }) => !answered)?.map((e) => {
                  return (

                    <Dropdown.Item
                      key={e.id}
                      title=''
                      className='text-wrap mt-1 mb-1'
                      onClick={() => handleRedirectToMeeting(e.meeting, e.id)}>
                      <Alert variant="secondary">
                        {'Reunião: ' + e.meeting.title.value}
                      </Alert>
                    </Dropdown.Item>
                  )
                })}
                {actionAlerts?.map((e) => {
                  return (
                    <Dropdown.Item
                      key={e.id}
                      title=''
                      className='text-wrap mt-1 mb-1'
                      onClick={() => handleRedirectToAction(e)}>
                      <Alert variant="secondary">
                        {`${e.statusId !== 6
                          ? 'Nova ação: "'
                          : 'Revisar ação "'}${e.description}"`}
                      </Alert>
                    </Dropdown.Item>
                  )
                })}
                {expireActionAlerts?.map((e) => {
                  switch (e.typeId) {
                    case 2:
                      return e.actions.map((action) =>
                        <Dropdown.Item
                          key={action.id}
                          title=''
                          className='text-wrap mt-1 mb-1'
                          onClick={() => handleRedirectToExpireAction(e, action)}>
                          <Alert variant="secondary">
                            {'Ação "' + action.description + '" se vence hoje'}
                          </Alert>
                        </Dropdown.Item>)

                    case 3:
                      return e.actions.map((action) =>
                        <Dropdown.Item
                          key={action.id}
                          title=''
                          className='text-wrap mt-1 mb-1'
                          onClick={() => handleRedirectToExpireAction(e, action)}>
                          <Alert variant="secondary">
                            {'Ação "' + action.description + '" se venceu hoje'}
                          </Alert>
                        </Dropdown.Item>)
                    case 4:
                      return e.actions.map((action) =>
                        <Dropdown.Item
                          key={action.id}
                          title=''
                          className='text-wrap mt-1 mb-1'
                          onClick={() => handleRedirectToExpireAction(e, action)}>
                          <Alert variant="secondary">
                            {'Ação "' + action.description + '" se vencerá amanhã'}
                          </Alert>
                        </Dropdown.Item>)
                    default:
                      return ''
                  }
                }
                )}
              </Dropdown.Menu>
            </NavNotifications>
          </Dropdown>
          <Nav>
            <ContainerNavbarProfile>
              <Dropdown alignRight={true}>
                <Dropdown.Toggle ref={dropdownProfileRef} id="dropdown-profile">
                  <span style={{
                    paddingLeft: '0',
                    marginTop: '0',
                    fontWeight: 600,
                    marginRight: '15px'
                  }}>
                    {profile.name}
                  </span>
                  {userLogo && (
                    <Avatar
                      src={userLogo}
                      size='32px'
                      round
                      alt={profile.name}
                    />)}
                  {!userLogo && (
                    <FaUserCircle color="#e1e3e1" size="32px" />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-profile" style={{ zIndex: '2', position: 'fixed', top: '65px' }}>
                  <Dropdown.Item
                    className="btn form-control btn-default"
                    onClick={() => history.push('/usuarios', {
                      id: profile.id,
                      cadastrar: true
                    })} key="1">
                    Acessar meu perfil
                  </Dropdown.Item>
                  <Dropdown.ItemText key="2"><b>Empresa:</b> {profile.company.fantasyName}</Dropdown.ItemText>
                  <Dropdown.ItemText key="3"><b>Acesso:</b> {profile.role.label}</Dropdown.ItemText>
                </Dropdown.Menu>
              </Dropdown>
            </ContainerNavbarProfile>
          </Nav>
        </Navbar>
      </ContainerNavbar>
    </>
  )
}
export const NavbarCustom = memo(NavbarFunc)
