import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm, Controller } from 'react-hook-form'

import { signInRequest } from '../../store/modules/auth/actions'
import Row from '../../components/Bootstrap/Row'
import Input from '../../components/Form/Input'
import Loading from '../../components/Loading'
import { Container, BottomSide, LoginCard, TopSide } from './styles'
import servnacLogo from '../../assets/logo.png'
import Button from '../../components/Form/Button'

export default function SignIn() {
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { loading } = useSelector((state) => state.auth)

  const onSubmit = (data) => {
    if (!data.email) {
      toast.error('O usuário é obrigatório.')
    } else if (!data.password) {
      toast.error('A senha é obrigatória.')
    } else {
      dispatch(signInRequest(data.email, data.password))
    }
  }

  return (
    <Container>
      {loading && <Loading />}

      <TopSide>
        <img src={servnacLogo} alt="servnacLogo" />
      </TopSide>

      <BottomSide>
        <LoginCard>
          <p>Login</p>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    xl="12"
                    lg="12"
                    label="E-mail"
                    id={field.name}
                    type="email"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
            <Row>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    xl="12"
                    lg="12"
                    label="Senha"
                    maxLength={14}
                    id={field.name}
                    type="password"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
            <u>
              <a href="/resetarSenha" className="font-italic">
                Esqueci minha senha
              </a>
            </u>
            <Button
              type="submit"
              className="btn-outline-success form-control mt-4"
              title="Entrar"
            />
          </form>
        </LoginCard>
      </BottomSide>
    </Container>
  )
}
