import produce from 'immer'
import * as types from '../types'
const INITIAL_STATE = {
  data: [],
  meeting: [],
  loading: false
}

export default function meeting(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.CREATE_MEETING_REQUEST: {
        draft.meeting = []
        draft.loading = true
        break
      }
      case types.CREATE_MEETING_SUCCESS: {
        draft.meeting = action.payload.data
        draft.loading = false
        break
      }
      case types.GET_MEETINGS_REQUEST: {
        draft.meeting = []
        draft.loading = true
        break
      }
      case types.GET_MEETINGS_SUCCESS: {
        draft.data = action.payload.data
        draft.loading = false
        break
      }
      case types.MEETING_FAILURE: {
        draft.loading = false
        break
      }
      default:
    }
  })
}
