import produce from 'immer'
import * as types from '../types'
const INITIAL_STATE = {
  data: [],
  loading: false,
  created: false
}

export default function workgroup(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.CREATE_WORKGROUP_REQUEST: {
        draft.created = false
        draft.loading = true
        break
      }
      case types.CREATE_WORKGROUP_SUCCESS: {
        draft.created = true
        draft.loading = false
        break
      }
      case types.GET_WORKGROUPS_REQUEST: {
        draft.created = false
        draft.loading = true
        break
      }
      case types.GET_WORKGROUPS_SUCCESS: {
        draft.data = action.payload.data
        draft.created = false
        draft.loading = false
        break
      }
      case types.WORKGROUP_FAILURE: {
        draft.created = false
        draft.loading = false
        break
      }
      default:
    }
  })
}
