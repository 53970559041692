import produce from 'immer'
import * as types from '../types'

const INITIAL_STATE = {
  data: [],
  company: [],
  loading: false,
  created: false,
}

export default function company(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.GET_COMPANY_REQUEST: {
        draft.created = false
        draft.loading = true
        break
      }
      case types.GET_COMPANY_SUCCESS: {
        draft.data = action.payload.data
        draft.created = false
        draft.loading = false
        break
      }
      case types.SAVE_COMPANY_REQUEST: {
        draft.created = false
        draft.loading = true
        break
      }
      case types.SAVE_COMPANY_SUCCESS: {
        draft.company = action.payload.data
        draft.created = true
        draft.loading = false
        break
      }
      case types.REQUEST_FAILURE: {
        draft.created = false
        draft.loading = false
        break
      }

      default:
    }
  })
}
