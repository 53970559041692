import React, { useCallback, useEffect, useState } from 'react'
import Header from '~/components/Header'
import { Card } from '~/components/Card'
import CardHeader from '~/components/Card/Header'
import CardBody from '~/components/Card/Body'
import Table from '~/components/Table'
import { ContainerHeader, ContainerAction } from './styles'
import { useSelector } from 'react-redux'
import Loading from '~/components/Loading'
import api from '~/services/api'
import { format, formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Modal } from 'react-bootstrap'
import Action from '~/pages/Meeting/Action'
import Col from '~/components/Bootstrap/Col'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

export default function DashboardActions() {

  const [data, setData] = useState({
    afazer: true,
    pausado: false,
    fazendo: false,
    vencido: false,
    realizado: false,
  })
  const { profile } = useSelector(state => state.auth)
  const { success: actionSuccess } = useSelector(state => state.action)

  const adminTotal = profile.roleId === 1
  const adminLocal = profile.roleId === 2

  const [loading, setLoading] = useState(false)
  const [actions, setActions] = useState([])
  const [acao, setAcao] = useState(1)
  const [acaoAtiva, setAcaoAtiva] = useState(false)
  const [actionId, setActionId] = useState(null)

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'deadline',
      text: 'Prazo',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {row.deadline && !row.extendedDeadline &&
              <>
                <div className="row">
                  <strong>
                    {format(new Date(row.deadline), 'dd MMM', { locale: ptBR })}
                  </strong>
                </div>
                <div className="row">
                  <small>
                    {format(new Date(row.deadline), 'HH:mm', { locale: ptBR })}
                  </small>
                </div>
              </>
            }
            {!row.deadline && row.extendedDeadline &&
              <>
                <div className="row">
                  <strong>
                    {format(new Date(row.extendedDeadline), 'dd MMM', { locale: ptBR })}
                  </strong>
                </div>
                <div className="row">
                  <small>
                    {format(new Date(row.extendedDeadline), 'HH:mm', { locale: ptBR })}
                  </small>
                </div>
              </>
            }
            {row.deadline && row.extendedDeadline &&
              <>
                <div className="row">
                  <strong>
                    {format(new Date(row.extendedDeadline), 'dd MMM', { locale: ptBR })}
                  </strong>
                </div>
                <div className="row">
                  <small>
                    {format(new Date(row.extendedDeadline), 'HH:mm', { locale: ptBR })}
                  </small>
                </div>
              </>
            }
            {!row.deadline && !row.extendedDeadline &&
              <div className="row">
                <strong>
                  À combinar
                </strong>
              </div>
            }
            <div className="row">
              <small className="text-muted">Prioridade - {row.priority.label}</small>
            </div>
          </div>
        )
      },
    },
    {
      dataField: 'description',
      text: 'Ação',
      style: (cell, row, rowI, colI) => {
        return {
          cursor: 'pointer'
        }
      },
      events: {
        onClick: (e, c, ci, row) => {
          handleRedirectToAction(row)
        }
      },
      formatter: (cell, row) => {
        return (
          <div className="td-action">
            <div className="row">
              <small className="text-muted">Acompanhamento -
                {row.topic.monitors?.map(({ user }) => {
                  return ' ' + user.name
                }).join(', ')}
              </small>
            </div>
            <div className="row">
              <strong>{row.description}</strong>
            </div>
            <div className="row">
              <small className="text-muted">
                {row.deadline && !row.extendedDeadline
                  ? formatDistanceToNow(
                    new Date(row.deadline),
                    {
                      locale: ptBR,
                      addSuffix: true
                    })
                  : ''
                }
                {row.deadline && row.extendedDeadline
                  ? formatDistanceToNow(
                    new Date(row.extendedDeadline),
                    {
                      locale: ptBR,
                      addSuffix: true
                    })
                  : ''
                }
                {!row.deadline &&
                  'Prazo não definido'
                }
              </small>
            </div>
          </div>
        )
      }
    },
  ]

  function handleAFazer() {
    setData({
      afazer: true,
      pausado: false,
      fazendo: false,
      vencido: false,
      realizado: false,
    })
    setAcao(1)
  }

  function handlePausado() {
    setData({
      afazer: false,
      pausado: true,
      fazendo: false,
      vencido: false,
      realizado: false,
    })
    setAcao(2)
  }

  function handleFazendo() {
    setData({
      afazer: false,
      pausado: false,
      fazendo: true,
      vencido: false,
      realizado: false,
    })
    setAcao(3)
  }

  function handleVencido() {
    setData({
      afazer: false,
      pausado: false,
      fazendo: false,
      vencido: true,
      realizado: false,
    })
    setAcao(4)
  }

  function handleRealizado() {
    setData({
      afazer: false,
      pausado: false,
      fazendo: false,
      vencido: false,
      realizado: true,
    })
    setAcao(5)
  }

  function handleRedirectToAction(row) {
    setActionId(row.id)
    setAcaoAtiva(true)
  }

  const getActions = useCallback(() => {
    setLoading(true)
    let query
    if (adminTotal)
      query = `action?include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&where.statusId=${acao}&&include.deadline.include.dateMarking=true&&include.extendedDeadline.include.dateMarking=true&&orderBy.createdAt=desc&&take=15`
    else if (adminLocal)
      query = `action?include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&where.responsibles.some.user.companyId=${profile.companyId}&&where.statusId=${acao}&&include.deadline.include.dateMarking=true&&orderBy.createdAt=desc&&take=15`
    else query = `action?include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&where.responsibles.some.userId=${profile.id}&&where.statusId=${acao}&&include.deadline.include.dateMarking=true&&orderBy.createdAt=desc&&take=15`

    api.get(query)
      .then(({ data: action }) => {
        const parseAction = action.map((e) => {
          e.deadline = e.deadline ? e.deadline.dateMarking.date : null
          e.extendedDeadline = e.extendedDeadline ? e.extendedDeadline.dateMarking.date : null
          return e
        })
        setActions(parseAction)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [adminLocal, adminTotal, profile, acao])

  const handleCloseActionModal = useCallback(() => {
    getActions()
    setAcaoAtiva(false)
  }, [getActions])

  useEffect(() => {
    getActions()
  }, [getActions])


  useEffect(() => {
    if (actionSuccess)
      handleCloseActionModal()
  }, [actionSuccess, handleCloseActionModal])

  return (
    <>
      <Modal
        size="xl"
        show={acaoAtiva}
        onHide={handleCloseActionModal}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            Ação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Action actionId={parseInt(actionId)} />
        </Modal.Body>
      </Modal>
      <Card>
        <CardHeader>
          <ContainerHeader className="row">
            <Col xl='2' lg='2' md='2' sm='2' col='4'>
              <Header
                title="A fazer"
                ativo={data.afazer}
                onClick={handleAFazer}>
              </Header>
            </Col>
            <Col xl='2' lg='2' md='2' sm='2' col='4'>
              <Header
                title="Pausado"
                ativo={data.pausado}
                onClick={handlePausado}>
              </Header>
            </Col>
            <Col xl='2' lg='2' md='2' sm='2' col='4'>
              <Header
                title="Fazendo"
                ativo={data.fazendo}
                onClick={handleFazendo}>
              </Header>
            </Col>
            <Col xl='2' lg='2' md='2' sm='2' col='4'>
              <Header
                title="Vencido"
                ativo={data.vencido}
                onClick={handleVencido}>
              </Header>
            </Col>
            <Col xl='2' lg='2' md='2' sm='2' col='4'>
              <Header
                title="Realizado"
                ativo={data.realizado}
                onClick={handleRealizado}>
              </Header>
            </Col>
          </ContainerHeader>
        </CardHeader>
        <CardBody>
          <ContainerAction>
            {loading && <Loading card={true} />}
            {!loading && (
              <Table
                keyField="id"
                data={actions}
                columns={columns}
                striped={false}
                paginationOptions={
                  {
                    sizePerPageList: [{
                      text: '5', value: 5
                    }, {
                      text: '10', value: 10
                    }]
                  }
                }
              />)}
          </ContainerAction>
        </CardBody>
      </Card>
    </>
  )
}

