import React, { useCallback, useEffect, useState } from 'react'
import Table from '~/components/Table'
import { ContainerAction, ContainerHeaderOptions } from './styles'
import { useSelector } from 'react-redux'
import Loading from '~/components/Loading'
import api from '~/services/api'
import { addDays, addHours, format, formatDistanceToNow, formatISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Modal } from 'react-bootstrap'
import Action from '~/pages/Meeting/Action'
import Col from '~/components/Bootstrap/Col'
import Button from '~/components/Form/Button'
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import { Controller, useForm } from 'react-hook-form'
import { Card } from '~/components/Card'
import CardHeader from '~/components/Card/Header'
import Header from '~/components/Header'
import CardBody from '~/components/Card/Body'
import { FaCaretDown, FaCaretUp, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

export default function Box() {

  const [inbox, setInbox] = useState(true)
  const [outbox, setOutbox] = useState(false)
  const [convidadas, setConvidadas] = useState(false)
  const [revisar, setRevisar] = useState(false)

  const handleInboxClick = () => {
    setInbox(true)
    setOutbox(false)
    setConvidadas(false)
    setRevisar(false)
  }

  const handleOutboxClick = () => {
    setInbox(false)
    setOutbox(true)
    setConvidadas(false)
    setRevisar(false)
  }

  const handleConvidadasClick = () => {
    setInbox(false)
    setOutbox(false)
    setConvidadas(true)
    setRevisar(false)
  }

  const handleRevisarClick = () => {
    setInbox(false)
    setOutbox(false)
    setConvidadas(false)
    setRevisar(true)
  }


  const { profile } = useSelector(state => state.auth)
  const { success: actionSuccess } = useSelector(state => state.action)

  const adminTotal = profile.roleId === 1

  const { control, watch } = useForm()

  const [countARevisar, setCountARevisar] = useState(0)

  const [loading, setLoading] = useState(false)
  const [actions, setActions] = useState([])
  const [acaoAtiva, setAcaoAtiva] = useState(false)

  const [actionId, setActionId] = useState(null)

  const [btnAbertas, setBtnAbertas] = useState(true)
  const [btnFinalizadas, setBtnFinalizadas] = useState(false)
  const [btnVencidas, setBtnVencidas] = useState(false)

  const dateStart = watch('date_start')
  const dateEnd = watch('date_end')

  const [aditionalQuery, setAditionalQuery] = useState('')
  const [aditionalQueryBtn, setAditionalQueryBtn] = useState('&&where.OR[0].statusId=1&&where.OR[1].statusId=2&&where.OR[2].statusId=3&&where.OR[3].statusId=6')

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'deadline',
      text: 'Prazo',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div>
            {row.deadline && !row.extendedDeadline &&
              <>
                <div className="row">
                  <strong>
                    {format(new Date(row.deadline), 'dd MMM', { locale: ptBR })}
                  </strong>
                </div>
                <div className="row">
                  <small>
                    {format(new Date(row.deadline), 'HH:mm', { locale: ptBR })}
                  </small>
                </div>
              </>
            }
            {!row.deadline && row.extendedDeadline &&
              <>
                <div className="row">
                  <strong>
                    {format(new Date(row.extendedDeadline), 'dd MMM', { locale: ptBR })}
                  </strong>
                </div>
                <div className="row">
                  <small>
                    {format(new Date(row.extendedDeadline), 'HH:mm', { locale: ptBR })}
                  </small>
                </div>
              </>
            }
            {row.deadline && row.extendedDeadline &&
              <>
                <div className="row">
                  <strong>
                    {format(new Date(row.extendedDeadline), 'dd MMM', { locale: ptBR })}
                  </strong>
                </div>
                <div className="row">
                  <small>
                    {format(new Date(row.extendedDeadline), 'HH:mm', { locale: ptBR })}
                  </small>
                </div>
              </>
            }
            {!row.deadline && !row.extendedDeadline &&
              <div className="row">
                <strong>
                  À combinar
                </strong>
              </div>
            }
            <div className="row">
              <small className="text-muted">Prioridade - {row.priority.label}</small>
            </div>
          </div>
        )
      },
    },
    {
      dataField: 'createdAt',
      text: 'Ação',
      style: (cell, row, rowI, colI) => {
        return {
          cursor: 'pointer'
        }
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      events: {
        onClick: (e, c, ci, row) => {
          handleRedirectToAction(row)
        }
      },
      formatter: (cell, row) => {
        return (
          <div className="td-action">
            <div className="row">
              <small className="text-muted">Pauta:
                {" " + row.topic.agendaTopic}
              </small>
            </div>
            <div className="row">
              <strong>{row.description}</strong>
            </div>
            <div className="row">
              <small className="text-muted">
                {formatDistanceToNow(new Date(row.createdAt), { locale: ptBR, addSuffix: true })}
                {/* {row.answers.map(({ isAware, responsible, id }) => {
                  return (<span key={id}>{responsible.user.name} {isAware ? <FaCheckCircle /> : <FaTimesCircle />}</span>)
                })} */}
              </small>
            </div>
          </div>
        )
      }
    }, {
      dataField: 'description',
      text: 'Ciente',
      style: (cell, row, rowI, colI) => {
        return {
          cursor: 'pointer'
        }
      },
      events: {
        onClick: (e, c, ci, row) => {
          handleRedirectToAction(row)
        }
      },
      formatter: (cell, row) => {
        return (
          <div className="td-action">
            <div className="row">
              <small className="text-muted d-flex">
                {row.answers.map(({ isAware, responsible, id }) => {
                  return (
                    <span className="d-flex align-items-center" key={id}>
                      {responsible.user.name}
                      &nbsp;
                      {isAware ? <FaCheckCircle color='#4b8a52' /> : <FaTimesCircle color="#c82333" />}
                      &nbsp;&nbsp;&nbsp;
                    </span>
                  )
                })}
              </small>
            </div>
          </div>
        )
      }
    },
  ]

  function handleAbertas() {
    setBtnAbertas(true)
    setBtnFinalizadas(false)
    setBtnVencidas(false)
    setAditionalQueryBtn('&&where.OR[0].statusId=1&&where.OR[1].statusId=2&&where.OR[2].statusId=3&&where.OR[3].statusId=6')
  }

  function handleFinalizadas() {
    setBtnAbertas(false)
    setBtnFinalizadas(true)
    setBtnVencidas(false)
    setAditionalQueryBtn('&&where.statusId=5')
  }

  function handleVencidas() {
    setBtnAbertas(false)
    setBtnFinalizadas(false)
    setBtnVencidas(true)
    setAditionalQueryBtn('&&where.statusId=4')
  }

  function handleRedirectToAction(row) {
    setActionId(row.id)
    setAcaoAtiva(true)
  }

  const getActions = useCallback(() => {
    setLoading(true)
    let query
    if (inbox) {
      if (adminTotal)
        query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc${aditionalQuery}${aditionalQueryBtn}`
      else query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&where.responsibles.some.userId=${profile.id}${aditionalQuery}${aditionalQueryBtn}&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc`
    } else if (outbox) {
      if (adminTotal)
        query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc${aditionalQuery}${aditionalQueryBtn}`
      else query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&where.topic.monitors.some.userId=${profile.id}${aditionalQuery}${aditionalQueryBtn}&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc`
    } else if (convidadas) {
      if (adminTotal)
        query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc${aditionalQuery}${aditionalQueryBtn}`
      else query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&where.inviteds.some.userId=${profile.id}${aditionalQuery}${aditionalQueryBtn}&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc`
    } else {
      if (adminTotal)
        query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc${aditionalQuery}${aditionalQueryBtn}&&where.statusId=6`
      else query = `action?include.answers.include.responsible.include.user=true&include.priority=true&&include.topic.include.monitors.include.user=true&&include.responsibles.include.user=true&&where.topic.monitors.some.userId=${profile.id}${aditionalQuery}&&where.statusId=6&&include.deadline.include.dateMarking=true&&orderBy.deadline.dateMarking.date=asc`
    }
    api.get(query)
      .then(({ data: action }) => {
        const parseAction = action.map((e) => {
          e.deadline = e.deadline ? e.deadline.dateMarking.date : null
          e.extendedDeadline = e.extendedDeadline ? e.extendedDeadline.dateMarking.date : null
          return e
        })
        setActions(parseAction)
        if (revisar)
          setCountARevisar(action.length)
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
      })
  }, [aditionalQuery, aditionalQueryBtn, adminTotal, convidadas, inbox, outbox, profile.id, revisar])

  const handleCloseActionModal = useCallback(() => {
    getActions()
    setAcaoAtiva(false)
  }, [getActions])

  const handleDate = useCallback(() => {
    let query = ''
    if (dateStart) {
      const dateStartQuery = dateStart
        ? formatISO(addHours(
          new Date(dateStart).setUTCHours(0, 0, 0, 0),
          3
        ))
        : null
      query += `&&where.OR[0].deadline.dateMarking.date.gte=${dateStartQuery}`
      query += `&&where.OR[1].extendedDeadline.dateMarking.date.gte=${dateStartQuery}`
    }
    if (dateEnd) {
      const dateEndQuery = dateEnd
        ? formatISO(
          addDays(
            addHours(
              new Date(dateEnd).setUTCHours(0, 0, 0, 0),
              3
            ), 1
          )
        )
        : null
      // console.log(dateEndQuery)
      query += `&&where.OR[0].deadline.dateMarking.date.lte=${dateEndQuery}`
      query += `&&where.OR[1].extendedDeadline.dateMarking.date.lte=${dateEndQuery}`
    }
    if (dateEnd || dateStart)
      setAditionalQuery(query)

  }, [dateStart, dateEnd])

  useEffect(() => {
    getActions()
  }, [getActions])

  useEffect(() => {
    handleDate()
  }, [handleDate])

  useEffect(() => {
    function getActionsARevisar() {
      let query = ''
      if (adminTotal)
        query = `action?where.statusId=6`
      else query = `action?where.topic.monitors.some.userId=${profile.id}${aditionalQuery}&&where.statusId=6`

      api.get(query)
        .then(({ data: action }) => {
          setCountARevisar(action.length)
          setLoading(false)
        })
        .catch(e => {
          setLoading(false)
        })
    }
    getActionsARevisar()
  }, [aditionalQuery, aditionalQueryBtn, adminTotal, profile.id])

  useEffect(() => {
    if (actionSuccess)
      handleCloseActionModal()
  }, [actionSuccess, handleCloseActionModal])

  return (
    <Card className='mt-4'>
      <CardHeader>
        <ContainerHeaderOptions className="row mb-4 mt-4 d-flex align-items-center ml-2">
          <Header
            title="Ações recebidas"
            ativo={inbox}
            onClick={handleInboxClick}>
          </Header>
          <Header
            title="Ações enviadas"
            ativo={outbox}
            onClick={handleOutboxClick}>
          </Header>
          <Header
            title="Ações convidadas"
            ativo={convidadas}
            onClick={handleConvidadasClick}>
          </Header>
          <Header
            title={
              <>
                Ações a revisar <span className='float-right'>{countARevisar}</span>
              </>}
            ativo={revisar}
            onClick={handleRevisarClick}>
          </Header>
        </ContainerHeaderOptions>
      </CardHeader>
      <CardBody>
        <ContainerAction>
          <Modal
            size="xl"
            show={acaoAtiva}
            onHide={() => setAcaoAtiva(false)}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100">
                Ação
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Action actionId={parseInt(actionId)} />
            </Modal.Body>
          </Modal>
          <Row>
            <Col xl='2' lg='2' sm='3' md='3' className='d-flex align-items-end mb-3'>
              <Button
                type="submit"
                className={`${btnAbertas ? 'btn-success' : 'btn-default'} btn form-control`}
                title='Abertas'
                onClick={handleAbertas}
              />
            </Col>
            <Col xl='2' lg='2' sm='3' md='3' className='d-flex align-items-end mb-3'>
              <Button
                type="button"
                onClick={handleFinalizadas}
                className={`${btnFinalizadas ? 'btn-success' : 'btn-default'} btn form-control`}
                title='Finalizadas'
              />
            </Col>
            <Col xl='2' lg='2' sm='3' md='3' className='d-flex align-items-end mb-3'>
              <Button
                type="button"
                onClick={handleVencidas}
                className={`${btnVencidas ? 'btn-success' : 'btn-default'} btn form-control`}
                title='Vencidas'
              />
            </Col>
            <Controller
              name="date_start"
              control={control}
              defaultValue={''}
              render={({ field }) =>
                <Input
                  label="De"
                  id={field.name}
                  xl='3'
                  lg='3'
                  type="date"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />}
            />
            <Controller
              name="date_end"
              control={control}
              defaultValue={''}
              render={({ field }) =>
                <Input
                  label="Até"
                  id={field.name}
                  xl='3'
                  lg='3'
                  type="date"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                />}
            />
          </Row>
          {loading && <Loading card={true} />}
          {!loading && (
            <Table
              keyField="id"
              data={actions}
              columns={columns}
              striped={false}
              paginationOptions={
                {
                  sizePerPageList: [{
                    text: '5', value: 5
                  }, {
                    text: '10', value: 10
                  }]
                }
              }
            />)}
        </ContainerAction>
      </CardBody>
    </Card>
  )
}
