import * as types from '../types'

export function createTopicRequest(data) {
  return {
    type: types.CREATE_TOPIC_REQUEST,
    payload: { data },
  }
}
export function createTopicSuccess() {
  return {
    type: types.CREATE_TOPIC_SUCCESS,
    payload: {},
  }
}

export function getTopicsRequest(data) {
  return {
    type: types.GET_TOPICS_REQUEST,
    payload: { data },
  }
}

export function getTopicsSuccess(data) {
  return {
    type: types.GET_TOPICS_SUCCESS,
    payload: { data },
  }
}

export function topicFailure() {
  return {
    type: types.TOPIC_FAILURE,
  }
}
