import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Popover, OverlayTrigger } from "react-bootstrap";
import PropTypes from 'prop-types';
import { FaRegQuestionCircle } from "react-icons/fa";

export default function TooltipCustom({
  title,
  text,
}) {
  const popover = (
    <Popover id="tooltip-help">
      {title && (
        <Popover.Title as="h3">{title}</Popover.Title>
      )}
      {text && (
        <Popover.Content>{text}</Popover.Content>
      )}
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      rootClose={true}
      transition={true}
      overlay={popover}>
      <span>
        <FaRegQuestionCircle
          color="#1f9f36"
          size="18px"
          className="ml-2"
          style={{
            cursor: 'pointer'
          }} />
      </span>
    </OverlayTrigger>
  );
}

TooltipCustom.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
};
