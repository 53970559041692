import React, { useContext, useEffect, useState } from 'react'
import Header from '~/components/Header'
import history from '~/services/history'
import { Card } from '~/components/Card'
import CardHeader from '~/components/Card/Header'
import CardBody from '~/components/Card/Body'
import Table from '~/components/Table'
import { ContainerData, ContainerDate, ContainerHeader } from './styles'
import Loading from '~/components/Loading'
import api from '~/services/api'
import Col from '~/components/Bootstrap/Col'
import Row from '~/components/Bootstrap/Row'
import { Modal } from 'react-bootstrap'
import Action from '~/pages/Meeting/Action'
import { SocketContext } from '~/services/websockets'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useSelector } from 'react-redux'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

export default function DashboardNotifications() {

  const { profile } = useSelector(state => state.auth)

  const [loading, setLoading] = useState([])

  const [notifications, setNotifications] = useState([])

  const [acaoAtiva, setAcaoAtiva] = useState(false)

  const [actionId, setActionId] = useState(null)

  const connect = useContext(SocketContext)

  const [socket, setSocket] = useState(null)

  const adminLocal = profile.roleId === 2

  const columns = [
    {
      dataField: 'id',
      text: '#',
      hidden: true,
    },
    {
      dataField: 'subject.createdAt',
      text: 'Data',
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<><FaCaretUp /><FaCaretDown /></>)
        else if (order === 'asc') return (<><FaCaretUp color='#4b8a52' /><FaCaretDown /></>)
        else if (order === 'desc') return (<><FaCaretUp /><FaCaretDown color='#4b8a52' /></>)
        return null
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <ContainerDate>
            <Row>
              <strong>{format(new Date(row.subject.createdAt), 'dd MMM', { locale: ptBR })}</strong>
            </Row>
            <Row>
              <small>{format(new Date(row.subject.createdAt), 'HH:mm', { locale: ptBR })}</small>
            </Row>
          </ContainerDate>
        )
      },
      headerFormatter: (column, e, { sortElement }) => {
        return (
          <div>
            {column.text}
            {sortElement}
          </div>
        )
      }
    },
    {
      dataField: 'user.name',
      text: '',
      formatter: (cell, row, rowIndex) => {
        return (
          <ContainerData>
            <div className="row">
              <Col xl='1' lg='1'>
              </Col>
              <Col xl='12' lg='12'>
                <Row>
                  <small className="text-muted text-wrap">
                    {row.user.company.fantasyName}
                  </small>
                </Row>
                <Row>
                  <strong onClick={() => {
                    if (row.subject.name === 'a reunião'
                      || row.subject.name === 'a pauta'
                      || row.subject.name === 'a ata da reunião')
                      handleRedirectToMeeting(row)
                    else if (row.subject.name === 'o workgroup')
                      handleRedirectToWorkgroup(row)
                    else
                      handleRedirectToAction(row)

                  }}>
                    {row.user.name}
                  </strong>
                </Row>
                <Row>
                  <span className='text-muted' onClick={() => {
                    if (row.subject.name === 'a reunião'
                      || row.subject.name === 'a pauta'
                      || row.subject.name === 'a ata da reunião')
                      handleRedirectToMeeting(row)
                    else if (row.subject.name === 'o workgroup')
                      handleRedirectToWorkgroup(row)
                    else
                      handleRedirectToAction(row)
                  }} title={
                    (
                      row.subject.name === 'a ação'
                        ? ' "' + row.subject.entity.description + '"'
                        : ''
                    )
                    +
                    (
                      row.subject.name === 'um comentário'
                        ? ' na ação "' + row.subject.entity.action.description + '"'
                        : ''
                    )
                    +
                    (
                      row.subject.name === 'a reunião'
                        ? ' "' + row.subject.entity.title.value + '"'
                        : ''
                    )
                    +
                    (
                      row.subject.name === 'o workgroup'
                        ? ' "' + row.subject.entity.name + '"'
                        : ''
                    )
                    +
                    (
                      row.subject.name === 'da ação'
                        ? ' "' + row.subject.entity.action.description + '"'
                        : ''
                    )
                    +
                    (
                      row.subject.name === 'um arquivo'
                        ? ' na ação "' + row.subject.entity.action.description + '"'
                        : ''
                    )
                    +
                    (
                      row.subject.name === 'a pauta'
                        ? ' "' + row.subject.entity.agendaTopic + '"'
                        : ''
                    )
                    +
                    (
                      row.subject.name === 'a ata da reunião'
                        ? row.subject.entity.title.value + '" para '
                        + row.subject.entity.recipients
                          .map(({ name }) => name).join(', ')
                        : ''
                    )}>
                    {row.type.name + row.subject.name +
                      (
                        row.subject.name === 'a ação'
                          ? ' "' + row.subject.entity.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'um comentário'
                          ? ' na ação "' + row.subject.entity.action.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'a reunião'
                          ? ' "' + row.subject.entity.title.value + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'o workgroup'
                          ? ' "' + row.subject.entity.name + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'da ação'
                          ? ' "' + row.subject.entity.action.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'um arquivo'
                          ? ' na ação "' + row.subject.entity.action.description + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'a pauta'
                          ? ' "' + row.subject.entity.agendaTopic + '"'
                          : ''
                      )
                      +
                      (
                        row.subject.name === 'a ata da reunião'
                          ? ' "' + row.subject.entity.title.value + '" para '
                          + row.subject.entity.recipients
                            .map(({ name }) => name).join(', ')
                          : ''
                      )
                    }
                  </span>
                </Row>
              </Col>
            </div>
          </ContainerData>
        )
      },
      headerFormatter: (column, e, { sortElement }) => {
        return (
          <div>
            {column.text}
            {sortElement}
          </div>
        )
      }
    },
  ]

  function handleRedirectToAction(row) {
    setActionId(row.subject.name === 'a ação' ? row.subject.entity.id : row.subject.entity.actionId)
    setAcaoAtiva(true)
  }

  function handleRedirectToMeeting(row) {
    if (row.subject.name === 'a pauta')
      history.push('/reuniao', {
        id: row.subject.entity.meetingId,
        cadastrar: true
      })
    else if (row.subject.name === 'a ata da reunião')
      history.push('/reuniao', {
        id: row.subject.entity.id,
        cadastrar: true
      })
    else
      history.push('/reuniao')
  }

  function handleRedirectToWorkgroup(row) {
    history.push('/workgroups')
  }

  useEffect(() => {
    function getNotifications() {
      setLoading(true)
      api.get(`log?include.type=true&&include.subject=true&&include.user.include.company=true&&orderBy.createdAt=desc&&take=10${adminLocal ? `&&where.user.companyId=${profile.companyId}` : ''}`)
        .then(({ data }) => {
          setLoading(false)
          data.map(e => {
            e.subject.entity = JSON.parse(e.subject.entity)
            switch (e.subject.name) {
              case 'Action':
                e.subject.name = 'a ação'
                break
              case 'Comment':
                e.subject.name = 'um comentário'
                break
              case 'Meeting':
                if (!e.subject.entity.recipients)
                  e.subject.name = 'a reunião'
                else
                  e.subject.name = 'a ata da reunião'
                break
              case 'WorkGroup':
                e.subject.name = 'o workgroup'
                break
              case 'AnswerAction':
                e.subject.name = 'da ação'
                break
              case 'Attachment':
                e.subject.name = 'um arquivo'
                break
              case 'Topic':
                e.subject.name = 'a pauta'
                break
              default:
                break
            }
            switch (e.typeId) {
              case 1:
                e.type.name = 'Criou '
                break
              case (2):
                e.type.name = 'Atualizou '
                break
              case (3):
                e.type.name = 'Atualizou o status d'
                break
              case (4):
                e.type.name = 'Deletou '
                break
              case (5):
                e.type.name = 'Ficou ciente '
                break
              case (6):
                e.type.name = 'Enviou '
                break
              default:
                break
            }
            return e
          })
          setNotifications(data)
        }).catch(() => {
          setLoading(false)
        })
    }
    getNotifications()
  }, [adminLocal, profile.companyId])

  useEffect(() => {
    api.getToken().then((idToken) => {
      setSocket(connect(idToken))
    }).catch(() => { })
  }, [connect])

  useEffect(() => {
    const logListener = (e) => {
      setLoading(true)
      e.subject.entity = JSON.parse(e.subject.entity)
      switch (e.subject.name) {
        case 'Action':
          e.subject.name = 'a ação'
          break
        case 'Comment':
          e.subject.name = 'um comentário'
          break
        case 'Meeting':
          if (!e.subject.entity.recipients)
            e.subject.name = 'a reunião'
          else
            e.subject.name = 'a ata da reunião'
          break
        case 'WorkGroup':
          e.subject.name = 'o workgroup'
          break
        case 'AnswerAction':
          e.subject.name = 'da ação'
          break
        case 'Attachment':
          e.subject.name = 'um arquivo'
          break
        case 'Topic':
          e.subject.name = 'a pauta'
          break
        default:
          break
      }
      switch (e.typeId) {
        case 1:
          e.type.name = 'Criou '
          break
        case (2):
          e.type.name = 'Atualizou '
          break
        case (3):
          e.type.name = 'Atualizou o status d'
          break
        case (4):
          e.type.name = 'Deletou '
          break
        case (5):
          e.type.name = 'Ficou ciente '
          break
        case (6):
          e.type.name = 'Enviou '
          break
        default:
          break
      }
      setNotifications(element => [e, ...element])
      setLoading(false)
    }
    socket?.on('log', logListener)
    return () => { socket?.disconnect() }
  }, [socket])


  return (
    <>
      <Modal
        size="xl"
        show={acaoAtiva}
        onHide={() => setAcaoAtiva(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            Ação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Action actionId={parseInt(actionId)} />
        </Modal.Body>
      </Modal>
      <Card>
        <CardHeader>
          <ContainerHeader>
            <Header
              title="Últimas atualizações"
              ativo={true}>
            </Header>
            <Header
              title="Ver todas"
              onClick={() => history.push('/notificacoes')}
              ativo={false}>
            </Header>
          </ContainerHeader>
        </CardHeader>
        <CardBody>
          <>
            {loading && <Loading card={true} />}
            {!loading && (
              <Table
                keyField="id"
                data={notifications}
                columns={columns}
                striped={false}
                paginationOptions={
                  {
                    sizePerPage: 5,
                    hideSizePerPage: true,
                    hidePageListOnlyOnePage: true,
                  }
                }
              />)}
          </>
        </CardBody>
      </Card>
    </>
  )
}
