import React from 'react'
import { Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Routes from './routes'
import history from './services/history'

import { store, persistor } from './store'
import { connect, SocketContext } from './services/websockets'

function App() {
  return (
    <SocketContext.Provider value={connect}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
            />
            <Routes />
          </Router>
        </PersistGate>
      </Provider>

    </SocketContext.Provider>
  )
}

export default App
