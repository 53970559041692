
// auth
export const UPDATE_REQUEST = '@auth/UPDATE_REQUEST'
export const UPDATE_SUCCESS = '@auth/UPDATE_SUCCESS'
export const UPDATE_TOKEN_REQUEST = '@auth/UPDATE_TOKEN_REQUEST'
export const UPDATE_TOKEN_SUCCESS = '@auth/UPDATE_TOKEN_SUCCESS'
export const SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS'
export const SIGN_FAILURE = '@auth/SIGN_FAILURE'
export const COMPANY_SELECT = '@auth/COMPANY_SELECT'
export const COMPANY_SELECTED = '@auth/COMPANY_SELECTED'
export const SIGN_OUT = '@auth/SIGN_OUT'

// company

export const GET_COMPANY_REQUEST = '@company/GET_COMPANY_REQUEST'
export const GET_COMPANY_SUCCESS = '@company/GET_COMPANY_SUCCESS'
export const SAVE_COMPANY_REQUEST = '@company/SAVE_COMPANY_REQUEST'
export const SAVE_COMPANY_SUCCESS = '@company/SAVE_COMPANY_SUCCESS'
export const REQUEST_FAILURE = '@company/REQUEST_FAILURE'

// user
export const CREATE_PROFILE_REQUEST = '@user/CREATE_PROFILE_REQUEST'
export const CREATE_PROFILE_SUCCESS = '@user/CREATE_PROFILE_SUCCESS'
export const GET_USERS_REQUEST = '@user/GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = '@user/GET_USERS_SUCCESS'
export const USERS_FAILURE = '@user/USERS_FAILURE'

// workgroup
export const CREATE_WORKGROUP_REQUEST = '@workgroup/CREATE_WORKGROUP_REQUEST'
export const CREATE_WORKGROUP_SUCCESS = '@workgroup/CREATE_WORKGROUP_SUCCESS'
export const GET_WORKGROUPS_REQUEST = '@workgroup/GET_WORKGROUPS_REQUEST'
export const GET_WORKGROUPS_SUCCESS = '@workgroup/GET_WORKGROUPS_SUCCESS'
export const WORKGROUP_FAILURE = '@workgroup/WORKGROUP_FAILURE'

// message
export const CREATE_MESSAGE_REQUEST = '@message/CREATE_MESSAGE_REQUEST'
export const CREATE_MESSAGE_SUCCESS = '@message/CREATE_MESSAGE_SUCCESS'
export const GET_MESSAGES_REQUEST = '@message/GET_MESSAGES_REQUEST'
export const GET_MESSAGES_SUCCESS = '@message/GET_MESSAGES_SUCCESS'
export const MESSAGE_FAILURE = '@message/MESSAGE_FAILURE'

// meeting
export const CREATE_MEETING_REQUEST = '@meeting/CREATE_MEETING_REQUEST'
export const CREATE_MEETING_SUCCESS = '@meeting/CREATE_MEETING_SUCCESS'
export const GET_MEETINGS_REQUEST = '@meeting/GET_MEETINGS_REQUEST'
export const GET_MEETINGS_SUCCESS = '@meeting/GET_MEETINGS_SUCCESS'
export const MEETING_FAILURE = '@meeting/MEETING_FAILURE'

// topic
export const CREATE_TOPIC_REQUEST = '@topic/CREATE_TOPIC_REQUEST'
export const CREATE_TOPIC_SUCCESS = '@topic/CREATE_TOPIC_SUCCESS'
export const GET_TOPICS_REQUEST = '@topic/GET_TOPICS_REQUEST'
export const GET_TOPICS_SUCCESS = '@topic/GET_TOPICS_SUCCESS'
export const TOPIC_FAILURE = '@topic/TOPIC_FAILURE'

// action
export const CREATE_ACTION_REQUEST = '@action/CREATE_ACTION_REQUEST'
export const CREATE_ACTION_SUCCESS = '@action/CREATE_ACTION_SUCCESS'
export const GET_ACTIONS_REQUEST = '@action/GET_ACTIONS_REQUEST'
export const GET_ACTIONS_SUCCESS = '@action/GET_ACTIONS_SUCCESS'
export const ACTION_FAILURE = '@action/ACTION_FAILURE'
