import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useForm, Controller } from "react-hook-form"
import Row from '~/components/Bootstrap/Row'
import Input from '~/components/Form/Input'
import Loading from '~/components/Loading'
import { TopSide, Container, BottomSide, LoginCard } from '../SignIn/styles'
import logoIMG from '../../assets/logo.png'
import Button from '~/components/Form/Button'
import api from '~/services/api'
import { signInRequest } from '~/store/modules/auth/actions'

export default function FirstLogin() {
  const dispatch = useDispatch()
  const { control, handleSubmit } = useForm()
  const { profile, loadingAuth } = useSelector(state => state.auth)

  const [loading, setLoading] = useState(false)

  const onSubmit = data => {
    if (!data.password) {
      toast.error('A senha é obrigatória.')
    } else if (!data.password !== !data.password2) {
      toast.error('As senhas não coincidem.')
    } else {
      setLoading(true)
      api.put(`user/${profile.id}`, {
        password: data.password,
        loggedFirstTime: true
      }).then(() => {
        setLoading(false)
        dispatch(signInRequest(profile.email, data.password))
        toast.success("Senha atualizada com sucesso.")
      }).catch(() => {
        setLoading(false)
        toast.error("Erro ao alterar a senha.")
      })
    }
  }

  return (
    <>
      {(loading || loadingAuth) && <Loading />}
      <Container>

        <TopSide>
          <img src={logoIMG} alt="logoIMG" />
        </TopSide>

        <BottomSide>
          <LoginCard>
            <p>Primeiro Acesso</p>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      label="Senha"
                      id={field.name}
                      xl='12'
                      lg='12'
                      placeholder="********"
                      type="password"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Row>
              <Row>
                <Controller
                  name="password2"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      label="Repetir senha"
                      id={field.name}
                      xl='12'
                      lg='12'
                      placeholder="********"
                      type="password"
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Row>
              <Button
                type="submit"
                className="btn-default form-control mt-4"
                title="Entrar"
              />
            </form>
          </LoginCard>
        </BottomSide>
      </Container>
    </>
  )
}
