import styled from "styled-components"

export const Container = styled.div`
  margin: 0;
`


export const ContainerTable = styled.div`
`

export const ContainerDate = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 18px 0;
  small{
    text-align: center;
  }
`


export const ContainerData = styled.div`
  cursor: pointer
`
